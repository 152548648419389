import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import { Icon } from 'shared/components/Icon';
import { stringify } from 'shared/helpers/query-string';
import { useSearch } from 'shared/helpers/useSearch';
import {
  getFabricGroupNameWithoutType,
  disableFilteredGroups,
  useFabricGroupsClusteredByTypes,
} from 'shared/http/fabric-groups.http';
import { LoaderIcon } from 'shared/components/LoaderIcon';

export function FabricGroups({ filter }) {
  const location = useLocation();
  const search = useSearch();
  const { fabricGroupsByType, fabricGroupTypes, isLoading } = useFabricGroupsClusteredByTypes();

  const filteredFabricsGroupsByType = useMemo(() => {
    //FIXME: make it immutable
    disableFilteredGroups(fabricGroupsByType, filter);

    return fabricGroupsByType;
  }, [fabricGroupsByType]);

  function disableClick(fabricGroup) {
    return (e) => {
      if (fabricGroup.disabled) {
        e.stopPropagation();
        e.preventDefault();
      }
    };
  }

  if (isLoading) {
    return <LoaderIcon />;
  }

  return (
    <div className="fabric-groups">
      <div className="row">
        {fabricGroupTypes.map((type) => (
          <div className="col-lg-6" key={type}>
            <h4>{type}</h4>
            <ul className="nav nav-pills flex-column">
              {(filteredFabricsGroupsByType[type] || []).map((fabricGroup) => (
                <li key={fabricGroup._id} className={`nav-item fabric-group${fabricGroup.disabled ? ' disabled' : ''}`}>
                  <Link
                    to={{ ...location, search: stringify({ ...search, 'grupo-tecido': fabricGroup._id }) }}
                    className="nav-link"
                    onClick={disableClick(fabricGroup)}
                  >
                    <span className="mc-icon float-right">
                      <Icon icon="chevron-right" />
                    </span>
                    {getFabricGroupNameWithoutType(fabricGroup)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
FabricGroups.propTypes = {
  filter: PropTypes.any,
};
