import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { useFabricGroups } from 'shared/http/fabric-groups.http';
import { resetVerticalScroll } from 'shared/components/scroll/VerticalScroll';
import { LoaderIcon } from 'shared/components/LoaderIcon';
import { useInviteIfNotLogged } from 'shared/auth/AccountInvite';

import { ModelsByFabricGroup } from './ModelsByFabricGroup';

export function ModelsByType({ modelType, isLoading: isLoadingParent }) {
  const { fabricGroups, isLoading: isLoadingFabricGroups } = useFabricGroups();
  const { inviteIfNotLogged, InviteIfNotLoggedPortal } = useInviteIfNotLogged();

  const isLoading = isLoadingFabricGroups || isLoadingParent;

  useEffect(() => {
    resetVerticalScroll();
  }, []);

  return (
    <div className="models-by-type">
      <InviteIfNotLoggedPortal />
      {isLoading ? (
        <LoaderIcon />
      ) : (
        fabricGroups.map((fabricGroup) => (
          <div key={fabricGroup._id}>
            <ModelsByFabricGroup
              modelType={modelType}
              fabricGroup={fabricGroup}
              inviteIfNotLogged={inviteIfNotLogged}
            />
          </div>
        ))
      )}
    </div>
  );
}
ModelsByType.propTypes = {
  modelType: PropTypes.object,
  isLoading: PropTypes.bool,
};
