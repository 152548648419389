import { useMemo } from 'react';
import PropTypes from 'prop-types';

export function MoldSizesInfo({ rawSizes }) {
  const sizes = useMemo(() => {
    if (!rawSizes) {
      return {};
    }

    let sizes;

    try {
      sizes = JSON.parse(rawSizes);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return (
      sizes &&
      sizes.reduce((acc, size) => {
        acc[size.name] = size.size;

        return acc;
      }, {})
    );
  }, []);

  return (
    <>
      <MoldSizeInfo sizes={sizes} sizeName="XPP" defaultSize="36" />
      <MoldSizeInfo sizes={sizes} sizeName="PP" defaultSize="38" />
      <MoldSizeInfo sizes={sizes} sizeName="P" defaultSize="40" />
      <MoldSizeInfo sizes={sizes} sizeName="M" defaultSize="42" />
      <MoldSizeInfo sizes={sizes} sizeName="G" defaultSize="44" />
      <MoldSizeInfo sizes={sizes} sizeName="GG" defaultSize="46" />
      <MoldSizeInfo sizes={sizes} sizeName="G1" defaultSize="48" />
      <MoldSizeInfo sizes={sizes} sizeName="G2" defaultSize="52" />
      <MoldSizeInfo sizes={sizes} sizeName="G3" defaultSize="56" />
      <MoldSizeInfo sizes={sizes} sizeName="G4" defaultSize="60" />
    </>
  );
}
MoldSizesInfo.propTypes = {
  rawSizes: PropTypes.string,
};

function MoldSizeInfo({ sizes, sizeName, defaultSize }) {
  const size = sizes[sizeName];

  return (
    <div className={`text-center box ${!size ? 'disabled' : ''}`}>
      {sizeName} {size || defaultSize}
    </div>
  );
}
MoldSizeInfo.propTypes = {
  sizes: PropTypes.object,
  sizeName: PropTypes.string,
  defaultSize: PropTypes.string,
};
