import BrV from 'br-validations';
import { isInRange } from 'js-var-type';

export function validateCpfCnpj(value) {
  return !value || isValidCpf(value) || isValidCnpj(value);
}

export function isValidCpf(value) {
  return BrV.cpf.validate(onlyNumbers(value));
}

export function isValidCnpj(value) {
  return BrV.cnpj.validate(onlyNumbers(value));
}

export function validateEmail(value) {
  return !value || /^[\w\.=-]+@[\w\.-]+\.[\w]{2,3}$/.test(value);
}

export function validatePhone(value) {
  return !value || isInRange(onlyNumbers(value), { min: 10, max: 13 });
}

export function onlyNumbers(value = '') {
  return value
    .toString()
    .trim()
    .replace(/[^0-9]/g, '');
}

export function validateUserName(value = '') {
  const words = value.split(' ');

  const hasAtLeastOneLastName = words.length >= 2;
  const allNamesAreValid = words.every((word) => word.length >= 2);

  return hasAtLeastOneLastName && allNamesAreValid;
}
