import { Link } from 'react-router-dom';

import { Icon } from 'shared/components/Icon';
import { useNavigate } from 'shared/helpers/useNavigate';
import { stringify } from 'shared/helpers/query-string';
import { useUserFabrics } from 'shared/http/user-fabrics.http';
import { UserFabricDetailsDialog } from 'shared/components/UserFabricDetailsDialog';

import { LoaderIcon } from 'shared/components/LoaderIcon';

export function UserFabricsPage() {
  const { userFabrics, isLoading } = useUserFabrics();
  const { updateSearch } = useNavigate();

  function afterSelect(userFabric) {
    updateSearch({
      'tecido-usuario': userFabric._id,
    });
  }

  return (
    <div className="user-fabrics">
      <header>
        <h4>Meus Tecidos</h4>
      </header>
      <section>
        <div className="fabrics">
          {isLoading && <LoaderIcon />}
          {isLoading && userFabrics.length === 0 && (
            <div>Opa! Você ainda não fez o upload de nenhum tecido personalizado.</div>
          )}

          {userFabrics.map((fabric) => (
            <div key={fabric._id} className="col-6 col-md-4 col-lg-3 col-xl-2 fabric">
              <img src={fabric.images.thumbnail.url || '/assets/image-placeholder.png'} alt="tecido do usuário" crossOrigin="anonymous" />
              <div className="hoverlay">
                <div className="middle">
                  <Link
                    to={{
                      pathname: '/',
                      search: stringify({
                        'tecido-usuario': fabric._id,
                      }),
                    }}
                  >
                    Aplicar
                  </Link>
                </div>
                <div className="bottom">
                  <UserFabricDetailsDialog userFabric={fabric} afterSelect={afterSelect}>
                    <a href="" className="float-right">
                      <span className="mc-icon">
                        <Icon icon="information" />
                      </span>
                    </a>
                  </UserFabricDetailsDialog>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
