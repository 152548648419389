export function EditDesktopIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.999" height="19.999" viewBox="0 0 15.999 15.999">
      <path
        fill="#fff"
        id="ic_create_24px"
        d="M3,15.664V19H6.333l9.829-9.829L12.829,5.835ZM18.739,6.59a.885.885,0,0,0,0-1.253l-2.08-2.08a.885.885,0,0,0-1.253,0L13.78,4.884l3.333,3.333L18.739,6.59Z"
        transform="translate(-3 -2.997)"
      />
    </svg>
  );
}
