export function RotateIcon() {
  return (
    <svg className="mc-icon" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 50 50">
      <path
        fill="#fff"
        d="M25,13C13.44,13,1,16.442,1,24c0,6.689,9.745,10.152,20,10.86v6.185L32.629,32L21,22.955v5.893C11.799,28.141,7,25.131,7,24 c0-1.285,6.189-5,18-5s18,3.715,18,5c0,0.553-1.579,2.211-6.272,3.538L36,27.743v6.174l1.24-0.307C44.823,31.734,49,28.321,49,24 C49,16.442,36.56,13,25,13z"
      />
    </svg>
  );
}
