const wNumber = '5531996167573';

export function WhatsAppBtn({message ='Olá! Estou interessado na modacad.' }) {
  return (
    <button type="button" className="whatsapp" onClick={onWhatsAppClick}>
      <img src="/assets/icons/whatsapp-white.svg" alt="whatsapp" />
    </button>
  );

  function onWhatsAppClick() {
    window.open(getWhatsappLink(message));
  }
}

export function getWhatsappLink(message) {
  return `https://wa.me/${wNumber}?text=${encodeURI(message)}`;
}

