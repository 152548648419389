'use strict';

const VALID_ENVIRONMENTS = ['development', 'production', 'staging', 'test'];

module.exports = {
  isDevEnv: () => process.env.NODE_ENV === 'development',
  isStagingEnv: () => process.env.NODE_ENV === 'staging',
  isProductionEnv: () => process.env.NODE_ENV === 'production',
  isValidEnv: (env) => VALID_ENVIRONMENTS.includes(env),
};
