import useSWR from 'swr';

import { swrFetcher } from '../helpers/http';

export function usePreferences() {
  const { data, error } = useSWR('/api/configuracoes-app', swrFetcher);

  return {
    preferences: data,
    isLoading: Boolean(!error && !data),
    isError: error,
  };
}
