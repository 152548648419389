import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'shared/components/Icon';
import { formatMoney } from 'shared/helpers/formatters';
import { stringify } from 'shared/helpers/query-string';
import { useSearch } from 'shared/helpers/useSearch';
import { trackRemoveFromCart } from 'shared/analytics';

import { RenderModel } from '../catalog/catwalk/RenderModel';

import { EditShoppingCartItemDialog } from './EditShoppingCartItemDialog';

export function ShoppingCartItem({ item, onRemove }) {
  const search = useSearch();
  const zoom = useMemo(() => parseInt(search.zoom, 10), [search.zoom]);
  const printUrl = useMemo(
    () =>
      `/print-sheet?${stringify({
        modelo: item.model,
        variacao: item.modelVariation,
        'amostra-tecido': item.fabricToken,
        'tecido-usuario': item.userFabric,
        medidas: JSON.stringify(item.measures),
        tamanhos: JSON.stringify(item.sizes),
      })}`,
    [item]
  );
  const variationIndex = useMemo(() => parseInt(item.modelVariation, 10), [item.modelVariation]);

  function removeItem(e) {
    trackRemoveFromCart(item);
    e.preventDefault();
    e.stopPropagation();
    onRemove(item);
  }

  return (
    <div className="order-inner">
      <header>
        <span>{item.code}</span>
        <a href="" onClick={removeItem}>
          <span className="mc-icon">
            <Icon icon="trash-desktop" />
          </span>
        </a>
      </header>
      <section>
        <div className="preview">
          <RenderModel
            model={item.model}
            fabric={item.fabricToken}
            userFabric={item.userFabric}
            variationIndex={variationIndex}
            frontMode={zoom === 1}
          />
        </div>
        <div className="description">
          <div className="measures">
            Busto {item.measures.bust} cm <br />
            Cintura {item.measures.waist} cm <br />
            Quadril {item.measures.hip} cm <br />
          </div>
          <div className="model-size">Tam prova {item.measures.sizeName}</div>
          <div className="sizes">
            {item.sizes.map((size, index) => (
              <span key={index}>
                {size.name} {size.size}
              </span>
            ))}
          </div>
          <div className="actions">
            <EditShoppingCartItemDialog item={item}>
              <a href="">
                <span className="mc-icon mr-1">
                  <Icon icon="circled-pencil" />
                </span>
                <span>Alterar Pedido</span>
              </a>
            </EditShoppingCartItemDialog>
            <a href="" className="remove-item" onClick={removeItem}>
              <span className="mc-icon mr-1">
                <Icon icon="trash" />
              </span>
              <span>Remover Pedido</span>
            </a>

            <a href={printUrl} target="_blank" rel="noreferrer">
              <span className="mc-icon mr-1">
                <Icon icon="print" />
              </span>
              <span>Imprimir</span>
            </a>
          </div>
          <div className="price">{formatMoney(item.total)}</div>
        </div>
      </section>
    </div>
  );
}

ShoppingCartItem.propTypes = {
  item: PropTypes.object,
  onRemove: PropTypes.func,
};
