import ReactGA from 'react-ga4';

import { isProductionEnv } from 'modacad-utils';

export function setupGA() {
  if (!isProductionEnv()) {
    return;
  }

  ReactGA.initialize('G-RL04YHPJWP');
}

/**
 * when showing a list of models
 *
 * When a user is presented with a list of results, send a view_item_list event including an items array parameter containing the displayed items.
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item_list
 *
 * @param {any} models Models presented in the screen
 * @returns {void}
 */
export function trackViewedModels(models) {
  if (!isProductionEnv()) {
    return;
  }

  ReactGA.event('view_item_list', {
    items: models.map((model, index) => ({
      item_id: model._id,
      item_name: model.code,
      item_category: model.type.name,
      index
    }))
  });
}

/**
 * when clicking on a model
 *
 * Once a user selects an item from the list, send the select_item event with the selected item in an items array parameter.
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#select_item
 *
 * @param {*} model Selected model
 * @returns {void}
 */
export function trackSelectModel(model) {
  if (!isProductionEnv()) {
    return;
  }

  ReactGA.event('select_item', {
    'items': [{
      item_id: model._id,
      item_name: model.code,
      item_category: model.type.name,
    }],
  });
}

export function trackSelectModelVariant(model, variant) {
  if (!isProductionEnv()) {
    return;
  }

  ReactGA.event('select_item', {
    'items': [{
      item_id: model._id,
      item_name: model.code,
      item_category: model.type.name,
      item_variant: variant
    }],
  });
}

/**
 * when showing a selected model in the doll
 *
 * To measure how many times item details are viewed, send a view_item event whenever a user views an item’s details screen.
 * This event signifies that some content was shown to the user. Use this event to discover the most popular items viewed.
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item
 *
 * @param {*} model Model presented in the screen
 * @returns {void}
 */
export function trackViewModel(model) {
  if (!isProductionEnv()) {
    return;
  }

  ReactGA.event('view_item', {
    'items': [{
      item_id: model._id,
      item_name: model.code,
      item_category: model.type.name,
    }],
  });
}

//when listing variations

/**
 * Add or remove an item from a shopping cart
 * Measure an item being added to a shopping cart by sending an add_to_cart event with the relevant items in an items array.
 *
 * @param {*} orderItem Mold added to the cart
 * @returns {void}
 */
export function trackAddToCart(orderItem) {
  if (!isProductionEnv()) {
    return;
  }

  ReactGA.event('add_to_cart', {
    'currency': 'BRL',
    'value': orderItem.total,
    'items': orderItem.sizes.map((size) => ({
      'item_id': orderItem.code,
      'item_name': orderItem.code,
      'price': orderItem.total / orderItem.sizes.length,
      'quantity': 1,
      'index': 0,
      size
    }))
  });
}

export function trackCheckoutStart(cart) {
  if (!isProductionEnv()) {
    return;
  }

  ReactGA.event('begin_checkout', {
    'value': cart.total,
    'currency': 'BRL',
    'items': cart.items.map((cartItem, index) => ({
      'item_id': cartItem.code,
      'item_name': cartItem.code,
      'price': cartItem.total,
      'quantity': cartItem.sizes.length,
      index,
    }))
  });
}

export function trackLogin() {
  if (!isProductionEnv()) {
    return;
  }

  ReactGA.event('login');
}

export function trackSignUp() {
  if (!isProductionEnv()) {
    return;
  }

  ReactGA.event('sign_up');
}

export function trackRemoveFromCart(orderItem) {
  if (!isProductionEnv()) {
    return;
  }

  ReactGA.event('remove_from_cart', {
    'currency': 'BRL',
    'value': orderItem.total,
    'items': orderItem.sizes.map((size) => ({
      'item_id': orderItem.code,
      'item_name': orderItem.code,
      'price': orderItem.total / orderItem.sizes.length,
      'quantity': 1,
      'index': 0,
      size
    }))
  });
}

export function trackCheckoutPaymentInfo(order) {
  if (!isProductionEnv()) {
    return;
  }

  ReactGA.event('add_payment_info', {
    'value': order.total,
    'currency': 'BRL',
    'items': order.items.map((cartItem, index) => ({
      'item_id': cartItem.code,
      'item_name': cartItem.code,
      'price': cartItem.total,
      'quantity': cartItem.sizes.length,
      index,
    }))
  });
}

export function trackCheckoutPurchase(order) {
  if (!isProductionEnv()) {
    return;
  }

  ReactGA.event('purchase', {
    'transaction_id': 'feab3fd7-139f-41e6-8664-ff5b385741e1',
    'value': order.total,
    'currency': 'BRL',
    'items': order.items.map((cartItem, index) => ({
      'item_id': cartItem.code,
      'item_name': cartItem.code,
      'price': cartItem.total,
      'quantity': cartItem.sizes.length,
      index,
    }))
  });
}
