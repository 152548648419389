export function CheckCircleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width="70px"
      height="70px"
      viewBox="0 0 70 70"
      enableBackground="new 0 0 70 70"
      xmlSpace="preserve"
    >
      <path
        fill="#5D5E5E"
        d="M53.229,25.933l-4.144-4.109L31.277,39.96l-8.978-8.566l-4.07,4.186l13.125,12.597L53.229,25.933z M35,0  C15.671,0,0,15.67,0,35s15.671,35,35,35s35-15.67,35-35S54.329,0,35,0z M35,5.833c16.082,0,29.167,13.083,29.167,29.167  S51.082,64.167,35,64.167S5.833,51.083,5.833,35S18.918,5.833,35,5.833z"
      />
    </svg>
  );
}
