export const CircledArrowLeftIcon = () => (
  <svg
    className="mc-icon-svg"
    xmlns="http://www.w3.org/2000/svg"
    width="30.092px"
    height="30.092px"
    viewBox="0 0 30.092 30.092"
  >
    <g>
      <path
        fill="#5D5E5E"
        d="M20.054,14.317h-8.817l4.327-4.313c0.286-0.285,0.286-0.747,0-1.032c-0.286-0.285-0.749-0.285-1.035,0
				l-6.092,6.075l6.092,6.075c0.143,0.143,0.33,0.214,0.517,0.214c0.188,0,0.375-0.071,0.518-0.214c0.286-0.285,0.286-0.747,0-1.031
				l-4.327-4.314h8.817c0.404,0,0.731-0.326,0.731-0.729C20.785,14.644,20.458,14.317,20.054,14.317z"
      />
      <path
        fill="#5D5E5E"
        d="M15.046,0C6.75,0,0,6.75,0,15.046c0,8.296,6.75,15.046,15.046,15.046c8.297,0,15.046-6.75,15.046-15.046
				C30.092,6.75,23.343,0,15.046,0z M15.046,28.59c-7.489,0-13.583-6.076-13.583-13.544S7.557,1.502,15.046,1.502
				c7.49,0,13.583,6.076,13.583,13.544S22.536,28.59,15.046,28.59z"
      />
    </g>
  </svg>
);
