export const HelpIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" viewBox="0 0 20 20">
    <g>
      <circle fill="#5D5E5E" cx="9.989" cy="14.156" r="1.042" />
      <path
        fill="#5D5E5E"
        d="M10.066,5.029c-1.814,0-2.988,1.292-2.988,3.292h1.674c0-1.238,0.69-1.677,1.28-1.677
				c0.527,0,1.088,0.351,1.136,1.021c0.052,0.706-0.324,1.064-0.801,1.518c-1.175,1.119-1.198,1.661-1.192,2.89h1.669
				c-0.011-0.553,0.025-1.003,0.778-1.815c0.564-0.608,1.264-1.365,1.279-2.519c0.009-0.771-0.236-1.433-0.711-1.915
				C11.683,5.312,10.929,5.029,10.066,5.029z"
      />
      <path
        fill="#5D5E5E"
        d="M9.989,0C4.473,0,0,4.472,0,9.989s4.473,9.989,9.989,9.989c5.517,0,9.99-4.472,9.99-9.989S15.506,0,9.989,0
				z M9.989,18.323c-4.59,0-8.324-3.738-8.324-8.333s3.734-8.333,8.324-8.333c4.59,0,8.325,3.738,8.325,8.333
				S14.58,18.323,9.989,18.323z"
      />
    </g>
  </svg>
);
