import { useCartPayment } from 'shared/useCartPayment';
import { getWhatsappLink } from 'shared/components/WhatsAppBtn';

export function Sucesso() {
  const { getWhatsappMessage } = useCartPayment();

  const whatsappMessage = getWhatsappMessage();

  return (
    <>
      <div className="arrow-back" />
      <div className="accept-contract-page">
        <h1>Pedido concluído com sucesso!</h1>
        <p />
        <p>
          Em alguns instantes você irá receber um contato por email com os dados para pagamento.
        </p>

        <p>
          Você também pode falar diretamente conosco via whatsapp clicando <a href={getWhatsappLink(whatsappMessage)} style={{textDecoration:'underline', fontSize: '1.1rem'}} target="_blank" rel="noreferrer">aqui</a>
        </p>

      </div>
    </>
  );
}
