import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'shared/components/Icon';
import { formatMoney } from 'shared/helpers/formatters';
import { useSession } from 'shared/http/auth.http';
import { useNavigate } from 'shared/helpers/useNavigate';
import { useSearch } from 'shared/helpers/useSearch';
import { LoaderIcon } from 'shared/components/LoaderIcon';
import { useAnnouncement } from 'shared/components/Announcement';
import { trackCheckoutStart } from 'shared/analytics';

export function ShoppingCartHeader({ order }) {
  const { isAuthenticated, isLoading: isLoadingSession } = useSession();
  const { updateSearch, navigate } = useNavigate();
  const {showAnnouncement, AnnouncementPortal} = useAnnouncement();
  const search = useSearch();
  const zoom = useMemo(() => parseInt(search.zoom, 10), [search.zoom]);

  function zoomOut() {
    if (zoom === 1) {
      return;
    }

    updateSearch({ zoom: zoom - 1 });
  }

  function zoomIn() {
    if (zoom === 4) {
      return;
    }

    updateSearch({ zoom: zoom + 1 });
  }

  function onChange({ target: { value } }) {
    updateSearch({ zoom: parseInt(value, 10) });
  }

  function render() {
    let orderClass = 'grid';

    let columnsClass = '';

    switch (zoom) {
      case 2:
        columnsClass = 'grid-12';
        break;

      case 3:
        columnsClass = 'grid-6';
        break;

      case 4:
        columnsClass = 'grid-4';
        break;

      default:
        orderClass = 'list';
    }

    const $element = document.querySelector('.shopping-cart-page');

    $element.classList.remove('grid', 'list', 'grid-4', 'grid-6', 'grid-12');
    if (orderClass) {
      $element.classList.add(orderClass);
    }

    if (columnsClass) {
      $element.classList.add(columnsClass);
    }
  }

  function pay() {
    trackCheckoutStart(order);

    if (showAnnouncement()) {
      return;
    }

    const paymentUrl = '/carrinho-compras/pagamento';

    if (isAuthenticated) {
      navigate(paymentUrl);
    } else {
      navigate('/login', {
        redirectTo: paymentUrl,
      });
    }
  }

  useEffect(() => {
    if (!zoom) {
      //set the default zoom without messing with the browser history
      updateSearch({ zoom: 4 }, { replace: true });

      return;
    }

    render();
  }, [zoom]);

  if (!zoom) {
    return <LoaderIcon />;
  }

  return (
    <header className="shopping-cart-header">
      <AnnouncementPortal />
      <span>
        <Link to="/">
          <span className="mc-icon">
            <Icon icon="right-arrow" />
          </span>
          Escolher mais modelos
        </Link>
      </span>
      <span>
        <div>
          <span className="mc-icon" onClick={zoomOut}>
            <Icon icon="list" />
          </span>
          <input type="range" min="1" max="4" step="1" name="zoom" value={zoom} onChange={onChange} />
          <span className="mc-icon" onClick={zoomIn}>
            <Icon icon="grid" />
          </span>
        </div>
      </span>
      {order && (
        <span>
          Subtotal: {formatMoney(order.total)}
          <button className="btn btn-lg btn-custom" onClick={pay} disabled={order.total <= 0 || isLoadingSession}>
            Comprar
          </button>
        </span>
      )}
    </header>
  );
}

ShoppingCartHeader.propTypes = {
  order: PropTypes.object,
};
