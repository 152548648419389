export const ExportFabricIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 25 25">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
        d="M18.5,11.5h-5v-5v-2v-4h-2v4h-5v-4h-2v4h-4v2h4v5h-4v2h4v5h-4v2h4 v4h2v-4h5v4h2v-4h5v4h2v-4h4v-2h-4v-5h4v-2h-4H18.5z M6.5,6.5h5v5h-5V6.5z M6.5,18.5v-5h5v5H6.5z M18.5,18.5h-5v-5h5V18.5z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
        d="M24.5,1.498c0-0.234-0.106-0.439-0.261-0.61 c-0.019-0.024-0.035-0.049-0.056-0.071c-0.022-0.021-0.047-0.036-0.071-0.055C23.942,0.607,23.736,0.5,23.501,0.5 c-0.022,0-0.041,0.013-0.063,0.014C23.41,0.513,23.386,0.5,23.356,0.5h-0.07c-0.018,0-0.035,0-0.053,0H16.64 c-0.631,0-1.143,0.446-1.143,0.997s0.512,0.998,1.143,0.998h4.115l-3.92,3.928c-0.473,0.473-0.473,1.241,0,1.714 c0.474,0.474,1.243,0.474,1.716,0l3.953-3.96v4.175c0,0.63,0.447,1.142,0.998,1.142c0.552,0,0.999-0.512,0.999-1.142v-6.71 c0-0.029-0.013-0.053-0.015-0.082C24.487,1.539,24.5,1.52,24.5,1.498z"
      />
    </g>
  </svg>
);
