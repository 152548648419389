import PropTypes from 'prop-types';

import { useNavigate } from 'shared/helpers/useNavigate';
import { Icon } from 'shared/components/Icon';

import { EmptyDoll } from './EmptyDoll';
import { RenderModel } from './RenderModel';
import { useEffect } from 'react';
import { trackViewModel } from '../../../shared/analytics';

export function ShowModel({ model, fabric, userFabric, variationIndex }) {
  const { updateSearch } = useNavigate();

  function removeModel() {
    updateSearch({
      'modelo': undefined,
      'variacao': undefined,
    });
  }

  useEffect(() => {
    if (!model || !model._id) {
      return;
    }

    trackViewModel(model);
  }, [model]);

  return (
    <div className="show-model">
      {model ? (
        <>
          <div className="model-remove" onClick={removeModel} title="Tirar modelo">
            <span className="mc-icon">
              <Icon icon="close-slim" />
            </span>
          </div>
          <div className="model">
            <RenderModel model={model} fabric={fabric} userFabric={userFabric} variationIndex={variationIndex} />
          </div>
        </>
      ) : (
        <EmptyDoll />
      )}
    </div>
  );
}

ShowModel.propTypes = {
  model: PropTypes.object,
  fabric: PropTypes.object,
  userFabric: PropTypes.object,
  variationIndex: PropTypes.number,
};
