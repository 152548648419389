import { useEffect, useMemo, useRef } from 'react';
import { Form, FormInput } from 'react-bootstrap-utils';
import { isTrueLike } from 'js-var-type';

import {
  useFabricGroup,
  getFabricGroupNameWithoutType,
  getFabricSubgroup,
  getTypeFromFabricGroup,
} from 'shared/http/fabric-groups.http';
import { useSearch } from 'shared/helpers/useSearch';
import { useNavigate } from 'shared/helpers/useNavigate';
import { useModel } from 'shared/http/models.http';
import { getValue } from 'shared/helpers/getters-setters';
import { Icon } from 'shared/components/Icon';
import { noop } from 'shared/helpers/utils';
import { VerticalScroll } from 'shared/components/scroll/VerticalScroll';

import { Breadcrumbs } from '../../ui/Breadcrumbs';

import { FabricGroups } from './FabricGroups';
import { FabricManufacturers } from './FabricManufacturers';
import { FabricsBySearch } from './FabricsBySearch';
import { FabricsBySubgroup } from './FabricsBySubgroup';
import { FabricSubgroupsByGroup } from './FabricSubgroupsByGroup';

export function FabricsCatalog() {
  const {
    'grupo-tecido': fabricGroupId,
    'subgrupo-tecido': fabricSubgroupId,
    tecido: fabricId,
    modelo: modelId,
    'busca-tecido': fabricSearchTerm,
    'modo-noturno-tecido': fabricLightMode,
  } = useSearch();
  const { updateSearch } = useNavigate();
  const ref = useRef(null);

  const mode = getValue(fabricSearchTerm, 'length') > 0 ? 'search' : 'selection';
  const subMode = useMemo(() => {
    if (!fabricGroupId) {
      return 'group';
    }

    if (!fabricSubgroupId) {
      return 'subgroup';
    }

    if (!fabricId) {
      return 'fabric';
    }

    return 'token';
  }, [fabricGroupId, fabricSubgroupId, fabricId]);
  const { model: selectedModel } = useModel(modelId);
  const { fabricGroup: selectedFabricGroup } = useFabricGroup(fabricGroupId);

  const selectedFabricType = getTypeFromFabricGroup(selectedFabricGroup);

  const selectedFabricSubgroup = useMemo(() => {
    if (!selectedFabricGroup || !fabricSubgroupId) {
      return null;
    }

    return getFabricSubgroup(selectedFabricGroup, fabricSubgroupId);
  }, [selectedFabricGroup, fabricSubgroupId]);
  const selectedFabric = useMemo(() => {
    if (!selectedFabricSubgroup || !fabricId) {
      return;
    }

    return selectedFabricSubgroup.fabrics.find(({ _id }) => _id === fabricId);
  }, [selectedFabricSubgroup, fabricId]);

  const steps = useMemo(() => {
    const _steps = [];

    if (selectedFabricType) {
      _steps.push({
        title: selectedFabricType,
        onClick() {
          updateSearch({
            'grupo-tecido': undefined,
            'subgrupo-tecido': undefined,
            'tecido': undefined,
            'amostra-tecido': undefined,
            'busca-tecido': undefined,
          });
        },
      });
    }

    if (selectedFabricGroup) {
      _steps.push({
        title: getFabricGroupNameWithoutType(selectedFabricGroup),
        onClick() {
          updateSearch({
            'subgrupo-tecido': undefined,
            'tecido': undefined,
            'amostra-tecido': undefined,
            'busca-tecido': undefined,
          });
        },
      });
    }

    if (selectedFabricSubgroup) {
      _steps.push({
        title: selectedFabricSubgroup.name,
        onClick() {
          updateSearch({ 'tecido': undefined, 'amostra-tecido': undefined, 'busca-tecido': undefined });
        },
      });
    }

    if (selectedFabric) {
      _steps.push({
        title: selectedFabric.name,
      });
    }

    return _steps;
  }, [selectedFabricType, selectedFabricGroup, selectedFabricSubgroup, selectedFabric]);
  const actualStep = useMemo(() => {
    let _actualStep = '';

    if (selectedFabricGroup) {
      _actualStep = getFabricGroupNameWithoutType(selectedFabricGroup);
    }

    if (selectedFabricSubgroup) {
      _actualStep = selectedFabricSubgroup.name;
    }

    if (selectedFabric) {
      _actualStep = selectedFabric.name;
    }

    return _actualStep;
  }, [selectedFabricGroup, selectedFabricSubgroup, selectedFabric]);
  const lightMode = useMemo(() => isTrueLike(fabricLightMode), [fabricLightMode]);
  const selectedModelName = useMemo(() => {
    if (!selectedModel) {
      return '';
    }

    return selectedModel.type.name;
  }, [selectedModel]);
  const groupFilter = useMemo(() => {
    if (!selectedModel) {
      return null;
    }

    return [...selectedModel.group_ids];
  }, [selectedModel]);
  const subgroupFilter = useMemo(() => {
    if (!selectedModel) {
      return null;
    }

    return [...selectedModel.subgroup_ids];
  }, [selectedModel]);

  function unselectModel(e) {
    e.stopPropagation();
    e.preventDefault();

    updateSearch({ modelo: undefined });
  }

  useEffect(() => {
    toggleSelectModelBadge();

    if (selectedModel && !isSelectedModelCompatibleWithNavigationMemory()) {
      updateSearch({
        'grupo-tecido': undefined,
        'subgrupo-tecido': undefined,
        'tecido': undefined,
        'amostra-tecido': undefined,
        'busca-tecido': undefined,
      });
    }
  }, [selectedModel]);

  function isSelectedModelCompatibleWithNavigationMemory() {
    if (!selectedFabricSubgroup) {
      return true;
    }

    return selectedModel.subgroup_ids.includes(selectedFabricSubgroup._id);
  }

  function toggleSelectModelBadge() {
    if (!ref.current) {
      return;
    }

    const formGroup = ref.current.querySelector('.search');

    if (!formGroup) {
      return;
    }

    const input = formGroup.querySelector('input');
    const badge = formGroup.querySelector('.selected-model');

    if (selectedModel) {
      formGroup.classList.add('search-filtered-by-model');
      setTimeout(() => {
        input.style.paddingLeft = `${badge.offsetWidth + 35}px`;
      });
    } else {
      formGroup.classList.remove('search-filtered-by-model');
      input.style.paddingLeft = '30px';
    }
  }

  function onSearch({ searchQuery }) {
    updateSearch({
      'busca-tecido': searchQuery,
    });
  }

  return (
    <div className="fabrics-catalog" ref={ref}>
      <div className="header">
        <Form
          initialValues={{ searchQuery: fabricSearchTerm }}
          onSubmit={noop}
          onChange={onSearch}
          customActions={<></>}
        >
          <div className="search">
            <div className="mc-icon">
              <Icon icon="search" />
            </div>
            <span className="selected-model">
              {selectedModelName}
              <a href="" onClick={unselectModel} className="mc-icon-xs">
                <Icon icon="close-slim" />
              </a>
            </span>
            <FormInput type="search" placeholder="Pesquisar um tecido aqui" name="searchQuery" />
          </div>
        </Form>
        <Breadcrumbs steps={steps} actualStep={actualStep} />
      </div>

      <div className={`fabric-options ${lightMode ? '' : 'dark'}`}>
        <VerticalScroll>
          {mode === 'selection' ? (
            <div>
              {subMode === 'group' ? (
                <FabricGroups filter={groupFilter} />
              ) : subMode === 'subgroup' ? (
                <FabricSubgroupsByGroup
                  type={selectedFabricType}
                  fabricGroup={selectedFabricGroup}
                  filter={subgroupFilter}
                />
              ) : subMode === 'fabric' ? (
                <FabricsBySubgroup fabricSubgroup={selectedFabricSubgroup} />
              ) : subMode === 'token' ? (
                <FabricManufacturers fabric={selectedFabric} />
              ) : (
                <div>
                  <em>Sub modo inválido</em>
                </div>
              )}
            </div>
          ) : mode === 'search' ? (
            <div ng-switch-when="search" className="search-results">
              <FabricsBySearch filter={fabricSearchTerm} groupFilter={groupFilter} subgroupFilter={subgroupFilter} />
            </div>
          ) : (
            <div>
              <em>Modo inválido</em>
            </div>
          )}
        </VerticalScroll>
      </div>
    </div>
  );
}
