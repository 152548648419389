export const CircledPencilIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" viewBox="0 0 25 25">
    <g>
      <path
        fill="#fff"
        d="M23,10.8c0.1,0.6,0.2,1.1,0.2,1.7c0,5.9-4.8,10.6-10.6,10.6S1.9,18.4,1.9,12.5S6.6,1.9,12.5,1.9 c0.6,0,1.2,0.1,1.7,0.2l1.6-1.6C14.7,0.2,13.6,0,12.5,0C5.6,0,0,5.6,0,12.5S5.6,25,12.5,25S25,19.4,25,12.5c0-1.1-0.2-2.2-0.5-3.3 L23,10.8z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
        d="M12.1,17.5l-4.6,0l0-4.6L20,0.5c0.7-0.7,1.9-0.7,2.6,0l1.9,1.9 c0.7,0.7,0.7,1.9,0,2.6L12.1,17.5z M9.3,15.7h2.8l-2.8-2.8V15.7z M21.3,1.8L11.2,12l1.8,1.8L23.2,3.7L21.3,1.8z"
      />
    </g>
  </svg>
);
