import { useMemo } from 'react';

import { useModel } from 'shared/http/models.http';
import { useFabricToken } from 'shared/http/fabric-tokens.http';
import { Icon } from 'shared/components/Icon';
import { gerarCodigoModelo } from 'shared/codigo-modelo';
import { useSearch } from 'shared/helpers/useSearch';
import { LoaderIcon } from 'shared/components/LoaderIcon';

import { RenderModel } from '../catalog/catwalk/RenderModel';

import { FabricTokenInfo } from './FabricTokenInfo';
import { ManufacturerInfo } from './ManufacturerInfo';
import { MeasuresInfo } from './MeasuresInfo';
import { MoldSizesInfo } from './MoldSizesInfo';
import { useUserFabric } from '../../shared/http/user-fabrics.http';

export function PrintModelSheetPage() {
  const {
    modelo: modelId,
    'amostra-tecido': fabricTokenId,
    'tecido-usuario': userFabricId,
    variacao: variation,
    medidas: rawMeasures,
    tamanhos: rawSizes,
  } = useSearch();
  const { model, isLoading: isLoadingModel } = useModel(modelId);
  const { fabricToken: token, isLoading: isLoadingToken } = useFabricToken(fabricTokenId);
  const { userFabric, isLoading: isLoadingUserFabric } = useUserFabric(userFabricId);
  const isLoading = isLoadingModel || isLoadingToken || isLoadingUserFabric;

  const variationIndex = useMemo(() => parseInt(variation, 10), [variation]);

  const moldCode = useMemo(() => {
    if (!model || !model._id) {
      return;
    }

    return gerarCodigoModelo(model, variationIndex);
  }, [model, variationIndex]);

  const print = () => {
    window.print();
  };

  if (isLoading) {
    return (
      <div className="print-model-sheet-page">
        <div className="page flex">
          <LoaderIcon />
        </div>
      </div>
    );
  }

  return (
    <div className="print-model-sheet-page">
      <nav className="navbar navbar-default fixed-top hidden-print">
        <div className="container">
          <div className="navbar-header">
            <a className="navbar-brand" href="">
              Ficha de modelo
            </a>
          </div>

          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <form className="navbar-form navbar-right">
              <button type="button" className="btn btn-primary" onClick={print}>
                <span className="mc-icon">
                  <Icon icon="print-alt" />
                </span>
                Imprimir ficha
              </button>
            </form>
          </div>
        </div>
      </nav>

      <div className="page flex">
        <div className="model flex flex-row">
          <div className="box model-reference">{moldCode}</div>
          <div className="flex">
            <div className="model-info">
              <MeasuresInfo rawMeasures={rawMeasures} />
              <MoldSizesInfo rawSizes={rawSizes} />
              <div className="modacad-logo">
                <img src="/assets/logo-vertical.svg" width="100px" alt="logo modacad" />
              </div>
            </div>
            <div className="model-preview">
              {model._id ? <RenderModel model={model} fabric={token} userFabric={userFabric} variationIndex={variationIndex} /> : null}
            </div>
          </div>
        </div>
        <div className="fabric">
          <FabricTokenInfo token={token} userFabric={userFabric} />

          <hr />

          <ManufacturerInfo token={token} />
        </div>
      </div>
    </div>
  );
}
