import { useHistory, useLocation } from 'react-router';

import { stringify } from './query-string';
import { useSearch } from './useSearch';

export function useNavigate() {
  const location = useLocation();
  const _search = useSearch();
  const history = useHistory();

  function navigate(pathname, search = {}, { inherit = false, replace = false } = {}) {
    const action = replace ? history.replace : history.push;

    action({
      pathname,
      search: stringify(
        inherit
          ? {
              ..._search,
              ...search,
            }
          : search
      ),
    });
  }

  return {
    updateSearch(newSearch, { inherit = true, replace = false } = {}) {
      navigate(location.pathname, newSearch, { inherit, replace });
    },
    navigate,
    navigateToUrl(url) {
      history.push(url);
    },
    navigateBack() {
      history.goBack();
    },
  };
}
