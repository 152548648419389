export function AvatarIcon() {
  return (
    <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 40 40">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
        d="M40,20C40,8.955,31.045,0,20,0C8.954,0,0,8.955,0,20 c0,6.54,3.153,12.328,8.006,15.978c0,0.008-0.002,0.015-0.003,0.022h0.033c3.339,2.501,7.471,4,11.963,4 c4.492,0,8.625-1.499,11.963-4h0.033c0-0.008-0.002-0.015-0.003-0.023C36.847,32.328,40,26.54,40,20z M9.785,34.813 c0.68-3.58,3.13-6.519,6.406-7.825c1.154,0.562,2.443,0.887,3.81,0.887s2.655-0.324,3.81-0.887c3.275,1.306,5.725,4.244,6.406,7.824 C27.312,36.819,23.796,38,20,38C16.204,38,12.688,36.819,9.785,34.813z M12.786,18.938c0-4.039,3.229-7.312,7.215-7.312 c3.984,0,7.215,3.274,7.215,7.312S23.985,26.25,20,26.25C16.015,26.25,12.786,22.976,12.786,18.938z M31.611,33.743 c-0.875-3.397-3.139-6.224-6.164-7.786c2.05-1.637,3.371-4.169,3.371-7.02C28.819,14.001,24.871,10,20,10 c-4.871,0-8.818,4.001-8.818,8.938c0,2.85,1.32,5.383,3.371,7.02c-3.025,1.562-5.289,4.389-6.164,7.786C4.485,30.442,2,25.514,2,20 c0-9.941,8.059-18,18-18s18,8.059,18,18C38,25.514,35.515,30.441,31.611,33.743z"
      />
    </svg>
  );
}
