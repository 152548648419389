'use strict';

const createError = require('http-errors');

module.exports = {
  badRequest: createError.bind(null, 400),
  notAuthenticated: createError.bind(null, 401),
  notAuthorized: createError.bind(null, 403),
  notFound: createError.bind(null, 404),
  notAllowed: createError.bind(null, 406),
  preconditionFailed: createError.bind(null, 412),
  badImplementation: createError.bind(null, 500),
};
