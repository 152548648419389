import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'perfect-scrollbar';
import _throttle from 'lodash/throttle';

export function HorizontalScroll({ children }) {
  const innerScrollElemRef = useRef(null);
  const leftScrollIntervalRef = useRef(null);
  const psRef = useRef(null);
  const rightScrollIntervalRef = useRef(null);
  const [isLeftButtonVisible, setIsLeftButtonVisible] = useState(false);
  const [isRightButtonVisible, setIsRightButtonVisible] = useState(false);

  function startLeftScroll() {
    if (!innerScrollElemRef.current) {
      return;
    }

    const innerScrollElem = innerScrollElemRef.current;

    leftScrollIntervalRef.current = setInterval(() => {
      if (innerScrollElem.scrollLeft === innerScrollElem.clientLeft) {
        return stopLeftScroll();
      }

      leftScroll();
    }, 5);
  }

  const stopLeftScroll = () => {
    clearInterval(leftScrollIntervalRef.current);
  };

  function leftScroll() {
    if (!innerScrollElemRef.current) {
      return;
    }

    const innerScrollElem = innerScrollElemRef.current;

    innerScrollElem.scrollLeft -= 1;
  }

  function rightScroll() {
    if (!innerScrollElemRef.current) {
      return;
    }

    const innerScrollElem = innerScrollElemRef.current;

    innerScrollElem.scrollLeft += 1;
  }

  function startRightScroll() {
    if (!innerScrollElemRef.current) {
      return;
    }

    const innerScrollElem = innerScrollElemRef.current;
    const maxScroll = innerScrollElem.scrollWidth - innerScrollElem.clientWidth;

    rightScrollIntervalRef.current = setInterval(() => {
      if (innerScrollElem.scrollLeft === maxScroll) {
        return stopRightScroll();
      }

      rightScroll();
    }, 5);
  }

  function stopRightScroll() {
    clearInterval(rightScrollIntervalRef.current);
  }

  const updateButtonsVisibilityRef = useRef(
    _throttle(() => {
      if (!innerScrollElemRef.current) {
        return;
      }

      const innerScrollElem = innerScrollElemRef.current;

      setIsLeftButtonVisible(innerScrollElem.scrollLeft > innerScrollElem.clientLeft);
      setIsRightButtonVisible(innerScrollElem.scrollLeft < innerScrollElem.scrollWidth - innerScrollElem.clientWidth);
    }, 250)
  );

  useEffect(() => {
    if (!innerScrollElemRef.current) {
      return;
    }

    const innerScrollElem = innerScrollElemRef.current;

    setTimeout(() => {
      psRef.current = new PerfectScrollbar(innerScrollElem, {
        suppressScrollY: true,
      });

      const updateButtonsVisibility = updateButtonsVisibilityRef.current;

      updateButtonsVisibility();

      innerScrollElem.addEventListener('ps-scroll-x', () => {
        updateButtonsVisibility();
      });
    }, 10);
  }, [innerScrollElemRef.current]);

  return (
    <div className="horizontal-scroll">
      {isLeftButtonVisible && (
        <button className="scroll-button left-scroll-button" onMouseDown={startLeftScroll} onMouseUp={stopLeftScroll}>
          <svg className="mc-icon" xmlns="http://www.w3.org/2000/svg" width="10px" height="31px" viewBox="0 0 10 31">
            <path
              fill="#fff"
              d="M0,15.5L9.367,0.492L10,1.506L1.266,15.5L10,29.493l-0.633,1.014L0.632,16.514v0L0,15.5z"
            />
          </svg>
        </button>
      )}
      {isRightButtonVisible && (
        <button
          className="scroll-button right-scroll-button"
          onMouseDown={startRightScroll}
          onMouseUp={stopRightScroll}
        >
          <svg className="mc-icon" xmlns="http://www.w3.org/2000/svg" width="10px" height="31px" viewBox="0 0 10 31">
            <path
              fill="#fff"
              d="M10,15.5L0.633,0.492L0,1.507L8.734,15.5L0,29.494l0.633,1.014l8.735-13.994v0L10,15.5z"
            />
          </svg>
        </button>
      )}
      <div className="horizontal-scroll-inner" ref={innerScrollElemRef}>
        {children}
      </div>
    </div>
  );
}

HorizontalScroll.propTypes = {
  children: PropTypes.node,
};
