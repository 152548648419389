import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import { Icon } from 'shared/components/Icon';
import { useSearch } from 'shared/helpers/useSearch';
import { stringify } from 'shared/helpers/query-string';
import { getFabricGroupNameWithoutType, getFabricSubgroups } from 'shared/http/fabric-groups.http';

export function FabricSubgroupsByGroup({ type, fabricGroup, filter }) {
  const location = useLocation();
  const search = useSearch();

  const subgroups = useMemo(() => getFabricSubgroups(fabricGroup, filter), [fabricGroup, filter]);
  const fabricGroupName = useMemo(() => getFabricGroupNameWithoutType(fabricGroup), [fabricGroup]);

  return (
    <div className="fabrics-subgroup-by-group">
      <div className="row">
        <div className="col-md-6">
          <h4>{type}</h4>
          <ul className="nav nav-pills flex-column">
            <li className="nav-item active">
              <Link
                to={{ ...location, search: stringify({ ...search, 'grupo-tecido': undefined }) }}
                className="nav-link"
              >
                <span className="mc-icon">
                  <Icon icon="chevron-left" />
                </span>
                {fabricGroupName}
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <h4>&nbsp;</h4>
          <ul className="nav nav-pills flex-column">
            {subgroups.map((fabricSubgroup) => (
              <li key={fabricSubgroup._id} className={`nav-item ${fabricSubgroup.disabled ? 'disabled' : ''}`}>
                <Link
                  to={{ ...location, search: stringify({ ...search, 'subgrupo-tecido': fabricSubgroup._id }) }}
                  className="nav-link"
                >
                  <span className="mc-icon float-right">
                    <Icon icon="chevron-right" />
                  </span>
                  {fabricSubgroup.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
FabricSubgroupsByGroup.propTypes = {
  type: PropTypes.string,
  fabricGroup: PropTypes.object,
  filter: PropTypes.array,
};
