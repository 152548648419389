import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { Icon } from 'shared/components/Icon';
import { useNavigate } from 'shared/helpers/useNavigate';

import { PreviewSelectedModel } from './PreviewSelectedModel';

export function BuyMoldSuccess({ order, onClose }) {
  const { navigate } = useNavigate();

  function showCart(e) {
    e.stopPropagation();
    e.preventDefault();
    onClose();

    setTimeout(() => {
      navigate('/carrinho-compras', { zoom: 4 });
    });
  }

  function close(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    onClose();
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      close();
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="buy-mold-success">
      <div className="row">
        <div className="col-sm-4 app-buy-mold-preview">
          <PreviewSelectedModel order={order._order} />
        </div>
        <div className="col-sm-8 app-buy-mold-content">
          <a href="" className="float-right" onClick={close}>
            <span className="mc-icon mc-icon-inverse">
              <Icon icon="close" />
            </span>
          </a>

          <div>
            <h3>
              Seu pedido foi enviado <br />
              para o carrinho com sucesso
            </h3>
            <div>
              <a href="" onClick={close}>
                <span className="mc-icon mc-icon-inverse">
                  <Icon icon="right-arrow" />
                </span>
                Escolher mais modelos
              </a>
            </div>
            <div>
              <a href="" onClick={showCart}>
                <span className="mc-icon mc-icon-inverse">
                  <Icon icon="right-arrow" />
                </span>
                Ir para o carrinho
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
BuyMoldSuccess.propTypes = {
  order: PropTypes.object,
  onClose: PropTypes.func,
};
