import PropTypes from 'prop-types';
import { isTrueLike } from 'js-var-type';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { useSearch } from 'shared/helpers/useSearch';
import { stringify } from 'shared/helpers/query-string';

export function FabricsLightMode({ className }) {
  const location = useLocation();
  const search = useSearch();
  const fabricLightMode = isTrueLike(search['modo-noturno-tecido']);

  return (
    <span className={`fabrics-light-mode ${className}`}>
      <Link to={{ ...location, search: stringify({ ...search, 'modo-noturno-tecido': !fabricLightMode }) }}>
        <img src={`/assets/icons/${fabricLightMode ? 'night' : 'day'}.svg`} alt="" />
      </Link>
    </span>
  );
}
FabricsLightMode.defaultProps = {
  className: '',
};
FabricsLightMode.propTypes = {
  className: PropTypes.string,
};
