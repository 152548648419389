export const GridIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#5D5E5E"
      d="M1.674,20h4.995c0.92,0,1.666-0.746,1.666-1.667v-5 c0-0.92-0.746-1.667-1.666-1.667H1.674c-0.919,0-1.665,0.746-1.665,1.667v5C0.01,19.254,0.755,20,1.674,20z M1.674,13.333h4.995v5 H1.674V13.333z M1.674,8.333h4.995c0.92,0,1.666-0.746,1.666-1.667v-5C8.335,0.746,7.589,0,6.669,0H1.674 C0.755,0,0.01,0.746,0.01,1.667v5C0.01,7.587,0.755,8.333,1.674,8.333z M1.674,1.667h4.995v5H1.674V1.667z M13.33,20h4.995 c0.919,0,1.665-0.746,1.665-1.667v-5c0-0.92-0.746-1.667-1.665-1.667H13.33c-0.919,0-1.665,0.746-1.665,1.667v5 C11.665,19.254,12.411,20,13.33,20z M13.33,13.333h4.995v5H13.33V13.333z M13.33,8.333h4.995c0.919,0,1.665-0.746,1.665-1.667v-5 C19.99,0.746,19.245,0,18.325,0H13.33c-0.919,0-1.665,0.746-1.665,1.667v5C11.665,7.587,12.411,8.333,13.33,8.333z M13.33,1.667 h4.995v5H13.33V1.667z"
    />
  </svg>
);
