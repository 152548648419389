import { http } from './helpers/http';

export function medidasInvalidas(measures) {
  return (
    !isInRange(measures.bust, 80, 134) ||
    !isInRange(measures.hip, 90, 144) ||
    !isInRange(measures.hip - measures.bust, 4, 16)
  );
}

export function getInvalidMeasuresErrorMessage({ bust, hip }) {
  if (bust < 80) {
    return 'A medida de busto deve ter no mínimo 80cm.';
  }

  if (bust > 134) {
    return 'A medida de busto deve ter no máximo 134cm.';
  }

  if (hip < 90) {
    return 'A medida de quadril deve ter no mínimo 90cm.';
  }

  if (hip > 144) {
    return 'A medida de quadril deve ter no máximo 144cm.';
  }

  const diffHipBust = hip - bust;

  if (diffHipBust <= 0) {
    return 'A medida de quadril deve ser maior que a do busto.';
  }

  if (diffHipBust < 4) {
    return 'A diferença entre o quadril e o busto não pode ser menor que 4cm.';
  }

  if (diffHipBust > 16) {
    return 'A diferença entre o quadril e o busto não pode ser maior que 16cm.';
  }
}

function isInRange(value, min, max) {
  return value >= min && value <= max;
}

const validWaistDiff = {
  36: 2,
  38: 2,
  40: 2,
  42: 3,
  44: 3,
  46: 3,
  48: 6,
  52: 6,
  56: 6,
  60: 6,
};

export function tamanhoCinturaEhValido(measures, measure) {
  return Math.abs(measures.waist - measure.waist) <= validWaistDiff[measure.size];
}

export function getTamanhoCinturaInvalidoErrorMessage(measure) {
  const minWaitMeasure = measure.waist - validWaistDiff[measure.size];
  const maxWaistMeasure = measure.waist + validWaistDiff[measure.size];

  return `Medida da cintura inválida. Para o tamanho ${measure.size} a medida da cintura deve estar entre ${minWaitMeasure} e ${maxWaistMeasure}.`;
}

export function searchTable({ bust, hip }) {
  return http.get(`/api/measures-tables/discover?bust=${bust}&hip=${hip}`);
}
