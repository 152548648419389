import useSWR, { mutate } from 'swr';

import { http, swrFetcher } from '../helpers/http';
import { trackLogin, trackSignUp } from '../analytics';

export function useSession() {
  const { data, error } = useSWR('/api/session', swrFetcher, {
    dedupingInterval: 10 * 1000,
    focusThrottleInterval: 10 * 1000,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });

  return {
    session: !error ? data : {},
    isAuthenticated: !error && Boolean(data),
    isLoading: !error && !data,
    isError: error,
  };
}

export function logoutUser() {
  return http.delete('/api/session').then(() => {
    mutate('/api/session');
  });
}

export function verifyUserByEmail(email) {
  return http.post('/api/verify-user', { email });
}

export function recoverPassword(formData) {
  return http.post('/api/recover-password', formData);
}

export function authenticateUser({ email, password }) {
  return http.post('/api/session', {
    email,
    password,
  }).then((res) => {
    trackLogin();

    return res;
  });
}

export function registerUser(formData) {
  return http.post('/api/register', formData).then((res) => {
    trackSignUp();

    return res;
  });
}

export function requestPasswordRecover(email) {
  http.post('/api/request-password-recovery', { email });
}

export function verifyConfirmationCode(formData) {
  return http.post('/api/verify-confirmation-code', formData);
}

export function resendConfirmationCode(email) {
  http.post('/api/resend-confirmation-code', { email });
}
