import { useState } from 'react';

import { Icon } from 'shared/components/Icon';
import { createOrder } from 'shared/http/orders.http';
import { useCartPayment } from 'shared/useCartPayment';
import { AcceptContract } from 'shared/payment/AcceptContract';
import { trackCheckoutPurchase } from 'shared/analytics';

export function StepAcceptContract() {
  const { cupomText, billingAddress, backStep, backToStart, proximo, setOrder } = useCartPayment();
  const [accept, setAccept] = useState(false);
  const [isLoadingTerms, setIsLoadingTerms] = useState(true);
  const [error, setError] = useState('');

  function next() {
    if (!accept) {
      return;
    }

    createOrder({
      billingAddress,
      codigoCupom: cupomText, //FIXME: renomear
    })
      .then((order) => {
        trackCheckoutPurchase(order);
        setOrder(order);
        proximo();
      }, (err) => {
        setError(err.message);
      });
  }

  function onAccept({contractAccepted}) {
    setAccept(contractAccepted);
  }

  if (error) {
    return (
      <>
        <div className="arrow-back" onClick={backStep}>
          <Icon icon="left-arrow-desktop" />
        </div>
        <div className="accept-contract-page">
          <h2>Ops! Ocorreu um erro ao iniciar o pagamento do seu pedido:</h2>

          <p className="payment-error-detail">
            {error}
          </p>

          <p>Revise seus dados cadastrais ou tente novamente</p>

          <button
            onClick={backToStart}
            type="button"
            className="btn btn-primary btn-lg"
          >
            Voltar
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="arrow-back" onClick={backStep}>
        <Icon icon="left-arrow-desktop" />
      </div>
      <div className="accept-contract-page">
        <h2 className="text-weight-bold">
          <strong>Contrato</strong>
        </h2>
        <AcceptContract setIsLoadingTerms={setIsLoadingTerms} onAccept={onAccept} />

        {!isLoadingTerms && (
          <button
            onClick={next}
            type="button"
            className="btn btn-primary btn-lg"
            disabled={!accept}
          >
            PAGAR
          </button>
        )}
      </div>
    </>
  );
}
