import PropTypes from 'prop-types';

import { SelectedModelVariations } from 'shared/components/SelectedModelVariations';
import { LoaderIcon } from 'shared/components/LoaderIcon';

import { AddToShoppingCartDialog } from '../../shopping-cart/AddToShoppingCartDialog';

import { SelectedFabric } from './SelectedFabric';

export function MoldToolbar({ model, fabric, userFabric, variation, isLoading }) {
  return (
    <div className="mold-toolbar hidden-xs">
      <div>
        <SelectedFabric fabric={fabric} userFabric={userFabric} />
        {model && model._id && (
          <AddToShoppingCartDialog>
            <button type="button" className="btn btn-block btn-custom">
              Pedir Moldes
            </button>
          </AddToShoppingCartDialog>
        )}
      </div>

      {isLoading ? (<LoaderIcon />) : (

        <SelectedModelVariations model={model} variation={variation} />
      )}
    </div>
  );
}

MoldToolbar.propTypes = {
  model: PropTypes.object,
  variation: PropTypes.string,
  fabric: PropTypes.object,
  userFabric: PropTypes.object,
  isLoading: PropTypes.bool
};
