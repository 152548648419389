import { useNavigate } from 'shared/helpers/useNavigate';
import { CartPaymentProvider } from 'shared/CartPaymentContext';
import { useCartPayment } from 'shared/useCartPayment';

import { StepSelectAddress } from './StepSelectAddress';
import { StepSummary } from './StepSummary';
import { StepAcceptContract } from './StepAcceptContract';
import { Sucesso } from './Sucesso';

export function CartPaymentPage() {
  const { navigate } = useNavigate();

  return (
    <CartPaymentProvider
      onEmptyCart={() => {
        navigate('/');
      }}
    >
      <CompraSteps />
    </CartPaymentProvider>
  );
}

function CompraSteps() {
  const { stepCart } = useCartPayment();

  function renderSwitch(stepCart) {
    switch (stepCart) {
      case 0:
        return <StepSelectAddress />;
      case 1:
        return <StepSummary />;
      case 2:
        return <StepAcceptContract />;
      case 3:
        return <Sucesso />;

      default:
        return '';
    }
  }

  return renderSwitch(stepCart);
}
