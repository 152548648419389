import { onlyNumbers } from '../validations';

export function formatMoney(value) {
  if (!value) {
    return 'R$ 0,00';
  }

  //.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  return `R$ ${value.toFixed(2).replace('.', ',')}`;
}

export function formatPhone(value) {
  if (!value) {
    return '';
  }

  const [phoneNumberPt1, phoneNumberPt2, areaCode, countryCode] = value
    .split(/(.{2})?(.{2})?(.{4,5})(.{4})$/)
    .filter((s) => Boolean(s))
    .reverse();

  let phoneNumberStr = `${phoneNumberPt2}-${phoneNumberPt1}`;

  if (areaCode) {
    phoneNumberStr = `(${areaCode}) ${phoneNumberStr}`;
  }

  if (countryCode) {
    phoneNumberStr = `+${countryCode} ${phoneNumberStr}`;
  }

  return phoneNumberStr;
}

export function formatDate(value) {
  return (value && new Date(value).toLocaleDateString()) || '';
}

export function formatDateTime(value) {
  return (value && new Date(value).toLocaleString()) || '';
}

export function formatCEP(value) {
  if (!value) {
    return;
  }

  const cleanZipValue = onlyNumbers(value);

  return `${cleanZipValue.slice(0, 5)}-${cleanZipValue.slice(5)}`;
}

export function formatAddress(address) {
  if (!address) {
    return;
  }

  return `${address.street} ${address.number} ${address.complement || ''}, ${address.district} , ${formatCEP(
    address.zip
  )}, ${address.city}/${address.state}`;
}
