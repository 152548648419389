export function BustIcon() {
  return (
    <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="42px" height="40px" viewBox="0 0 41.271 40">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#fff"
          d="M13.395,3.125v-2.5c0,0-1.445-0.625-3.189-0.625 S7.016,0.625,7.016,0.625v2.5c0,0-2.854,0.779-3.827,1.25S0,6.25,0,6.25v3.125c0,0,0.382,0.985,1.275,1.875 c0.893,0.89,3.189,3.714,3.189,6.25c0,2.536-1.224,5.297-2.552,7.5S0,31.105,0,34.375c0,3.27,8.292,3.75,8.292,3.75V40h1.913h1.914 v-1.875c0,0,8.292-0.48,8.292-3.75c0-3.27-0.585-7.172-1.914-9.375s-2.551-4.964-2.551-7.5c0-2.536,2.296-5.36,3.189-6.25 c0.893-0.89,1.276-1.875,1.276-1.875V6.25c0,0-2.217-1.404-3.189-1.875C16.25,3.904,13.395,3.125,13.395,3.125z M18.371,8.906 h-0.002c0,0-0.282,0.581-1.176,1.471c-0.893,0.89-3.29,4.279-3.29,7.123c0,2.781,1.303,5.922,2.631,8.125 c1.327,2.203,1.834,5.434,1.834,8.704c-1.419,1.641-7.143,1.828-7.143,1.828H9.186c0,0-5.724-0.188-7.143-1.828 c0-3.27,0.505-6.5,1.833-8.704c1.328-2.203,2.631-5.344,2.631-8.125c0-2.844-2.396-6.233-3.29-7.123 c-0.893-0.89-1.175-1.471-1.175-1.471V7.359c0,0,1.008-0.757,2.185-1.25c1.866-0.781,4.79-1.406,4.79-1.406l0-2.532 c0,0,0.634-0.213,1.189-0.213c0.555,0,1.189,0.213,1.189,0.213v2.531c0,0,2.923,0.625,4.789,1.406 c1.177,0.493,2.186,1.25,2.186,1.25L18.371,8.906z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#fff"
          d="M40.267,15.834c0.704-0.669,1.004-1.461,1.004-1.461 c0-0.844,0-1.689,0-2.538c0,0-1.749-1.307-2.517-1.845c-0.769-0.564-3.021-1.764-3.021-1.764c0-0.677,0-1.354,0-2.031 c0,0-1.142-0.746-2.517-0.88c-1.378-0.103-2.52,0.164-2.52,0.164c0,0.677,0,1.354,0,2.032c0,0-2.255-0.083-3.022,0.06 c-0.77,0.193-2.52,1.123-2.52,1.123c0,0.848,0,1.693,0,2.537c0,0,0.303,0.812,1.009,1.596c0.704,0.848,2.519,3.664,2.519,5.723 c0,2.06-0.966,4.003-2.015,5.527c-1.05,1.654-1.513,4.808-1.513,7.462c0,1.329,1.659,2.253,3.276,3.161 c1.654,0.93,3.273,1.468,3.273,1.468c0,0.506,0,3.012,0,3.518c0.505,0.072,1.009,0.108,1.512,0.125 c0.504,0.029,1.007,0.082,1.51,0.16c0-0.505,0-3.012,0-3.518c0,0,1.638,0.296,3.273,0.253c1.637-0.042,3.273-0.698,3.273-2.024 c0-2.655-0.459-5.841-1.51-7.744c-1.047-1.991-2.014-4.468-2.014-6.528C37.747,18.35,39.56,16.462,40.267,15.834z M39.658,13.849 c0,0-0.222,0.432-0.927,1.012c-0.705,0.556-2.597,2.823-2.597,5.132c0,2.263,1.027,5.078,2.078,7.138 c1.047,2.017,1.446,4.715,1.446,7.37c-0.56,0.559-1.969,0.591-3.238,0.485c-1.268-0.105-2.4-0.243-2.4-0.243 c-0.537-0.046-1.074-0.064-1.611-0.089c0,0-1.147-0.175-2.398-0.749c-1.285-0.588-2.68-1.418-3.241-2.227 c0-2.655,0.4-5.186,1.447-6.675c1.05-1.448,2.078-3.671,2.078-5.934c0-2.309-1.892-5.677-2.597-6.614 c-0.706-0.906-0.929-1.429-0.929-1.429s-0.001,0-0.002,0c0.001-0.417,0.001-0.838,0.001-1.259c0,0,0.796-0.416,1.725-0.534 c1.472-0.15,3.782-0.143,3.782-0.143c0-0.688,0-1.372,0-2.055c0,0,0.501-0.147,0.938-0.129c0.438,0.021,0.938,0.249,0.938,0.249 c0,0.684,0,1.368,0,2.056c0,0,2.31,0.98,3.782,1.992c0.93,0.617,1.725,1.386,1.725,1.386 C39.658,13.012,39.658,13.432,39.658,13.849z"
        />
      </g>
    </svg>
  );
}
