import { useAuthStates } from 'shared/auth/auth-state-machine';
import { useRedirectIfAuthenticated } from 'shared/auth/useRedirectIfAuthenticated';

export function LoginPage(params) {
  const { Component, data, onGoBack, onProceed } = useAuthStates({ initialState: 'email' });

  useRedirectIfAuthenticated();

  return <Component onProceed={onProceed} onGoBack={onGoBack} data={data} params={params} />;
}
