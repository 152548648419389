export const DownloadIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 25 25">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M20.5,17.982v-2.594c1.234-1.262,2-2.984,2-4.888 c0-3.866-3.134-7-7-7c-3.524,0-6.41,2.612-6.899,6H6.5c-2.209,0-4,1.791-4,4c0,1.477,0.81,2.752,2,3.445v2.188 c-2.325-0.827-4-3.024-4-5.633c0-3.314,2.687-6,6-6c0.173,0,0.335,0.037,0.504,0.051C8.227,4.031,11.564,1.5,15.5,1.5 c4.97,0,9,4.029,9,9C24.5,13.62,22.912,16.368,20.5,17.982z M8.917,18.649l2.583,1.743V12.5c0-0.552,0.448-1,1-1s1,0.448,1,1v7.892 l2.583-1.743c0.455-0.307,1.036-0.125,1.298,0.406c0.263,0.532,0.106,1.211-0.348,1.519l-3.762,2.539 c-0.013,0.016-0.033,0.024-0.046,0.039c-0.076,0.091-0.16,0.162-0.255,0.216c0,0.001-0.001,0.001-0.002,0.001 c-0.118,0.067-0.241,0.104-0.372,0.11c-0.006,0-0.011,0.005-0.018,0.005c-0.011,0.001-0.021,0.005-0.032,0.006 c-0.016,0-0.03,0.009-0.046,0.009s-0.032-0.005-0.047-0.009c-0.011-0.001-0.021-0.005-0.032-0.007 c-0.01-0.002-0.02-0.005-0.031-0.006c-0.089-0.006-0.174-0.018-0.258-0.052c-0.036-0.014-0.064-0.038-0.099-0.056 c-0.067-0.038-0.133-0.073-0.192-0.129c-0.034-0.03-0.053-0.073-0.083-0.107l-3.792-2.56c-0.455-0.307-0.61-0.986-0.348-1.519 C7.882,18.524,8.463,18.342,8.917,18.649z"
    />
  </svg>
);
