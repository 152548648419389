export function ArrowIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.695" height="7.41" viewBox="0 0 17.695 7.41">
      <path
        fill="#fff"
        id="ic_keyboard_arrow_right_24px"
        d="M0,15.616,4.58,8.848,0,2.079,1.41,0l6,8.848-6,8.848Z"
        transform="translate(17.695) rotate(90)"
      />
    </svg>
  );
}
