export const NewOrderIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 25 25">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M22.5,24.508h-20c-1.104,0-2-0.895-2-2v-20c0-1.104,0.896-2,2-2 h12.808l-2,2H2.5v20h20V11.7l2-2v12.808C24.5,23.613,23.604,24.508,22.5,24.508z M10.5,19.508l-4.987,0.014L5.5,14.508L19.049,1.069 c0.767-0.77,2.011-0.77,2.777,0l2.084,2.09c0.768,0.77,0.768,2.018,0,2.787L10.5,19.508z M7.5,17.508h3l-3-3V17.508z M20.438,2.463 L9.5,13.508l2,2L22.521,4.553L20.438,2.463z"
    />
  </svg>
);
