export function UncheckedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g id="Grupo_2690" data-name="Grupo 2690" transform="translate(3873 -12993)">
        <g fill="#dd4739" stroke="#711a1a" id="Elipse_18" data-name="Elipse 18" transform="translate(-3873 12993)">
          <circle stroke="none" cx="15" cy="15" r="15" />
          <circle fill="none" cx="15" cy="15" r="14.5" />
        </g>
        <path
          opacity="0.4"
          id="ic_done_24px"
          d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z"
          transform="translate(-3870.4 12995.4)"
        />
      </g>
    </svg>
  );
}
