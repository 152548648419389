/* eslint-disable no-empty-function */
import { useState } from 'react';
import { Form, FormInput } from 'react-bootstrap-utils';
import PropTypes from 'prop-types';

import { Icon } from 'shared/components/Icon';
import {
  getTamanhoCinturaInvalidoErrorMessage,
  medidasInvalidas,
  searchTable,
  tamanhoCinturaEhValido,
} from 'shared/measures';

import { HelpWithMeasurement } from './HelpWithMeasurement';
import { PreviewSelectedModel } from './PreviewSelectedModel';

export function BuyMoldMeasures({ order, update, onClose, onProceed }) {
  const [showHelp, setShowHelp] = useState(false);
  const [state, setState] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  function calcular(inputedMeasures) {
    setState('calculando');

    if (!inputedMeasures.waist || !inputedMeasures.bust || !inputedMeasures.hip) {
      setErrorMessage('O preenchimento das medidas de busto, cintura e quadril são necessários para continuar.');
      setState('erro');

      return;
    }

    if (medidasInvalidas(inputedMeasures)) {
      setErrorMessage(
        'Não conseguimos calcular o tamanho da sua modelo de provas. As medidas do busto devem estar entre 80cm e 134cm e as do quadril	entre 90cm e 144cm. O quadril é sempre maior o que o busto e a diferença entre estas duas medidas não pode ser menor do que 4 cm ou maior do que 16 cm.'
      );
      setState('erro');

      return;
    }

    searchTable(inputedMeasures).then(
      ({ measure, table }) => {
        if (!tamanhoCinturaEhValido(inputedMeasures, measure)) {
          setErrorMessage(getTamanhoCinturaInvalidoErrorMessage(measure));
          setState('erro');

          return;
        }

        setState('calculado');
        setErrorMessage('');

        order.setMeasures(inputedMeasures, measure.name);
        order.setupTable(table);
        update(order);
      },
      () => {
        setState('erro');
        setErrorMessage('Ocorreu um erro no cálculo das suas medidas, tente novamente mais tarde.');
      }
    );
  }

  if (showHelp) {
    return (
      <div className="buy-mold-measures">
        <HelpWithMeasurement back={() => setShowHelp(false)} />
      </div>
    );
  }

  return (
    <div className="buy-mold-measures">
      <div className="row">
        <div className="col-sm-4 app-buy-mold-preview">
          <PreviewSelectedModel order={order._order || {}} />
        </div>
        <div className="col-sm-8 app-buy-mold-content">
          <a
            href=""
            className="float-right"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClose();
            }}
          >
            <span className="mc-icon mc-icon-inverse">
              <Icon icon="close" />
            </span>
          </a>

          <h4 className="text-center">Medidas da modelo de prova</h4>
          <p className="text-center">
            Preencha as medidas para ver a tabela completa com a indicação do tamanho de molde ideal para as suas
            medidas
          </p>

          <div className="form-horizontal">
            <Form initialValues={{}} onChange={calcular} onSubmit={() => {}} customActions={<></>}>
              <div className="form-group row">
                <label htmlFor="bustoModeloProva" className="col-sm-6 col-form-label">
                  BUSTO <span className="required">*</span>
                </label>
                <div className="col-sm-5">
                  <FormInput type="number" name="bust" min="80" max="134" step="1" required />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="cinturaModeloProva" className="col-sm-6 col-form-label">
                  CINTURA <span className="required">*</span>
                </label>
                <div className="col-sm-5">
                  <FormInput type="number" name="waist" step="1" required />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="quadrilModeloProva" className="col-sm-6 col-form-label">
                  QUADRIL <span className="required">*</span>
                </label>
                <div className="col-sm-5">
                  <FormInput type="number" name="hip" min="90" max="144" step="1" required />
                </div>
              </div>

              {state === 'calculando' ? (
                <div className="calculando text-center">Calculando tamanho da sua modelo...</div>
              ) : state === 'calculado' ? (
                <div className="calculado text-center">
                  <span className="text-right">
                    O tamanho da sua <br />
                    modelo de provas é {order.getSizeName()}
                  </span>
                  <span className="mc-icon mc-icon-inverse">
                    <Icon icon="chevron-right" />
                  </span>
                  <button type="button" className="btn btn-custom" onClick={onProceed}>
                    <strong>PROSSEGUIR</strong>
                  </button>
                </div>
              ) : state === 'erro' ? (
                <div className="erro-calculo">{errorMessage}</div>
              ) : (
                <div className="aguardando text-center">Insira as medidas acima para prosseguir...</div>
              )}
            </Form>
          </div>

          <hr />
          <div className="text-center">
            <button
              type="button"
              className="btn-transparent"
              onClick={() => {
                setShowHelp(true);
              }}
            >
              <span className="mc-icon mc-icon-inverse">
                <Icon icon="help-alt" />
              </span>
              Saiba como medir a sua modelo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
BuyMoldMeasures.propTypes = {
  order: PropTypes.object,
  update: PropTypes.func,
  onClose: PropTypes.func,
  onProceed: PropTypes.func,
};
