import { useState } from 'react';
import PropTypes from 'prop-types';

import { BuyMoldMeasures } from './BuyMoldMeasures';
import { BuyMoldSizes } from './BuyMoldSizes';
import { BuyMoldSuccess } from './BuyMoldSuccess';

import './HelpWithMeasurement';
import './PreviewSelectedModel';

const steps = ['measures', 'sizes', 'success'];

export function BuyMold({ order: baseOrder, onClose, onSuccess, initialStep }) {
  const [actualStep, setActualStep] = useState(initialStep);
  const [order, setOrder] = useState(baseOrder);

  function goToNextStep() {
    if (actualStep >= steps.length) {
      return;
    }

    setActualStep((step) => step + 1);
  }

  function goToPreviousStep() {
    if (actualStep === 0) {
      return;
    }

    setActualStep((step) => step - 1);
  }

  function isActive(step) {
    return steps[actualStep] === step;
  }

  function buy(sizes) {
    order.setSizes(sizes);
    const _order = update(order);

    return onSuccess(_order);
  }

  function update(_order) {
    const newOrderReference = _order.clone();

    setOrder(newOrderReference);

    return newOrderReference;
  }

  return (
    <div className="buy-mold">
      {isActive('measures') ? (
        <BuyMoldMeasures order={order} update={update} onClose={onClose} onProceed={goToNextStep} />
      ) : isActive('sizes') ? (
        <BuyMoldSizes
          order={order}
          update={update}
          onGoBack={goToPreviousStep}
          onClose={onClose}
          onProceed={goToNextStep}
          onBuy={buy}
        />
      ) : isActive('success') ? (
        <BuyMoldSuccess order={order} onClose={onClose} />
      ) : (
        <em>Erro</em>
      )}
    </div>
  );
}
BuyMold.propTypes = {
  order: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  initialStep: PropTypes.number,
};
