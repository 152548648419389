import useSWR from 'swr';

import { swrFetcher } from 'shared/helpers/http';

export function useCoupom(code) {
  const { data, error } = useSWR(code && `/api/cupons-desconto?codigo=${code}`, swrFetcher);

  return {
    coupom: data,
    isLoading: Boolean(code && !error && !data),
    isError: error,
  };
}
