import PropTypes from 'prop-types';

import { SVGModelo } from 'shared/components/SVGModelo';
import { getValue } from 'shared/helpers/getters-setters';
import { useNavigate } from 'shared/helpers/useNavigate';
import { useSearch } from 'shared/helpers/useSearch';
import { isModelCompatibleWithFabric } from 'shared/models';
import { trackSelectModel } from 'shared/analytics';

let modelosSelecionados = 0;

export function ModelThumbnail({ model, fabric, inviteIfNotLogged }) {
  const { 'subgrupo-tecido': fabricSubgroupId } = useSearch();
  const { updateSearch } = useNavigate();

  function select() {
    trackSelectModel(model, fabric);
    if (!isModelCompatibleWithFabric(model, fabricSubgroupId)) {
      // eslint-disable-next-line no-console
      console.log('invalid subgroup');

      //TODO: exibir aviso
      /*
      <div class="modal-header">
					<h3 class="modal-title">Modelo incompatível com tecido selecionado</h3>
				</div>
				<div class="modal-body">
					O modelo escolhido é incompatível com o tecido selecionado.
					Você deseja remover o tecido selecionado e prosseguir com o modelo escolhido?
				</div>
				<div class="modal-footer">
					<button class="btn btn-primary" type="button" ng-click="$ctrl.ok()">Sim</button>
					<button class="btn btn-warning" type="button" ng-click="$ctrl.cancel()">Não</button>
				</div>
      */
      return;
    }

    modelosSelecionados += 1;

    if (modelosSelecionados >= 11) {
      inviteIfNotLogged();
      modelosSelecionados = 0;
    }

    updateSearch({
      'modelo': model._id,
      variacao: undefined,
    });
  }

  return (
    <div className="model-thumbnail">
      <div className="thumbnail" onClick={select}>
        <div className="model">
          <SVGModelo svg={getValue(model, 'default_unfolding.nodes')} tecido={fabric} />
        </div>
        <div className="caption">{model.code}</div>
      </div>
    </div>
  );
}
ModelThumbnail.propTypes = {
  model: PropTypes.object,
  fabric: PropTypes.object,
  inviteIfNotLogged: PropTypes.func.isRequired,
};
