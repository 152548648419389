import { createBrowserHistory, createMemoryHistory } from 'history';

import { stringify } from './query-string';

const canUseDOM = Boolean(typeof window !== 'undefined' && window.document && window.document.createElement);

const isServer = !canUseDOM;

export const history = isServer
  ? createMemoryHistory({
      initialEntries: [],
    })
  : createBrowserHistory();

export const navigate = (history, pathname, search) => {
  history.push({
    pathname,
    search: search ? stringify(search) : undefined,
  });
};

export const replace = (history, pathname, search) => {
  history.replace({
    pathname,
    search: stringify(search),
  });
};
