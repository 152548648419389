export default {
  V: {
    1: 'skinny',
    2: 'boot cut',
    3: 'flare',
    4: 'reta',
    5: 'baggy',
    6: 'pantalona',
    7: 'saruel',
  },
  VM: {
    1: 'legging',
    2: 'regular',
    3: 'reta',
    4: 'pantalona',
    5: 'saruel',
  },
  F: {
    1: 'justa',
    2: 'regular',
    3: 'folgada',
    4: 'larga',
  },
  C: {
    1: 'baixa',
    2: 'regular',
    3: 'alta',
  },
  BC: {
    1: 'micro',
    2: 'mini',
    3: 'clássico',
    4: 'no joelho',
  },
  CPR: {
    1: 'micro',
    2: 'mini',
    3: 'clássico',
    4: 'no joelho',
    5: 'midi',
    6: 'longuette',
    7: 'longo',
  },
  MCPR: {
    1: 'micro',
    2: 'mini',
    3: 'médio',
    4: 'no joelho',
    5: 'pescador',
    6: 'tornozelo',
    7: 'longo',
  },
  MG: {
    0: 'japonesa',
    1: 'mini manga',
    2: 'manga curta',
    3: 'clássica',
    4: '3 quartos',
    5: 'comprida',
    6: 'outra',
    7: 'sem manga',
  },
  SS: {
    1: 'elástico',
    2: 'malha punho',
    3: 'dobrado',
    4: 'meio elástico',
  },
  ABT: {
    1: 'clássico cintura',
    2: 'clássico',
    3: 'trespasse duplo',
    4: 'tr duplo cintura',
  },
  G: {
    1: 'olímpica',
    2: 'rolê',
    3: 'com zipper',
    4: 'polo',
    5: 'cacharrel',
    6: 'lapela',
    7: 'gola padre',
  },
  CV: {
    1: 'clássica',
    2: 'atleta',
    3: 'raglan',
    4: 'japonesa',
    5: 'alças',
    6: 'outra',
  },
  D: {
    0: 'canoa',
    1: 'decote U',
    2: 'decote V',
    3: 'tomara que caia',
    4: 'careca',
    5: 'um ombro só',
    6: 'frente única',
    7: 'outro',
  },
};
