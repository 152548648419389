/* eslint-disable react/no-unknown-property */
import { Component } from 'react';
import PropTypes from 'prop-types';

export class SVGModelo extends Component {
  constructor(props) {
    super(props);

    this.patternId = (Math.random() * 10).toString().replace('.', '');
  }

  trimSVG() {
    const { modo, boneca } = this.props;
    const svg = this.elem;
    const costas = svg.querySelector('g[id^="Costas"]');
    const frente = svg.querySelector('g[id^="Frente"]');

    if (!costas || !frente) {
      return;
    }

    frente.style.visibility = 'visible';
    costas.style.visibility = 'visible';

    let box;

    if (modo === 'completo') {
      box = svg;
    } else if (modo === 'costas') {
      box = costas;
      frente.style.visibility = 'hidden';
    } else {
      box = frente;
      costas.style.visibility = 'hidden';
    }

    if (!boneca) {
      this.removerBonecas(svg);
    }

    const dimensoes = box.getBBox();

    svg.viewBox.baseVal.x = dimensoes.x;
    svg.viewBox.baseVal.y = dimensoes.y;
    svg.viewBox.baseVal.width = dimensoes.width;
    svg.viewBox.baseVal.height = dimensoes.height;

    setTimeout(() => {
      removeAllElements(svg.querySelectorAll('rect[id^="Retângulo"]'));

      setTimeout(() => {
        const dimensoes = frente.getBBox();

        if (svg.viewBox.baseVal) {
          svg.viewBox.baseVal.y = dimensoes.y;
          svg.viewBox.baseVal.height = dimensoes.height;
        }
      }, 0);
    });
  }

  aplicarTecido() {
    const { tecido, tecidoUsuario } = this.props;

    const svg = this.elem;

    const pathsToFill = svg.querySelectorAll(
      'g[id^="Peça"] g[style], g[id^="Peça"] path[style], g[id^="Peça"] polygon[style], g[id^="Peça"] line[style], g[id^="Peça"] ellipse[style], g[id^="Peça"] polyline[style], g[id^="Peça"] rect[style]'
    );

    for (let index = 0; index < pathsToFill.length; index += 1) {
      const elem = pathsToFill[index];
      const pathStyle = elem.style;

      if (pathStyle.fill !== 'none') {
        if (tecido || tecidoUsuario) {
          //${window.location.origin} no iOS
          pathStyle.fill = `url(#${this.patternId})`;
        } else {
          pathStyle.fill = '#f1f1f2';
        }
      }
    }

    svg.style.display = 'none';
    svg.style.display = 'inline-block';
  }

  componentDidMount() {
    this.trimSVG();
    this.aplicarTecido();
  }

  componentDidUpdate() {
    this.trimSVG();
    this.aplicarTecido();
  }

  removerBonecas(svg) {
    removeAllElements(svg.querySelectorAll('[id^="Boneca"]'));
  }

  getUrlTecido(tecido, tecidoUsuario) {
    if (tecidoUsuario) {
      return tecidoUsuario.images.applicable.url;
    }

    if (!tecido) {
      return '';
    }

    if (tecido.fillType === 'blocos') {
      return tecido.images.applicable.url;
    }

    return tecido.images.url;
  }

  render() {
    const { svg, tecido, tecidoUsuario } = this.props;
    const urlTecido = this.getUrlTecido(tecido, tecidoUsuario);

    let width = 180;

    let height = 180;

    let y = 0;

    let x = 0;

    if (tecido && tecido.fillType === 'completo') {
      x = 363;
      y = 756.5;
      width = 726;
      height = 1513;
    }

    return (
      <svg
        className="modelo-svg"
        ref={(elem) => (this.elem = elem)}
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 0 0"
      >
        <defs>
          <pattern id={this.patternId} patternUnits="userSpaceOnUse" x={x} y={y} width={width} height={height}>
            <image xlinkHref={urlTecido} x="0" y="0" width={width} height={height} crossOrigin="anonymous" />
          </pattern>
        </defs>
        <g id="unfolding" dangerouslySetInnerHTML={{ __html: svg }} />
      </svg>
    );
  }
}

SVGModelo.defaultProps = {
  retangulo: true,
};

SVGModelo.propTypes = {
  svg: PropTypes.string,
  tecido: PropTypes.object,
  tecidoUsuario: PropTypes.object,
  modo: PropTypes.string,
  boneca: PropTypes.bool,
};

function removeAllElements(elements) {
  for (let i = 0; i < elements.length; i += 1) {
    elements[i].remove();
  }
}
