export function RightArrowAltIcon() {
  return (
    <svg viewBox="0 0 16 25" xmlns="http://www.w3.org/2000/svg">
      <g className="right-arrow">
        <path d="M 1 0 L 0 0 L 0 25 L 1 25 L 13 13 Z" className="background" />
        <path className="border" d="M 1 0 L 0 0 L 0 1 L 0 1 L 12 13 L 0 24 L 0 24 L 0 25 L 1 25 L 13 13 Z" />
      </g>
    </svg>
  );
}
