import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormInput, useToasts } from 'react-bootstrap-utils';

import { authenticateUser } from 'shared/http/auth.http';

import { Icon } from '../components/Icon';

export function ValidateUserPasswordForm({ onProceed, onGoBack, data }) {
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const { showToast } = useToasts();

  function onSubmit(formData) {
    authenticateUser(formData)
      .then(onProceed)
      .catch(() => {
        showToast('Não foi possível realizar o login. Email ou senha incorretos', {
          type: 'danger',
        });
      });
  }

  function recoverPassword(e) {
    e.preventDefault();
    e.stopPropagation();

    onProceed({ forgotPassword: true });
  }

  /*
    Mensagem original: senha.length < 8 ? 'Senha curta demais' : 'Senha inválida';
  */
  return (
    <div className="validate-user-password-form login-page-step">
      <Form
        initialValues={data}
        onSubmit={onSubmit}
        customActions={
          <button type="submit" className="btn btn-lg btn-primary">
            PRÓXIMO
          </button>
        }
      >
        <p className="bem-vindo">Bem-vindo de volta!</p>

        <div className="input-with-addon">
          <button type="button" onClick={onGoBack}>
            <Icon icon="edit" />
          </button>
          <FormInput name="email" readOnly />
        </div>

        <div className="input-with-addon">
          <button type="button" onClick={() => setPasswordVisibility((v) => !v)}>
            <Icon icon={isPasswordVisible ? 'eye-off' : 'eye-on'} />
          </button>
          <FormInput
            name="password"
            type={isPasswordVisible ? 'text' : 'password'}
            minLength="8"
            placeholder="Sua senha"
            required
          />
        </div>

        <a href="" onClick={recoverPassword} className="extra-link">
          Recuperar senha
        </a>
      </Form>
    </div>
  );
}
ValidateUserPasswordForm.propTypes = {
  onProceed: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  data: PropTypes.object,
};
