export const HelpAltIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" viewBox="0 0 25 25">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#5D5E5E"
        d="M12.5,0C5.6,0,0,5.6,0,12.5C0,19.4,5.6,25,12.5,25
				S25,19.4,25,12.5C25,5.6,19.4,0,12.5,0z M12.1,19.2c-0.7,0-1.3-0.6-1.3-1.4c0-0.8,0.6-1.4,1.3-1.4c0.7,0,1.3,0.6,1.3,1.4
				C13.4,18.6,12.8,19.2,12.1,19.2z M14.2,12.7c-1,1.1-1,1.7-1,2.4H11c0-1.6,0-2.3,1.5-3.8c0.6-0.6,1.1-1.1,1-2
				C13.5,8.4,12.8,8,12.1,8c-0.8,0-1.6,0.6-1.6,2.2H8.3c0-2.6,1.5-4.3,3.8-4.3c1.1,0,2.1,0.4,2.7,1c0.6,0.6,0.9,1.5,0.9,2.5
				C15.8,10.9,14.9,11.9,14.2,12.7z"
      />
    </g>
  </svg>
);
