import PropTypes from 'prop-types';

import { useNavigate } from 'shared/helpers/useNavigate';
import { Icon } from 'shared/components/Icon';

export function SelectedFabric({ fabric, userFabric }) {
  const { navigate, updateSearch } = useNavigate();

  function showFabrics() {
    navigate('/tecidos', {}, { inherit: true });
  }

  function remove() {
    updateSearch({
      'amostra-tecido': undefined,
      'tecido-usuario': undefined,
    });
  }

  if (userFabric) {
    return (
      <div className="selected-fabric">
        <div className={`image ${userFabric ? '' : 'image-empty'}`}>
          {userFabric && <img className="image-item" src={userFabric.images.thumbnail.url} alt="tecido do usuario" crossOrigin="anonymous" />}
          <div className="image-cue" onClick={showFabrics}>
            <div>
              <span>{userFabric ? 'Clique para escolher outro tecido' : 'Selecione um tecido'}</span>
            </div>
          </div>
          <div className="image-remove" onClick={remove} title="Tirar tecido">
            <span className="mc-icon">
              <Icon icon="close-slim" />
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="selected-fabric">
      <div className={`image ${fabric ? '' : 'image-empty'}`}>
        {fabric && <img className="image-item" src={fabric.images.thumbnail.url} alt="tecido selecionado" crossOrigin="anonymous" />}
        <div className="image-cue" onClick={showFabrics}>
          <div>
            <span>{fabric ? 'Clique para escolher outro tecido' : 'Selecione um tecido'}</span>
          </div>
        </div>
        <div className="image-remove" onClick={remove} title="Tirar tecido">
          <span className="mc-icon">
            <Icon icon="close-slim" />
          </span>
        </div>
      </div>
    </div>
  );
}

SelectedFabric.propTypes = {
  fabric: PropTypes.object,
  userFabric: PropTypes.object,
};
