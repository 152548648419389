export const SaveIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 25 25">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M22.5,24.5h-3h-14h-3c-1.104,0-2-0.896-2-2v-20 c0-1.104,0.896-2,2-2h14.943c0.001,0,0.002,0,0.003,0H17.5v0.006c0.277-0.009,0.556,0.072,0.768,0.283l5.91,5.911 c0.22,0.22,0.311,0.512,0.295,0.8H24.5v15C24.5,23.604,23.604,24.5,22.5,24.5z M7.5,22.5h10v-7h-10V22.5z M15.5,2.5h-6v6h6V2.5z M22.499,7.978l-4.999-5V8.5c0,1.104-0.896,2-2,2h-6c-1.105,0-2-0.896-2-2v-6h-5v20h3v-7c0-1.104,0.895-2,2-2h10 c1.104,0,2,0.896,2,2v7h2.999V7.978z"
    />
  </svg>
);
