import { Link } from 'react-router-dom';

import { formatMoney } from 'shared/helpers/formatters';
import { FormDesconto } from 'shared/components/FormDesconto';
import { Icon } from 'shared/components/Icon';
import { stringify } from 'shared/helpers/query-string';
import { useCartPayment } from 'shared/useCartPayment';
import { useCoupom } from 'shared/http/cupom-desconto.http';

export function StepSummary() {
  const { itens, cart, cupomText, proximo, setCupomText, backStep } = useCartPayment();
  const { coupom, isLoading, isError } = useCoupom(cupomText);

  const subtotal = cart.total;
  const desconto = subtotal * (coupom ? coupom.percentualDesconto : 0);
  const total = subtotal - desconto;

  return (
    <>
      <div className="arrow-back" onClick={backStep}>
        <Icon icon="left-arrow-desktop" />
      </div>
      <div className="confirm-order">
        <h2 className="text-lighttext-confirm-order">
          <strong>Confirme seu pedido</strong>
        </h2>
        <div className="itens-cart">
          {itens.map((item) => (
            <div key={item._id} className="item">
              <p className="text-light name-item">{item.code}</p>
              <p className="text-right text-light value-item">
                <strong>{formatMoney(item.total)}</strong>
              </p>
              <p className="text-light sizes-item">{item.sizes.map((size) => `${size.name}(${size.size}), `)}</p>
              <div id="hr" className="text-center" />
            </div>
          ))}
        </div>
        <div className="mc-icon">
          <Icon icon="arrow" />
        </div>

        <Link to={{ pathname: '/carrinho-compras', search: stringify({ backToResumo: true }) }}>
          <h6 className="text-light text-center">Alterar pedido</h6>
        </Link>

        <div className="values">
          <p className="text-right">
            Subtotal: <strong>{formatMoney(subtotal)}</strong>
          </p>
        </div>

        <div className="values">
          <div className="text-right">
            <FormDesconto
              coupom={coupom}
              isLoading={isLoading}
              isError={isError}
              desconto={desconto}
              onChange={(codigo) => {
                setCupomText(codigo);
              }}
            />
          </div>
        </div>

        <div className="values">
          <p className="text-right">
            Total: <strong>{formatMoney(total)}</strong>
          </p>
        </div>

        <button type="button" className="btn btn-primary btn-lg" onClick={proximo}>
          PRÓXIMO
        </button>
      </div>
    </>
  );
}
