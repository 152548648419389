import { useEffect, useRef } from 'react';
import { useFormControl } from 'react-bootstrap-utils';
import PropTypes from 'prop-types';

export function FormCustomSwitch({ name, onChange }) {
  const { getValue, setValue } = useFormControl(name);
  const ref = useRef(null);

  function toggle() {
    const nextValue = !getValue();

    setValue(nextValue);

    onChange(nextValue);
  }

  useEffect(() => {
    if (getValue()) {
      ref.current.classList.add('onoffswitch-checked');
    } else {
      ref.current.classList.remove('onoffswitch-checked');
    }
  }, [getValue()]);

  return (
    <div className="form-custom-switch">
      <div className="onoffswitch" ref={ref}>
        <div className="onoffswitch-label" onClick={toggle}>
          <span className="onoffswitch-inner" />
          <span className="onoffswitch-switch" />
        </div>
      </div>
    </div>
  );
}
FormCustomSwitch.defaultProps = {
  // eslint-disable-next-line no-empty-function
  onChange: () => {},
};
FormCustomSwitch.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
};
