/* eslint no-console:0 */
import fetch from 'cross-fetch';

import { stringify } from './query-string';

function _http(url, options) {
  const data = {
    method: options.method,
    body: options.body,
  };

  if (!options.isFormData) {
    data.body = JSON.stringify(options.body);
    data.headers = {
      'Content-Type': 'application/json',
    };
  }

  return fetch(url, data).then((res) => {
    if (!res.headers.get('content-type').includes('application/json')) {
      return res.text().then((text) => text);
    }

    return res
      .json()
      .catch((err) => {
        console.warn(err);

        return {};
      })
      .then((data) => {
        if (res.status >= 400) {
          const e = new Error(data.message || 'Erro desconhecido');

          e.status = res.status;
          e.stack = data.stack;
          throw e;
        }

        return data;
      });
  });
}

export const http = {
  get(url, query) {
    const q = stringify(query);

    return _http(`${url}${q && `?${q}`}`, {
      method: 'GET',
    });
  },
  post(url, body, { isFormData = false } = {}) {
    return _http(url, {
      method: 'POST',
      isFormData,
      body,
    });
  },
  put(url, body) {
    return _http(url, {
      method: 'PUT',
      body,
    });
  },
  delete(url) {
    return _http(url, {
      method: 'DELETE',
    });
  },
};

export async function swrFetcher(url) {
  const res = await fetch(url);

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.

  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.');

    // Attach extra info to the error object.

    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
}
