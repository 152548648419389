import { roundMoney } from './helpers/utils';
import { searchTable } from './measures';

export class MoldOrder {
  constructor(baseOrder) {
    this._order = { ...baseOrder };
  }

  static init(model, modelVariation, fabricToken, preferences, userFabric) {
    return this.initFromOrder(
      {
        kind: 'Mold',
        model,
        modelVariation: modelVariation || 0,
        modelLength: model && model.length,
        fabricToken,
        userFabric,
        measureTable: null,
        measures: {},
      },
      preferences
    );
  }

  static initFromOrder(_order, preferences) {
    const order = new MoldOrder(_order);

    order.setUnitPrice(preferences.mold_price_by_linear_meter);
    order.updateTotal();

    return Promise.resolve()
      .then(() => order.populateMeasureTable())
      .then(() => order);
  }

  clone() {
    return new MoldOrder(this._order);
  }

  populateMeasureTable() {
    const selectedSizeName = this._order.measures.sizeName;

    return this.getMeasureTable().then((measureTable) => {
      measureTable.measures.forEach((measure) => {
        if (measure.name === selectedSizeName) {
          measure.referenceModel = true;
        }

        const sizeFound = this._order.sizes?.find((size) => size.measure === measure._id || size.name === measure.name);

        if (sizeFound) {
          measure.selected = true;
        }
      });

      this._order.measureTable = measureTable;
      this.updateTotal();
    });
  }

  getMeasureTable() {
    const measureTable = this._order.measureTable;

    if (measureTable && typeof measureTable !== 'string') {
      return Promise.resolve(measureTable);
    }

    return searchTable(this._order.measures).then((res) => {
      if (!res) {
        throw new Error('Tabela de medidas não encontrada');
      }

      const measureTable = res.table;

      if (this._order.measureTable && this._order.measureTable !== measureTable._id) {
        throw new Error('Tabela de medidas incompatível com pedido original');
      }

      return measureTable;
    });
  }

  setUnitPrice(unitPrice) {
    this._order.unitPrice = unitPrice;
  }

  updateTotal() {
    if (!this._order.measureTable || !this._order.measureTable._id) {
      return 0;
    }

    const selectedSizes = this._order.measureTable.measures.reduce(
      (acc, measure) => acc + (measure.selected ? 1 : 0),
      0
    );

    this._order.total = roundMoney(this._order.modelLength * selectedSizes * this._order.unitPrice);

    return this.getTotal();
  }

  getTotal() {
    return this._order.total;
  }

  getTable() {
    return this._order.measureTable;
  }

  getMeasures() {
    return this._order.measures;
  }

  getSizePrice() {
    return roundMoney(this._order.unitPrice * this._order.modelLength);
  }

  getSizes() {
    const sizes = [];

    this._order.measureTable.measures.forEach((measure) => {
      if (!measure.selected) {
        return;
      }

      sizes.push({
        measure: measure._id,
        name: measure.name,
        size: measure.size,
        amount: 1,
      });
    });

    return sizes;
  }

  setSizes(sizes) {
    this._order.sizes = sizes;
  }

  getSizeName() {
    return this._order.measures.sizeName;
  }

  setMeasures(measures, sizeName) {
    this._order.measures = {
      ...this._order.measures,
      ...measures,
      sizeName,
    };
  }

  setupTable(table) {
    this._order.measureTable = table;

    const selectedSizeName = this._order.measures.sizeName;

    this._order.measureTable.measures.some((measure) => {
      if (measure.name === selectedSizeName) {
        measure.selected = true;
        measure.referenceModel = true;

        return true;
      }

      return false;
    });

    this.updateTotal();
  }

  updateMeasureTable(measures) {
    this._order.measureTable = {
      ...this._order.measureTable,
      measures,
    };

    this.updateTotal();
  }
}
