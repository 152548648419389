export function CartIcon() {
  return (
    <svg className="mc-icon" xmlns="http://www.w3.org/2000/svg" width="40px" height="32px" viewBox="0 0 40 32">
      <g>
        <path
          fill="#fff"
          d="M15.827,26.285c-0.744,0-1.474,0.303-2,0.836C13.301,27.653,13,28.391,13,29.144 c0,0.752,0.3,1.49,0.826,2.023c0.526,0.532,1.256,0.836,2,0.836c0.743,0,1.47-0.304,1.996-0.836c0.526-0.533,0.83-1.271,0.83-2.023 c0-0.753-0.304-1.491-0.83-2.023C17.296,26.587,16.57,26.285,15.827,26.285z"
        />
        <path
          fill="#fff"
          d="M31.43,26.285c-0.747,0-1.474,0.303-2,0.836c-0.526,0.532-0.826,1.271-0.826,2.023 c0,0.752,0.3,1.49,0.826,2.023c0.526,0.532,1.253,0.836,2,0.836c0.743,0,1.47-0.304,1.995-0.836c0.526-0.533,0.83-1.271,0.83-2.023 c0-0.753-0.304-1.491-0.83-2.023C32.9,26.587,32.173,26.285,31.43,26.285z"
        />
        <path
          fill="#fff"
          d="M39.658,5.624c-0.328-0.452-0.848-0.751-1.402-0.751H15.391C14.431,4.873,14,5.035,14,6.006s1.04,1,2,1h21 l-4.723,14.169H14.662L6.537,1.191C6.281,0.507,5.628,0,4.906,0H1.736C0.776,0,0,0.035,0,1.006s1.04,1,2,1h2l8.548,20.285 c0.359,0.978,1.422,1.715,2.452,1.715h18c1.089,0,1.674-0.949,2-2l4.915-14.8C40.082,6.671,39.986,6.076,39.658,5.624z"
        />
      </g>
    </svg>
  );
}
