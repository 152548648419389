import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import { Icon } from 'shared/components/Icon';
import { useSearch } from 'shared/helpers/useSearch';
import { stringify } from 'shared/helpers/query-string';
import { getExtraInfoFromFabricToken, useFabricTokens } from 'shared/http/fabric-tokens.http';

import { FabricTokenDetailsDialog } from './FabricTokenDetailsDialog';

export function FabricTokens({ fabric, manufacturer, onStartLoading, onFinishLoading }) {
  const location = useLocation();
  const search = useSearch();
  const { fabricTokens: _fabricTokens, isLoading } = useFabricTokens(
    {
      fabricId: fabric && fabric._id,
      manufacturerId: manufacturer._id,
    },
    Boolean(fabric && fabric._id && manufacturer._id)
  );
  const fabricTokens = useMemo(
    () =>
      _fabricTokens.map((fabricToken) => ({
        ...fabricToken,
        extras: getExtraInfoFromFabricToken(fabricToken),
      })),
    [_fabricTokens]
  );

  useEffect(() => {
    if (isLoading) {
      onStartLoading();

      return;
    }

    onFinishLoading();
  }, [isLoading]);

  if (fabricTokens.length === 0) {
    return null;
  }

  return (
    <div className="fabric-tokens-by-manufacturer">
      <div className="row">
        {fabricTokens.map((fabricToken) => (
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4" key={fabricToken._id}>
            <div className="fabric-token">
              <div className="token">
                <img
                  src={fabricToken.images.thumbnail.url}
                  alt="amostra de tecido do fabricante"
                  crossOrigin="anonymous"
                />
                <div className="hoverlay">
                  <div className="middle">
                    <Link
                      to={{
                        ...location,
                        search: stringify({
                          ...search,
                          'amostra-tecido': fabricToken._id,
                          'tecido-usuario': undefined,
                        }),
                      }}
                    >
                      Aplicar
                    </Link>
                  </div>
                  <div className="bottom">
                    <a className="float-left">
                      <Icon icon="heart" class="mc-icon" />
                    </a>
                    <FabricTokenDetailsDialog fabricToken={fabricToken} manufacturer={manufacturer}>
                      <a href="" className="float-right">
                        <Icon icon="information" class="mc-icon" />
                      </a>
                    </FabricTokenDetailsDialog>
                  </div>
                </div>
              </div>
              <small className="caption">{fabricToken.name}</small>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
FabricTokens.propTypes = {
  fabric: PropTypes.object,
  manufacturer: PropTypes.object,
  onStartLoading: PropTypes.func,
  onFinishLoading: PropTypes.func,
};
