import { hydrate } from 'react-dom';
import { Router } from 'react-router';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { history } from 'shared/helpers/history';
import { ErrorPage } from 'shared/components/ErrorPage';
import { setupGA } from 'shared/analytics';
import { WhatsAppBtn } from 'shared/components/WhatsAppBtn';

import { DesktopApp } from './DesktopApp';

import './desktop.scss';

Sentry.init({
  dsn: process.env.SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

setupGA();

hydrate(
  <Router history={history}>
    <WhatsAppBtn />
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <DesktopApp />
    </Sentry.ErrorBoundary>
  </Router>,
  document.getElementById('desktop-root')
);

