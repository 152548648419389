import { useNavigate } from 'shared/helpers/useNavigate';

export function ErrorPage() {
  const { navigateBack } = useNavigate();

  const goBack = (e) => {
    e.preventDefault();
    navigateBack();
  };

  return (
    <div className="container-fluid pagina-nao-encontrada">
      <div>
        <h3>Um erro inesperado aconteceu...</h3>
        <p>Não se preocupe que já fomos informados sobre este problema.</p>
        <a href="/">voltar a página inicial</a>
        <button type="button" className="" onClick={goBack}>
          voltar a página anterior
        </button>
      </div>
    </div>
  );
}
