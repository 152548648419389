export function SidebarOpener() {
  const toggleMenuOpen = () => {
    const elem = document.querySelector('.sidebar');

    elem.classList.toggle('open');
  };

  const onClick = (e) => {
    e.preventDefault();
    toggleMenuOpen();
  };

  return (
    <button
      type="button"
      className="navbar-toggler sidebar-opener"
      aria-expanded="false"
      aria-label="Toggle navigation"
      onClick={onClick}
    >
      <img src="/assets/icons/menu.svg" alt="" />
    </button>
  );
}
