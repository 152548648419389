import useSWR from 'swr';

import { http, swrFetcher } from '../helpers/http';
import { onlyNumbers } from '../validations';

const baseUrl = '/api/billing-addresses';

export function useBillingAddresses() {
  const { data, error } = useSWR(baseUrl, swrFetcher);

  return {
    billingAddresses: data || [],
    isLoading: !error && !data,
    isError: error,
  };
}

export function useBillingAddress(billingAddressId) {
  const { data, error } = useSWR(billingAddressId && `${baseUrl}/${billingAddressId}`, swrFetcher);

  return {
    billingAddress: data,
    isLoading: Boolean(billingAddressId && !error && !data),
    isError: error,
  };
}

export function saveBillingAddress(id, dados) {
  return http.post(`${baseUrl}/${id || ''}`, dados);
}

export function deleteBillingAddress(id) {
  return http.delete(`${baseUrl}/${id}`);
}

export function useAddressFromZip(zip) {
  const cleanZipValue = onlyNumbers(zip);
  const shouldLoad = cleanZipValue.length === 8;
  const { data, error } = useSWR(shouldLoad && `/api/buscar-endereco?cep=${cleanZipValue}`, swrFetcher);

  return {
    address: data,
    isLoading: Boolean(shouldLoad && !error && !data),
    isError: error,
  };
}
