export const DayIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 25 25">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M12.5,6.469c-3.317,0-6.007,2.689-6.007,6.006 s2.689,6.006,6.007,6.006c3.317,0,6.006-2.689,6.006-6.006S15.817,6.469,12.5,6.469z M12.512,16.544 c-2.239,0-4.053-1.815-4.053-4.053s1.814-4.053,4.053-4.053c2.238,0,4.053,1.814,4.053,4.053S14.75,16.544,12.512,16.544z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M12.538,1.5h-0.062c-0.552,0-1,0.448-1,1v2c0,0.552,0.448,1,1,1 h0.062c0.552,0,1-0.448,1-1v-2C13.538,1.948,13.09,1.5,12.538,1.5z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M12.538,19.5h-0.062c-0.552,0-1,0.448-1,1v2c0,0.552,0.448,1,1,1 h0.062c0.552,0,1-0.448,1-1v-2C13.538,19.948,13.09,19.5,12.538,19.5z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M1.5,12.462v0.062c0,0.552,0.448,1,1,1h2c0.552,0,1-0.448,1-1 v-0.062c0-0.553-0.448-1-1-1h-2C1.948,11.462,1.5,11.909,1.5,12.462z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M19.5,12.462v0.062c0,0.552,0.448,1,1,1h2c0.552,0,1-0.448,1-1 v-0.062c0-0.553-0.448-1-1-1h-2C19.948,11.462,19.5,11.909,19.5,12.462z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M4.695,20.251l0.044,0.044c0.391,0.391,1.024,0.391,1.414,0 l1.415-1.415c0.391-0.39,0.391-1.023,0-1.414l-0.044-0.044c-0.39-0.391-1.023-0.391-1.414,0l-1.414,1.414 C4.304,19.228,4.304,19.86,4.695,20.251z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M17.423,7.523l0.044,0.044c0.391,0.391,1.024,0.391,1.415,0 l1.414-1.414c0.391-0.391,0.391-1.024,0-1.415l-0.044-0.044c-0.39-0.391-1.023-0.391-1.414,0l-1.414,1.414 C17.032,6.5,17.032,7.133,17.423,7.523z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M20.251,20.305l0.044-0.044c0.391-0.39,0.391-1.023,0-1.414 l-1.414-1.414c-0.391-0.391-1.024-0.391-1.415,0l-0.044,0.044c-0.391,0.391-0.391,1.024,0,1.415l1.414,1.414 C19.228,20.696,19.861,20.696,20.251,20.305z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M7.523,7.577l0.044-0.044c0.391-0.391,0.391-1.024,0-1.415 L6.153,4.705c-0.39-0.391-1.023-0.391-1.414,0L4.695,4.749c-0.391,0.39-0.391,1.023,0,1.414l1.414,1.414 C6.5,7.968,7.133,7.968,7.523,7.577z"
    />
  </svg>
);
