import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'react-bootstrap-utils';

import { MoldOrder } from 'shared/MoldOrder';
import { usePreferences } from 'shared/http/preferences.http';
import { editShoppingCartItem } from 'shared/http/shopping-cart.http';
import { LoaderIcon } from 'shared/components/LoaderIcon';

import { BuyMold } from '../catalog/buy-mold/BuyMold';

export function EditShoppingCartItemDialog({ item, children }) {
  return (
    <Dialog size="lg" body={({ close }) => <EditBuyMoldDialog item={item} close={close} />}>
      {children}
    </Dialog>
  );
}

EditShoppingCartItemDialog.propTypes = {
  children: PropTypes.node,
  item: PropTypes.object,
};

function EditBuyMoldDialog({ item, close }) {
  const [order, setOrder] = useState();
  const { preferences, isLoading: isLoadingPreferences } = usePreferences();
  const isLoading = isLoadingPreferences;

  function success(item) {
    return editShoppingCartItem(item._order);
  }

  useEffect(() => {
    if (isLoading) {
      return;
    }

    MoldOrder.initFromOrder(item, preferences).then((order) => {
      setOrder(order);
    });
  }, [isLoading]);

  if (isLoading || !order) {
    return <LoaderIcon />;
  }

  return <BuyMold order={order} initialStep={1} onSuccess={success} onClose={close} />;
}
EditBuyMoldDialog.propTypes = {
  close: PropTypes.func,
  item: PropTypes.object,
};
