export const CloseIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="9px" height="9px" viewBox="0 0 9 9">
    <polygon
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      points="8.992,1.505 7.495,0.008 4.5,3.002 1.505,0.008 0.008,1.505 3.002,4.5 0.008,7.495 1.505,8.992 4.5,5.998 7.495,8.992 8.992,7.495 5.998,4.5 "
    />
  </svg>
);
