import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useModels } from 'shared/http/models.http';
import { HorizontalScroll } from 'shared/components/scroll/HorizontalScroll';
import { trackViewedModels } from 'shared/analytics';
import { LoaderIcon } from 'shared/components/LoaderIcon';

import { ModelThumbnail } from './ModelThumbnail';

export function ModelsByFabricSubgroup({ modelType, fabricSubgroup, fabricToken, onFinishLoading, inviteIfNotLogged }) {
  const { isLoading, models } = useModels(
    {
      fabricSubgroupId: fabricSubgroup && fabricSubgroup._id,
      modelTypeId: modelType && modelType._id,
    },
    Boolean(fabricSubgroup && fabricSubgroup._id && modelType && modelType._id)
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    trackViewedModels(models);
    onFinishLoading(models.length);
  }, [isLoading, models]);

  return (
    <div className="models-by-fabric-subgroup models-by-type">
      {isLoading && <LoaderIcon />}

      {models.length > 0 && (
        <div className="row">
          <div className="col-sm-12">
            <div className="title">{modelType.name}</div>

            <HorizontalScroll>
              {models.map((model) => (
                <ModelThumbnail
                  key={model._id}
                  model={model}
                  fabric={fabricToken}
                  inviteIfNotLogged={inviteIfNotLogged}
                />
              ))}
            </HorizontalScroll>
            <hr />
          </div>
        </div>
      )}
    </div>
  );
}
ModelsByFabricSubgroup.propTypes = {
  modelType: PropTypes.object,
  fabricSubgroup: PropTypes.object,
  fabricToken: PropTypes.object,
  onFinishLoading: PropTypes.func,
  inviteIfNotLogged: PropTypes.func.isRequired,
};
