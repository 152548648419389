import PropTypes from 'prop-types';

import { SVGModelo } from 'shared/components/SVGModelo';
import { getSVGNodeFromModel } from 'shared/models';
import { useFabricToken } from 'shared/http/fabric-tokens.http';
import { useModel } from 'shared/http/models.http';
import { useUserFabric } from 'shared/http/user-fabrics.http';

export function RenderModel({ model, fabric, userFabric, variationIndex, frontMode, showDoll = true }) {
  //o pedido pode não estar populado e por isso deve ser carregado caso necessário
  const { fabricToken: backupFabricToken } = useFabricToken((fabric && fabric._id) || fabric);
  const { userFabric: backupUserFabric } = useUserFabric((userFabric && userFabric._id) || userFabric);
  const { model: backupModel } = useModel((model && model._id) || model);

  const selectedModel = model && model._id ? model : backupModel;
  const selectedFabricToken = fabric && fabric._id ? fabric : backupFabricToken;
  const selectedUserFabric = userFabric && userFabric._id ? userFabric : backupUserFabric;

  if (!selectedModel) {
    return null;
  }

  return (
    <div className="render-model">
      <SVGModelo
        svg={getSVGNodeFromModel(selectedModel, variationIndex)}
        tecido={selectedFabricToken}
        tecidoUsuario={selectedUserFabric}
        modo={frontMode ? 'frente' : 'completo'}
        boneca={showDoll}
      />
    </div>
  );
}
RenderModel.propTypes = {
  model: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  fabric: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  userFabric: PropTypes.object,
  variationIndex: PropTypes.number,
  frontMode: PropTypes.bool,
  showDoll: PropTypes.bool
};
