import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'shared/components/Icon';
import { useCartPayment } from 'shared/useCartPayment';
import { useNavigate } from 'shared/helpers/useNavigate';
import { useBillingAddresses } from 'shared/http/billing-address.http';

export function StepSelectAddress() {
  const { navigate } = useNavigate();
  const {
    selectedAddress: selectedAddressId,
    setSelectedAddress,
    setBillingAddress,
    proximo: goForward,
  } = useCartPayment();

  const { billingAddresses: addresses } = useBillingAddresses();

  function onSelect(address) {
    setSelectedAddress(address._id);
    setBillingAddress(address);
  }

  useEffect(() => {
    if (!addresses || addresses.length === 0) {
      return;
    }

    onSelect(addresses[0]);
  }, [addresses]);

  const goBack = () => {
    navigate('/carrinho-compras', { zoom: 4 });
  };

  return (
    <>
      <div className="arrow-back" onClick={goBack}>
        <Icon icon="left-arrow-desktop" />
      </div>
      <div className="billing-address">
        <h2 className="text-light mt-3 font-weight-bold text-address">Endereço</h2>
        {addresses.length === 0 ? (
          <p id="text-select-address" className="font-weight-light text-center">
            Você ainda não possui endereço cadastrado
          </p>
        ) : (
          <>
            <p id="text-select-address" className="font-weight-light">
              Selecione um endereço
            </p>
            <div className="registered-addresses">
              {addresses.map((address) => (
                <div
                  key={address._id}
                  className="address"
                  onClick={() => {
                    onSelect(address);
                  }}
                >
                  <p className="name text-center">{address.name}</p>
                  <div id="hr-svg">
                    <div className="iconsAddress">
                      {isSelected(address) ? <Icon icon="checked" /> : <Icon icon="unchecked" />}
                    </div>
                    <div id="hr" className="text-center" />
                  </div>
                  <p className="address-address text-center">
                    {`${address.address.street}, ${address.address.number} - ${address.address.district}, ${address.address.zip}, ${address.address.city}/${address.address.state}`}
                  </p>
                  <div className="icons">
                    <Link to={`/enderecos/${address._id}`}>
                      <div className="mc-icon">
                        <Icon icon="edit-desktop" />
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        <p className="add-address text-center">
          {addresses.length > 0 && <>ou </>}
          <Link to="/enderecos/novo">
            <u>Cadastre um novo endereço</u>
          </Link>
        </p>
        <button type="button" className="btn btn-primary btn-lg" disabled={!selectedAddressId} onClick={goForward}>
          PRÓXIMO
        </button>
      </div>
    </>
  );

  function isSelected(address) {
    return selectedAddressId === address._id;
  }
}
