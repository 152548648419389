import { useEffect } from 'react';
import { useNavigate } from 'shared/helpers/useNavigate';
import { useSearch } from 'shared/helpers/useSearch';
import { useSession } from 'shared/http/auth.http';

export function useRedirectIfAuthenticated() {
  const { isAuthenticated } = useSession();
  const { navigateToUrl } = useNavigate();
  const { redirectTo } = useSearch();

  useEffect(() => {
    if (isAuthenticated) {
      navigateToUrl(redirectTo || '/');
    }
  }, [isAuthenticated]);
}
