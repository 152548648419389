import useSWR from 'swr';

import { swrFetcher } from '../helpers/http';
import { stringify } from '../helpers/query-string';

const baseUrl = '/api/fabric-tokens';

export function useFabricTokens(filters, shouldLoad = true) {
  const searchStr = buildFabricTokensSearch(filters);

  const { data, error } = useSWR(shouldLoad && `${baseUrl}/${searchStr}`, swrFetcher);

  return {
    fabricTokens: data || [],
    isLoading: Boolean(shouldLoad && !error && !data),
    isError: error,
  };
}

function buildFabricTokensSearch({ fabricId, manufacturerId }) {
  return `?${stringify({
    'by_fabric_ids[]': fabricId,
    ...(manufacturerId ? { 'by_manufacturer_ids[]': manufacturerId } : {}),
  })}`;
}

export function useFabricToken(fabricTokenId) {
  const { data, error } = useSWR(fabricTokenId && `${baseUrl}/${fabricTokenId}`, swrFetcher);

  return {
    fabricToken: data,
    isLoading: Boolean(fabricTokenId && !error && !data),
    isError: error,
  };
}

export const getExtraInfoFromFabricToken = (token) => {
  const splittedName = token.name.split(' | ');

  if (splittedName.length < 1) {
    return;
  }

  return {
    reference: splittedName[0],
    name: splittedName[1],
    composition: splittedName[2],
    width: splittedName[3],
    color: splittedName[4],
    pieceslength: splittedName[5],
    minimum: splittedName[6],
    manufacturer: {
      name: splittedName[7],
      address: splittedName[8],
      phone: splittedName[9],
      email: splittedName[10],
    },
  };
};
