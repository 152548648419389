import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormInput } from 'react-bootstrap-utils';

import { resendConfirmationCode, verifyConfirmationCode } from 'shared/http/auth.http';

export function VerifyUserEmailForm({ onProceed, data }) {
  const [errorMessage, setErrorMessage] = useState('');

  function onSubmit(formData) {
    verifyConfirmationCode(formData).then(
      () => {
        onProceed();
      },
      (error) => {
        setErrorMessage(error.message);
      }
    );
  }

  function requestNewCode(e) {
    e.preventDefault();
    e.stopPropagation();

    resendConfirmationCode(data.email);
  }

  return (
    <div className="verify-user-email-form login-page-step">
      <Form
        initialValues={data}
        onSubmit={onSubmit}
        customActions={
          <button type="submit" className="btn btn-lg btn-primary">
            PRÓXIMO
          </button>
        }
        onChange={() => {
          setErrorMessage('');
        }}
      >
        <h1 className="conta-gratuita">Sua conta é gratuita e ilimitada.</h1>
        <h2 className="verify-your-email-title">Verifique o código no seu email.</h2>

        <FormInput name="code" type="tel" placeholder="Insira o código aqui" required />

        {errorMessage && <div className="error-message alert alert-danger">{errorMessage}</div>}

        <a href="" onClick={requestNewCode} className="extra-link">
          Reenviar Código
        </a>
      </Form>
    </div>
  );
}
VerifyUserEmailForm.propTypes = {
  onProceed: PropTypes.func.isRequired,
  data: PropTypes.object,
};
