import PropTypes from 'prop-types';

import { Icon } from 'shared/components/Icon';
import { useNavigate } from 'shared/helpers/useNavigate';
import { stringify } from 'shared/helpers/query-string';
import { useInviteIfNotLogged } from 'shared/auth/AccountInvite';

import { useUploadUserFabricDialog } from '../user-fabrics/UploadUserFabricDialog';

export function ActionsToolbar({ modelId, fabricTokenId, variationIndex }) {
  const { inviteIfNotLogged, InviteIfNotLoggedPortal } = useInviteIfNotLogged();
  const { UploadUserFabricDialogPortal, showUploadDialog } = useUploadUserFabricDialog();
  const { navigate } = useNavigate();

  async function print() {
    if (!modelId) {
      return;
    }

    const logado = await inviteIfNotLogged();

    if (logado) {
      return;
    }

    window.open(
      `/print-sheet?${stringify({
        'modelo': modelId,
        'variacao': variationIndex,
        'amostra-tecido': fabricTokenId,
      })}`,
      '_blank'
    );
  }

  async function uploadUserFabric(e) {
    e.stopPropagation();
    e.preventDefault();

    const convidado = await inviteIfNotLogged();

    if (convidado) {
      return;
    }

    showUploadDialog();
  }

  async function userFabrics(e) {
    e.stopPropagation();
    e.preventDefault();
    const convidado = await inviteIfNotLogged();

    if (convidado) {
      return;
    }

    navigate('/meus-tecidos');
  }

  return (
    <div className="actions-toolbar">
      <InviteIfNotLoggedPortal />
      <UploadUserFabricDialogPortal />
      <ul className="nav nav-pills flex-column">
        <li className="nav-item disabled">
          <a href="#salvar" className="nav-link">
            <div className="mc-icon">
              <div className="sr-only">Salvar</div>
              <Icon icon="save" />
            </div>
            <span>Salvar</span>
          </a>
        </li>
        <li className={`nav-item ${!modelId ? 'disabled' : ''}`}>
          <a href="#print" className="nav-link" onClick={print}>
            <div className="mc-icon">
              <div className="sr-only">Imprimir</div>
              <Icon icon="print" />
            </div>
            <span>Imprimir / pdf</span>
          </a>
        </li>
      </ul>

      <ul className="nav nav-pills flex-column">
        <li className="nav-item disabled">
          <a href="#zoom-in" className="nav-link">
            <div className="mc-icon">
              <div className="sr-only">Zoom in</div>
              <Icon icon="zoom-in" />
            </div>
            <span>Zoom in</span>
          </a>
        </li>
        <li className="nav-item disabled">
          <a href="#zoom-out" className="nav-link">
            <div className="mc-icon">
              <div className="sr-only">Zoom out</div>
              <Icon icon="zoom-out" />
            </div>
            <span>Zoom out</span>
          </a>
        </li>
      </ul>

      <ul className="nav nav-pills flex-column">
        <li className="nav-item disabled">
          <a href="#importar" className="nav-link">
            <div className="mc-icon">
              <div className="sr-only">Importar jpg</div>
              <Icon icon="download" />
            </div>
            <span>Importar jpg</span>
          </a>
        </li>
        <li className="nav-item disabled">
          <a href="#exportar" className="nav-link">
            <div className="mc-icon">
              <div className="sr-only">Exportar jpg</div>
              <Icon icon="upload" />
            </div>
            <span>Exportar jpg</span>
          </a>
        </li>
      </ul>

      <ul className="nav nav-pills flex-column">
        <li className="nav-item">
          <a href="#importar-tecido" className="nav-link" onClick={uploadUserFabric}>
            <div className="mc-icon">
              <div className="sr-only">Importar tecido</div>
              <Icon icon="enviar-meu-tecido" />
            </div>
            <span>Importar tecido</span>
          </a>
        </li>

        <li className="nav-item">
          <a href="#meus-tecidos" className="nav-link" onClick={userFabrics}>
            <div className="mc-icon">
              <div className="sr-only">Meus tecidos</div>
              <Icon icon="meus-tecidos" />
            </div>
            <span>Meus tecidos</span>
          </a>
        </li>
      </ul>
    </div>
  );
}

ActionsToolbar.propTypes = {
  modelId: PropTypes.string,
  variationIndex: PropTypes.number,
  fabricTokenId: PropTypes.string,
};
