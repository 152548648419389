import PropTypes from 'prop-types';

import { getValue } from 'shared/helpers/getters-setters';

export function FabricTokenInfo({ token, userFabric }) {
  return (
    <>
      <div className="fabric-token">
        <div className="token-image">
          {userFabric ? <img src={userFabric.images.thumbnail.url} width="300px" alt="tecido do usuario" crossOrigin="anonymous" />
            : <img src={getValue(token, 'images.url')} width="300px" alt="amostra de tecido" crossOrigin="anonymous" />}
        </div>
        <div className="token-caption">REF.: {token.name || '-'}</div>
      </div>

      <div className="fabric-info">
        <div className="flex">
          <div>
            <p>Composição: {token.composition || '-'}</p>
            <p>Cor: {token.color || '-'}</p>
            <p>Largura: {token.width || '-'}</p>
          </div>
          <div>
            <p>Peças de {token.pieceslength || '-'}</p>
            <p>Quantidade mínima: {token.minimum || '-'} peça</p>
          </div>
        </div>
      </div>
    </>
  );
}
FabricTokenInfo.defaultProps = {
  token: {},
};
FabricTokenInfo.propTypes = {
  token: PropTypes.object,
  userFabric: PropTypes.object
};
