import PropTypes from 'prop-types';
import { Dialog } from 'react-bootstrap-utils';

import { useNavigate } from 'shared/helpers/useNavigate';

export function FabricTokenDetailsDialog({ fabricToken, manufacturer, children }) {
  const { updateSearch } = useNavigate();

  function onSelect(close) {
    close();
    updateSearch({
      'amostra-tecido': fabricToken._id,
      'tecido-usuario': undefined,
    });
  }

  return (
    <Dialog
      body={({ close }) => (
        <FabricTokenDetails fabricToken={fabricToken} manufacturer={manufacturer} onSelect={() => onSelect(close)} />
      )}
    >
      {children}
    </Dialog>
  );
}
FabricTokenDetailsDialog.propTypes = {
  fabricToken: PropTypes.object,
  manufacturer: PropTypes.object,
  children: PropTypes.node,
};

function FabricTokenDetails({ fabricToken, manufacturer, onSelect }) {
  return (
    <div className="manufacturer-card">
      <div className="row">
        <div className="col-md-6">
          <img
            src={fabricToken.images.url || '/assets/image-placeholder.png'}
            style={{ height: '100%', width: '100%' }}
            alt="amostra de tecido"
            crossOrigin="anonymous"
          />
        </div>
        <div className="col-md-6 fabric-token-details-text" style={{ paddingRight: '35px' }}>
          <div className="page-header">
            <h4>{fabricToken.name}</h4>
          </div>

          <div>Composição: {fabricToken.composition || '-'}</div>
          <div>Largura: {fabricToken.width || '-'}</div>
          <div>Cor: {fabricToken.color || '-'}</div>
          <div>Metragem peças: {fabricToken.pieceslength || '-'}</div>
          <div>Quantidade mínima: {fabricToken.minimum || '-'}</div>

          <hr />

          <div className="manufacturer-logo">
            <h4>{manufacturer.name}</h4>
            <div>TÊXTIL FICTÍCIA IND & COM</div>
          </div>

          <h4>{manufacturer.name}</h4>
          <h5>{manufacturer.contact.name || '-'}</h5>
          <div>{manufacturer.contact.address || '-'}</div>
          <div>
            {manufacturer.contact.phone || '-'} / {manufacturer.contact.cellPhone || '-'}
          </div>
          <div>{manufacturer.contact.email || '-'}</div>

          <button type="button" className="btn btn-lg btn-block" onClick={onSelect}>
            Aplicar
          </button>
        </div>
      </div>
    </div>
  );
}
FabricTokenDetails.propTypes = {
  fabricToken: PropTypes.object,
  manufacturer: PropTypes.object,
  onSelect: PropTypes.func,
};
