import { useMemo } from 'react';
import PropTypes from 'prop-types';

export function MeasuresInfo({ rawMeasures }) {
  const measures = useMemo(() => {
    if (!rawMeasures) {
      return {};
    }

    let measures;

    try {
      measures = JSON.parse(rawMeasures);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return measures;
  }, [rawMeasures]);

  return (
    <>
      <div className={`box ${!measures.bust ? 'disabled' : ''}`}>
        <p>Busto: {measures.bust || '88'}cm</p>
        <p>Cintura: {measures.waist || '68'}cm</p>
        <p>Quadril: {measures.hip || '96'}cm</p>
      </div>
      <div className={`text-center box ${!measures.sizeName ? 'disabled' : ''}`}>
        <p>Tamanho de provas:</p>
        <p>{measures.sizeName || 'p'}</p>
      </div>
    </>
  );
}
MeasuresInfo.propTypes = {
  rawMeasures: PropTypes.string,
};
