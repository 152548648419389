import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
  buildModelCodeFromVariations,
  getVariationLabel,
  parseVariationFromModelCode,
  sortVariationValues,
} from 'shared/http/models.http';
import { useNavigate } from 'shared/helpers/useNavigate';

import { VerticalScroll } from './scroll/VerticalScroll';
import { trackSelectModelVariant } from '../analytics';

//FIXME: render labels according to import LabelsVariacoes from './label-variacoes';

export function SelectedModelVariations({ model, variation }) {
  const { updateSearch } = useNavigate();

  const variations = useMemo(() => {
    if (!model) {
      return [];
    }

    return model.variations;
  }, [model]);
  const selectedVariations = useMemo(() => {
    if (!variation) {
      return {};
    }

    return parseVariationFromModelCode(variation);
  }, [variation]);

  function select(code, value) {
    const selectedUnfolding = buildModelCodeFromVariations({
      ...selectedVariations,
      [code]: value,
    });

    trackSelectModelVariant(model, selectedUnfolding);

    updateSearch({ 'variacao': selectedUnfolding });
  }

  useEffect(() => {
    if (!model || variation) {
      return;
    }

    updateSearch({ 'variacao': model.default_unfolding.code }, { replace: true });
  }, [variation, model]);

  if (variations.length === 0) {
    return null;
  }

  return (
    <div className="selected-model-variations">
      <VerticalScroll>
        <div className="variations">
          <div className="variations-inner">
            {variations.map((variation) => (
              <SelectedModelVariation
                key={variation._id}
                variation={variation}
                afterSelect={select}
                selectedValue={selectedVariations[variation.code]}
              />
            ))}
          </div>
        </div>
      </VerticalScroll>
    </div>
  );
}
SelectedModelVariations.propTypes = {
  model: PropTypes.object,
  variation: PropTypes.string,
};

export function SelectedModelVariation({ variation, selectedValue, afterSelect }) {
  const [sortedVariationValues, setSortedVariationValues] = useState([]);

  function isSelected(value) {
    return selectedValue === value.code;
  }
  function selectValue(e, value) {
    e.stopPropagation();
    e.preventDefault();

    afterSelect(variation.code, value.code);
  }

  useEffect(() => {
    if (variation && variation.values && variation.values.length > 0) {
      setSortedVariationValues(sortVariationValues(variation));
    }
  }, [variation.values]);

  if (!variation.values || variation.values.length === 0) {
    return null;
  }

  return (
    <div className="selected-model-variation">
      <div className="title">{variation.name}</div>
      <ul className="nav nav-pills flex-column">
        {sortedVariationValues.map((value) => (
          <li key={value.code} className={`nav-item ${isSelected(value) ? 'active' : ''}`}>
            <a href="" className="nav-link" onClick={(e) => selectValue(e, value)}>
              {getVariationLabel(variation, value)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

SelectedModelVariation.propTypes = {
  variation: PropTypes.object,
  selectedValue: PropTypes.string,
  afterSelect: PropTypes.func,
};
