import { getValue } from './helpers/getters-setters';

export const getUnfoldingsByCode = (model) => {
  const _unfoldingsByCode = {};

  if (!model || !model.unfoldings) {
    return _unfoldingsByCode;
  }

  model.unfoldings.forEach((unfolding, index) => {
    _unfoldingsByCode[unfolding.code] = index;
  });

  return _unfoldingsByCode;
};

export function getVariationIndexByCode(model, variation) {
  const unfoldingsByCode = getUnfoldingsByCode(model);

  if (!unfoldingsByCode) {
    return;
  }

  return unfoldingsByCode[variation];
}

export const getSVGNodeFromModel = (modelo, variacao) => {
  if (modelo.unfoldings && modelo.unfoldings[variacao]) {
    return modelo.unfoldings[variacao].nodes;
  }

  return getValue(modelo, 'default_unfolding.nodes');
};

export function isModelCompatibleWithFabric(model, fabricSubgroupId) {
  if (!fabricSubgroupId || !model) {
    return true;
  }

  return model.subgroup_ids.includes(fabricSubgroupId);
}

export function sortModelsBySubgroup(models) {
  if (!models) {
    return;
  }

  const _models = [...models];

  _models.sort((m1, m2) => {
    const s1 = m1.subgroup_ids && m1.subgroup_ids[0];
    const s2 = m2.subgroup_ids && m2.subgroup_ids[0];

    if (s1 === s2) {
      return m1.code < m2.code ? -1 : 1;
    }

    return s1 < s2 ? -1 : 1;
  });

  return _models;
}
