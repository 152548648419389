import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'react-bootstrap-utils';

import { addItemIntoShoppingCart } from 'shared/http/shopping-cart.http';
import { getVariationIndexByCode } from 'shared/models';
import { MoldOrder } from 'shared/MoldOrder';
import { useFabricToken } from 'shared/http/fabric-tokens.http';
import { useModel } from 'shared/http/models.http';
import { usePreferences } from 'shared/http/preferences.http';
import { useSearch } from 'shared/helpers/useSearch';
import { LoaderIcon } from 'shared/components/LoaderIcon';
import { useUserFabric } from 'shared/http/user-fabrics.http';

import { BuyMold } from '../catalog/buy-mold/BuyMold';

export function AddToShoppingCartDialog({ children }) {
  return (
    <Dialog size="lg" body={({ close }) => <BuyMoldDialog close={close} />}>
      {children}
    </Dialog>
  );
}

AddToShoppingCartDialog.propTypes = {
  children: PropTypes.node,
};

function BuyMoldDialog({ close }) {
  const {
    modelo: modelId,
    'amostra-tecido': fabricTokenId,
    variacao: variation,
    'tecido-usuario': userFabricId
  } = useSearch();
  const { model: selectedModel, isLoading: isLoadingModel } = useModel(modelId);
  const { fabricToken: selectedFabric, isLoading: isLoadingFabric } = useFabricToken(fabricTokenId);
  const { preferences, isLoading: isLoadingPreferences } = usePreferences();
  const { userFabric: selectedUserFabric, isLoading: isLoadingUserFabric } = useUserFabric(userFabricId);
  const isLoading = isLoadingModel || isLoadingFabric || isLoadingPreferences || isLoadingUserFabric;

  const [order, setOrder] = useState();

  function success(item) {
    return addItemIntoShoppingCart(item._order);
  }

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const selectedModelVariation = getVariationIndexByCode(selectedModel, variation);

    MoldOrder.init(selectedModel, selectedModelVariation, selectedFabric, preferences, selectedUserFabric).then((order) => {
      setOrder(order);
    });
  }, [isLoading]);

  if (isLoading || !order) {
    return <LoaderIcon />;
  }

  return <BuyMold order={order} initialStep={0} onSuccess={success} onClose={close} />;
}
BuyMoldDialog.propTypes = { close: PropTypes.func };
