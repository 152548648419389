import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useSearch } from 'shared/helpers/useSearch';
import { SVGModelo } from 'shared/components/SVGModelo';
import { getValue } from 'shared/helpers/getters-setters';
import { stringify } from 'shared/helpers/query-string';
import { useModelTypes } from 'shared/http/model-types.http';

import { LoaderIcon } from 'shared/components/LoaderIcon';

const Masonry = typeof window !== 'undefined' ? window.Masonry || require('masonry-layout') : null;

export function ModelTypes() {
  const { modelTypes, isLoading } = useModelTypes({}, true);
  const location = useLocation();
  const search = useSearch();

  useEffect(() => {
    let masonry;

    if (!modelTypes || modelTypes.length === 0) {
      return;
    }

    const timeout = setTimeout(() => {
      const grid = document.querySelector('.grid-model-types');

      if (!grid) {
        return;
      }

      masonry = new Masonry(grid, {
        itemSelector: '.model-type',
      });
    }, 200);

    return () => {
      if (masonry) {
        masonry.destroy();
      }

      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [modelTypes]);

  return (
    <div className="model-types">
      <div className="grid-model-types">
        {isLoading ? (
          <LoaderIcon />
        ) : (
          modelTypes.map((modelType) => (
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2 model-type" key={modelType._id}>
              <Link
                to={{ ...location, search: stringify({ ...search, 'tipo-modelo': modelType._id }) }}
                className="thumbnail"
              >
                <SVGModelo svg={getValue(modelType, 'representative.default_unfolding.nodes')} />
                <div className="caption">{modelType.name}</div>
              </Link>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
