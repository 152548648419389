import useSWR, { mutate } from 'swr';

import { gerarCodigoModelo } from '../codigo-modelo';
import { http, swrFetcher } from '../helpers/http';
import { trackAddToCart } from '../analytics';

const baseUrl = '/api/shopping-cart';

export function useShoppingCart() {
  const { data, error } = useSWR(baseUrl, swrFetcher);

  return {
    shoppingCart: data || {},
    isLoading: Boolean(!error && !data),
    isError: error,
    refresh: () => mutate(baseUrl),
  };
}

export function addItemIntoShoppingCart(newItem) {
  const orderItem = {
    ...newItem,
    code: gerarCodigoModelo(newItem.model, newItem.modelVariation),
    model: newItem.model._id || newItem.model,
    userFabric: newItem.userFabric?._id || newItem.userFabric,
  };

  return http.put(`${baseUrl}/items/`, orderItem).then((res) => {
    trackAddToCart(orderItem);

    return res;
  });
}

export function editShoppingCartItem(item) {
  const itemId = item._id;

  return http.post(`${baseUrl}/items/${itemId}`, item);
}

export function removeShoppingCartItem(item) {
  const itemId = item._id;

  return http.delete(`${baseUrl}/items/${itemId}`);
}
