export function TamanhoProvaIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.077 39.997" width="50px" height="36px">
      <defs />
      <g id="Group_2619" data-name="Group 2619" transform="translate(-5.592 -372.001)">
        <path
          id="Union_206"
          data-name="Union 206"
          className="cls-1"
          d="M-2531.722-5783l7.8,20.073-7.8,19.923ZM-2580-5743v-40h48.277v40Z"
          transform="translate(2585.592 6155.002)"
          fill="#12ccd6"
        />
        <text
          id="TAM._PROVA"
          data-name="TAM.PROVA"
          className="cls-2"
          transform="translate(32 388)"
          fill="#fff"
          fontSize="12px"
        >
          <tspan x="-14.334" y="0">
            TAM.
          </tspan>
          <tspan x="-20.616" y="15">
            PROVA
          </tspan>
        </text>
      </g>
    </svg>
  );
}
