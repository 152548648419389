export function SearchIcon() {
  return (
    <svg className="mc-icon" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="10 10 60 60">
      <path
        fill="#fff"
        d="M65.6,62L52,48.4c2.9-3.7,4.5-8.2,4.5-13c0-5.6-2.2-10.9-6.2-14.9c-4-4-9.3-6.2-14.9-6.2c-5.6,0-10.9,2.2-14.9,6.2 c-4,4-6.2,9.3-6.2,14.9s2.2,10.9,6.2,14.9c4,4,9.3,6.2,14.9,6.2c4.8,0,9.3-1.6,13-4.5L62,65.6L65.6,62z M24.1,46.7 c-3-3-4.7-7-4.7-11.3c0-4.3,1.7-8.3,4.7-11.3c3-3,7-4.7,11.3-4.7c4.3,0,8.3,1.7,11.3,4.7c3,3,4.7,7,4.7,11.3c0,4.3-1.7,8.3-4.7,11.3 c-3,3-7,4.7-11.3,4.7C31.1,51.4,27.1,49.7,24.1,46.7z"
      />
    </svg>
  );
}
