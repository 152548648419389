import { useMemo } from 'react';

import { getFabricSubgroup, useFabricGroup } from 'shared/http/fabric-groups.http';
import { useFabricToken } from 'shared/http/fabric-tokens.http';
import { useNavigate } from 'shared/helpers/useNavigate';
import { useSearch } from 'shared/helpers/useSearch';
import { useModelType } from 'shared/http/model-types.http';
import { resetVerticalScroll, VerticalScroll } from 'shared/components/scroll/VerticalScroll';

import { Breadcrumbs } from '../../ui/Breadcrumbs';

import { ModelTypes } from './ModelTypes';
import { ModelsByType } from './ModelsByType';
import { ModelTypesByFabricSubgroup } from './ModelTypesByFabricSubgroup';

export function ModelsCatalog() {
  const {
    'tipo-modelo': tipoModeloId,
    'grupo-tecido': grupoId,
    'subgrupo-tecido': subgrupoId,
    'amostra-tecido': amostraId,
  } = useSearch();
  const { updateSearch } = useNavigate();
  const { modelType: selectedModelType, isLoading: isLoadingModelType } = useModelType(tipoModeloId);
  const { fabricGroup: selectedFabricGroup } = useFabricGroup(grupoId);

  const selectedFabricSubgroup = useMemo(() => {
    if (!selectedFabricGroup || !subgrupoId) {
      return null;
    }

    return getFabricSubgroup(selectedFabricGroup, subgrupoId);
  }, [selectedFabricGroup, subgrupoId]);
  const { fabricToken: selectedFabricToken } = useFabricToken(amostraId);

  const steps = useMemo(() => {
    const _steps = [
      {
        title: 'Coleção',
        onClick() {
          updateSearch({
            'tipo-modelo': undefined,
            'grupo-tecido': undefined,
            'subgrupo-tecido': undefined,
            'amostra-tecido': undefined,
          });
          resetVerticalScroll();
        },
      },
    ];

    if (selectedFabricSubgroup && selectedFabricToken) {
      _steps.push({
        title: selectedFabricToken.name,
      });
    } else if (selectedModelType) {
      _steps.push({
        title: selectedModelType.name,
      });
    }

    return _steps;
  }, [selectedFabricSubgroup, selectedFabricToken, selectedModelType]);

  const actualStep = useMemo(() => {
    let _actualStep = '';

    if (selectedFabricSubgroup && selectedFabricToken) {
      _actualStep = selectedFabricToken.name;
    } else if (selectedModelType) {
      _actualStep = selectedModelType.name;
    }

    return _actualStep;
  }, [selectedFabricSubgroup, selectedFabricToken, selectedModelType]);

  return (
    <div className="models-catalog">
      <div className="header">
        <Breadcrumbs steps={steps} actualStep={actualStep} />
      </div>
      <div className="model-navigation">
        <VerticalScroll>
          {subgrupoId ? (
            <ModelTypesByFabricSubgroup fabricSubgroup={selectedFabricSubgroup} fabricToken={selectedFabricToken} />
          ) : tipoModeloId ? (
            <ModelsByType modelType={selectedModelType} isLoading={isLoadingModelType} />
          ) : (
            <ModelTypes />
          )}
        </VerticalScroll>
      </div>
    </div>
  );
}
