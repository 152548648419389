export function parse(queryString) {
  if (typeof queryString === 'object' || !queryString || queryString === '?') {
    return {};
  }

  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

  for (const pair of pairs) {
    const [attr, value] = pair.split('=');

    const decodedAttr = decodeURIComponent(attr);
    const decodedValue = decodeURIComponent(value || '') || (pair.includes('=') ? '' : null);

    if (query[decodedAttr]) {
      query[decodedAttr] = (Array.isArray(query[decodedAttr]) ? query[decodedAttr] : [query[decodedAttr]]).concat(
        decodedValue
      );
    } else {
      query[decodedAttr] = decodedValue;
    }
  }

  return query;
}

export function stringify(query = {}) {
  if (!query) {
    return '';
  }

  return Object.entries(query)
    .filter(([, value]) => typeof value !== 'undefined' && (!Array.isArray(value) || value.length > 0))
    .map(([attr, value]) => {
      if (value === null) {
        return attr;
      }

      if (Array.isArray(value)) {
        return value.map((v) => `${encodeURIComponent(attr)}=${encodeURIComponent(v)}`).join('&');
      }

      return `${encodeURIComponent(attr)}=${encodeURIComponent(value)}`;
    })
    .join('&');
}
