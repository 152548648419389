import { Link } from 'react-router-dom';

import { formatDate, formatMoney } from 'shared/helpers/formatters';
import { NoOrders } from 'shared/orders/NoOrders';
import { orderStatusMap } from 'shared/orders/orders';
import { useOrders } from 'shared/http/orders.http';
import { LoaderIcon } from 'shared/components/LoaderIcon';

export function OrdersPage() {
  const { orders, isLoading } = useOrders();

  return (
    <div className="my-orders">
      <header>
        <h3>Meus pedidos</h3>
      </header>
      <section>
        {isLoading ? (
          <LoaderIcon />
        ) : orders.length === 0 ? (
          <NoOrders />
        ) : (
          <div className="orders list-group">
            {orders.map((pedido) => (
              <Link key={pedido._id} to={`/meus-pedidos/${pedido._id}`} className="list-group-item item">
                <div className="d-flex justify-content-between">
                  <span>{formatDate(pedido.createdAt)}</span>

                  <span>{formatMoney(pedido.total)}</span>
                </div>
                {orderStatusMap[pedido.status]}
              </Link>
            ))}
          </div>
        )}
      </section>
    </div>
  );
}
