import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useModelTypes } from 'shared/http/model-types.http';
import { LoaderIcon } from 'shared/components/LoaderIcon';
import { useInviteIfNotLogged } from 'shared/auth/AccountInvite';

import { ModelsByFabricSubgroup } from './ModelsByFabricSubgroup';

export function ModelTypesByFabricSubgroup({ fabricSubgroup, fabricToken }) {
  const { modelTypes, isLoading } = useModelTypes(
    {
      fabricSubgroupId: fabricSubgroup && fabricSubgroup._id,
    },
    fabricSubgroup && fabricSubgroup._id
  );
  const { inviteIfNotLogged, InviteIfNotLoggedPortal } = useInviteIfNotLogged({ mobile: true });
  const [modelsByTypeFound, setModelsByTypeFound] = useState(0);
  const [modelsByTypeLoaded, setModelsByTypeLoaded] = useState(0);
  const isEmpty = useMemo(() => !isLoading && modelsByTypeLoaded >= modelTypes.length && modelsByTypeFound === 0, []);

  useEffect(() => {
    setModelsByTypeFound(0);
    setModelsByTypeLoaded(0);
  }, [fabricSubgroup]);

  function finishedLoading(modelsFound) {
    setModelsByTypeFound((modelsByTypeFound) => modelsByTypeFound + modelsFound);
    setModelsByTypeLoaded((modelsByTypeLoaded) => modelsByTypeLoaded + 1);
  }

  return (
    <div className="model-types-by-fabric-subgroup">
      <InviteIfNotLoggedPortal />
      {isLoading && <LoaderIcon />}

      {modelTypes.map((modelType) => (
        <ModelsByFabricSubgroup
          key={modelType._id}
          modelType={modelType}
          fabricSubgroup={fabricSubgroup}
          fabricToken={fabricToken}
          onFinishLoading={finishedLoading}
          inviteIfNotLogged={inviteIfNotLogged}
        />
      ))}
      {isEmpty && <div className="empty">Nenhum modelo foi encontrado para o tecido selecionado</div>}
    </div>
  );
}
ModelTypesByFabricSubgroup.propTypes = {
  fabricSubgroup: PropTypes.object,
  fabricToken: PropTypes.object,
};
