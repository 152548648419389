import { Link } from 'react-router-dom';

import { removeShoppingCartItem, useShoppingCart } from 'shared/http/shopping-cart.http';
import { LoaderIcon } from 'shared/components/LoaderIcon';
import { DigitalSalesBanner } from 'shared/components/DigitalSalesBanner';

import { ShoppingCartHeader } from './ShoppingCartHeader';
import { ShoppingCartItem } from './ShoppingCartItem';

export function ShoppingCartPage() {
  const { shoppingCart: order, isLoading, refresh } = useShoppingCart();

  function removeItem(item) {
    removeShoppingCartItem(item).then(refresh);
  }

  if (isLoading) {
    return <LoaderIcon />;
  }

  return (
    <div className="shopping-cart-page">
      <DigitalSalesBanner />
      <ShoppingCartHeader order={order} />
      <section>
        <div className="orders">
          {order.items.length === 0 && (
            <div>
              <h4>
                <em>Opa! Seu carrinho ainda está vazio...</em>
              </h4>
              <Link to="/" className="btn btn-lg btn-custom">
                Navegar pelo catálogo
              </Link>
            </div>
          )}
          {order.items.map((item, index) => (
            <div className="order" key={item._id + index}>
              <ShoppingCartItem item={item} onRemove={removeItem} />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
