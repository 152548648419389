export const ZoomOutIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 25 25">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M24.191,24.191c-0.396,0.395-1.032,0.398-1.424,0.008l-7.672-7.658 c-1.536,1.227-3.479,1.965-5.597,1.965c-4.964,0-8.988-4.029-8.988-9s4.024-9,8.988-9c4.964,0,8.989,4.029,8.989,9 c0,2.12-0.737,4.066-1.963,5.604l7.675,7.661C24.59,23.161,24.586,23.797,24.191,24.191z M9.499,2.506c-3.861,0-6.99,3.134-6.99,7 s3.129,7,6.99,7c3.861,0,6.991-3.134,6.991-7S13.36,2.506,9.499,2.506z M12.495,10.506H6.502c-0.552,0-0.999-0.448-0.999-1 c0-0.553,0.447-1,0.999-1h5.992c0.551,0,0.999,0.447,0.999,1C13.493,10.058,13.046,10.506,12.495,10.506z"
    />
  </svg>
);
