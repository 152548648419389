export const MeusTecidosIcon = () => (
  <svg
    className="mc-icon-svg"
    xmlns="http://www.w3.org/2000/svg"
    width="25px"
    height="25px"
    viewBox="0 0 22.489 18.477"
  >
    <defs>
      <clipPath id="clip-path">
        <path id="Path_2681" data-name="Path 2681" className="cls-1" d="M0-18.477H22.489V0H0Z" />
      </clipPath>
    </defs>
    <g id="Group_2650" data-name="Group 2650" transform="translate(0 18.477)">
      <path id="Path_2675" data-name="Path 2675" className="cls-2" d="M0,0H19.489V-1.247H0Z" />
      <path id="Path_2676" data-name="Path 2676" className="cls-2" d="M0-.623H1.247V-13.478H0Z" />
      <g id="Group_2649" data-name="Group 2649" className="cls-3">
        <g id="Group_2646" data-name="Group 2646" transform="translate(17.924 -17.17)">
          <path
            id="Path_2677"
            data-name="Path 2677"
            className="cls-2"
            d="M0,0C-.757-2.054-4.566-1.749-4.566,1.357-4.566,2.9-3.4,4.965,0,7.063,3.4,4.965,4.565,2.9,4.565,1.357,4.565-1.731.761-2.066,0,0"
          />
        </g>
        <path
          id="Path_2678"
          data-name="Path 2678"
          className="cls-2"
          d="M14.975-6.651H12.829V-9.136h2.146ZM12.149-9.816H10V-12.3h2.146Zm0,3.165H10V-9.136h2.146ZM9.324-9.816H7.178V-12.3H9.324Zm0,3.165H7.178V-9.136H9.324Zm9.155-2.077a1.475,1.475,0,0,1-.555.122c-.042,0-.082-.014-.124-.017v1.972H15.654V-9.137h1.008c-.349-.227-.681-.454-.982-.679h-.026v-.019c-.242-.182-.466-.361-.679-.541v.56H12.829V-12.3h.3a6.892,6.892,0,0,1-.985-1.841v1.162H10v-1.2h-.68v1.2H7.179v-1.2H6.5v1.2H4.872v.679H6.5v2.486H4.872v.679H6.5v2.486H4.872v.679H6.5v1.2h.68v-1.2H9.324v1.2H10v-1.2h2.145v1.2h.68v-1.2h2.146v1.2h.679v-1.2H17.8v1.2h.679v-1.2h1.627v-.679H18.479Z"
        />
        <g id="Group_2647" data-name="Group 2647" transform="translate(19.185 -9.137)">
          <path id="Path_2679" data-name="Path 2679" className="cls-2" d="M0,0H.921V-.636C.636-.425.326-.213,0,0" />
        </g>
        <g id="Group_2648" data-name="Group 2648" transform="translate(21.239 -10.697)">
          <path
            id="Path_2680"
            data-name="Path 2680"
            className="cls-2"
            d="M0,0V7.049H-17.5V-4.607h8.148a5.053,5.053,0,0,1-.03-.508A4.669,4.669,0,0,1-9.32-5.78h-9.43v14h20V-1.315A11.118,11.118,0,0,1,0,0"
          />
        </g>
      </g>
    </g>
  </svg>
);
