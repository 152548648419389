import { useMemo } from 'react';
import useSWR from 'swr';

import { getValue } from '../helpers/getters-setters';
import { swrFetcher } from '../helpers/http';

const FABRIC_GROUP_TYPES = ['Tecidos', 'Malhas'];

export const getFabricGroupTypes = () => FABRIC_GROUP_TYPES;

const parseFabricGroupName = (fabricGroup) => {
  if (!fabricGroup) {
    return {};
  }

  const [type, name] = fabricGroup.name.split(' > ');

  return { type, name };
};

export const getTypeFromFabricGroup = (fabricGroup) => {
  const { type } = parseFabricGroupName(fabricGroup);

  return type;
};

export const getFabricGroupNameWithoutType = (fabricGroup) => {
  const { name } = parseFabricGroupName(fabricGroup);

  return name;
};

const baseUrl = '/api/fabric-groups';

export function useFabricGroups() {
  const { data, error } = useSWR(baseUrl, swrFetcher);

  return {
    fabricGroups: data || [],
    fabricGroupTypes: FABRIC_GROUP_TYPES,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useFabricGroupsClusteredByTypes() {
  const { fabricGroups, fabricGroupTypes, isLoading, isError } = useFabricGroups();
  const fabricGroupsByType = useMemo(
    () => getFabricGroupClustersByType(fabricGroupTypes, fabricGroups) || {},
    [fabricGroupTypes, fabricGroups]
  );

  return {
    fabricGroupsByType,
    fabricGroupTypes,
    isLoading,
    isError,
  };
}

export function useFabricGroup(id) {
  const { fabricGroups, isLoading, isError } = useFabricGroups();

  return {
    fabricGroup: fabricGroups.find((g) => g._id === id),
    isLoading,
    isError,
  };
}

export const disableFilteredGroups = (fabricGroups = {}, filter) => {
  for (const fabricGroupsByType of Object.values(fabricGroups)) {
    fabricGroupsByType.forEach((group) => {
      group.disabled = filter && !filter.includes(group._id);
    });
  }
};

export const getFabricGroupClustersByType = (types, fabricGroups) => {
  const fabricGroupsByType = {};

  types.forEach((type) => {
    fabricGroupsByType[type] = fabricGroups.filter((g) => g.name.includes(type));
  });

  return fabricGroupsByType;
};

export const getFabricSubgroups = (fabricGroup, filter) => {
  if (!fabricGroup) {
    return [];
  }

  const { subgroups } = fabricGroup;

  subgroups.forEach((subgroup) => {
    subgroup.disabled = filter && !filter.includes(subgroup._id);
  });

  return subgroups;
};

export const getFabricSubgroup = (fabricGroup, id) => {
  if (!fabricGroup) {
    return;
  }

  return fabricGroup.subgroups.find((s) => s._id === id);
};

export const fabricHasRepresentative = (fabric) => getValue(fabric, 'images.thumbnail.url') && !fabric.disabled;

export const filterFabricsWithRepresentative = (fabrics) => fabrics.filter(fabricHasRepresentative);

export function getFabricsFromFabricGroups(fabricGroups) {
  const _fabrics = [];

  fabricGroups.forEach((group) => {
    group.subgroups.forEach((subgroup) => {
      const fabricsWithRepresentative = filterFabricsWithRepresentative(subgroup.fabrics);

      fabricsWithRepresentative.forEach((fabric) => {
        fabric.parents = {
          group,
          subgroup,
        };
        _fabrics.push(fabric);
      });
    });
  });

  return _fabrics;
}
