export const CloseRawIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 110 110">
    <path
      fill="none"
      stroke="#231F20"
      strokeMiterlimit="10"
      d="M1176.5-218.5c0,0,13.75-16.5,15.5-19s10.75-12.25,0-23 s-21.75-1-28,7.25c-3.444,4.547-129.993,155.411-202.333,238.583C902.744,53.081,895,56.667,895,56.667 s-195.333-182.333-199.333-187s-43-54.334-48-60.667s-16.001-9.333-21.667-3.667s-10.666,11.667-3.666,21s42.666,56,42.666,56 s-10.666-3-15.666,7s-0.334,17,2.333,20s207.667,183,207.667,183l-4.668,4c0,0-195.667-147.667-202.666-152.334 c-7-4.667-11.667-7.333-17-9c-5.334-1.667-74.667-34.667-76.667-35s-12.68-5.11-19.333,9.333c-2.5,5.426,1,13.667,5,17 s56,39,64,44.667s219.666,153.334,219.666,153.334S673,282.333,666.333,286.667s-56,41.334-56,41.334s-12.999,9-3.667,22.333 c9.334,13.333,22,3.333,22,3.333l105.667-80.999l133.343-120.334c0,0,143.325,98.666,151.991,105.333s120,90,120,90 s7.999,7.199,15.666,9.6s28,8.734,28,8.734s14.334,3.333,18.667-3.667s4-9,4-9l20.333,17c0,0,5.667,4.666,11.667,4.666 s15.5-9.333,15.5-16.666s-15.167-18.333-15.167-18.333S968.334,124.666,960.667,117S932,89.667,932,89.667s29.667-29,48.667-52.667 s204.667-231.333,206.667-233.667s3.666-10.667-0.667-15.333S1181.333-216.667,1176.5-218.5z"
    />
    <path
      fill="#5D5D5D"
      d="M97.031,14.061c0,0,2.06-2.471,2.321-2.845c0.263-0.375,1.61-1.834,0-3.444 c-1.609-1.61-3.257-0.15-4.192,1.085C94.645,9.538,75.693,32.13,64.859,44.585c-8.824,10.146-9.984,10.683-9.984,10.683 S25.624,27.963,25.025,27.264c-0.599-0.699-6.439-8.137-7.188-9.085c-0.749-0.948-2.396-1.397-3.245-0.549s-1.597,1.747-0.549,3.145 c1.048,1.398,6.389,8.386,6.389,8.386s-1.597-0.449-2.346,1.048c-0.749,1.498-0.05,2.546,0.35,2.995s31.099,27.405,31.099,27.405 l-0.699,0.6c0,0-29.302-22.114-30.35-22.813c-1.048-0.699-1.747-1.098-2.546-1.348S4.758,31.856,4.458,31.807 c-0.299-0.05-1.898-0.765-2.895,1.397c-0.374,0.812,0.15,2.047,0.749,2.546c0.599,0.499,8.386,5.84,9.584,6.689 c1.198,0.849,32.896,22.962,32.896,22.962S21.63,89.062,20.632,89.712s-8.386,6.189-8.386,6.189s-1.947,1.348-0.549,3.345 c1.398,1.997,3.295,0.499,3.295,0.499l15.824-12.13l19.969-18.021c0,0,21.463,14.775,22.761,15.774 c1.298,0.998,17.971,13.478,17.971,13.478s1.198,1.078,2.347,1.438c1.147,0.359,4.192,1.309,4.192,1.309s2.146,0.499,2.796-0.55 c0.648-1.048,0.599-1.348,0.599-1.348l3.045,2.546c0,0,0.849,0.698,1.748,0.698c0.898,0,2.32-1.397,2.32-2.495 c0-1.099-2.271-2.746-2.271-2.746S65.857,65.451,64.71,64.304c-1.148-1.148-4.293-4.094-4.293-4.094s4.442-4.343,7.288-7.887 C70.55,48.779,98.354,17.68,98.654,17.33c0.3-0.349,0.549-1.597-0.1-2.296C97.905,14.335,97.756,14.335,97.031,14.061z"
    />
  </svg>
);
