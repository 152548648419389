export const EnviarMeuTecidoIcon = () => (
  <svg
    className="mc-icon-svg"
    xmlns="http://www.w3.org/2000/svg"
    width="25px"
    height="25px"
    viewBox="0 0 22.489 18.478"
  >
    <defs>
      <clipPath id="clip-path">
        <path id="Path_2688" data-name="Path 2688" className="cls-1" d="M0-18.478H22.489V0H0Z" />
      </clipPath>
    </defs>
    <g id="Group_2655" data-name="Group 2655" transform="translate(0 18.478)">
      <path id="Path_2682" data-name="Path 2682" className="cls-2" d="M0,0H19.489V-1.247H0Z" />
      <path id="Path_2683" data-name="Path 2683" className="cls-2" d="M0-.623H1.247V-13.478H0Z" />
      <g id="Group_2654" data-name="Group 2654" className="cls-3">
        <g id="Group_2651" data-name="Group 2651" transform="translate(12.489 -10.144)">
          <path
            id="Path_2684"
            data-name="Path 2684"
            className="cls-2"
            d="M0,0A8.467,8.467,0,0,1,6.111-6.482V-8.334L10-4.646,6.111-.926V-2.777A8.722,8.722,0,0,0,0,0"
          />
        </g>
        <g id="Group_2652" data-name="Group 2652" transform="translate(12.635 -14.18)">
          <path id="Path_2685" data-name="Path 2685" className="cls-2" d="M0,0H-.485V.769C-.332.5-.169.242,0,0" />
        </g>
        <path
          id="Path_2686"
          data-name="Path 2686"
          className="cls-2"
          d="M17.8-6.651H15.654V-9.136H17.8ZM9.324-9.816H7.178V-12.3H9.324Zm0,3.165H7.178V-9.136H9.324ZM18.6-9.569a1.476,1.476,0,0,1-.589-.121,1.467,1.467,0,0,1-.212-.13v0H15.654v-.839a6.983,6.983,0,0,0-.679.381v.458h-.632a7.125,7.125,0,0,0-.74.679h1.372v2.486H12.829V-8.687a1.442,1.442,0,0,1-.341.043,1.455,1.455,0,0,1-.339-.041v2.034H10V-9.137h1.378a1.473,1.473,0,0,1-.338-.679H10V-12.3h1.609c.1-.235.2-.462.311-.679H10v-1.2h-.68v1.2H7.179v-1.2H6.5v1.2H4.872v.679H6.5v2.486H4.872v.679H6.5v2.486H4.872v.679H6.5v1.2h.68v-1.2H9.324v1.2H10v-1.2h2.145v1.2h.68v-1.2h2.146v1.2h.679v-1.2H17.8v1.2h.679v-1.2h1.627v-.679H18.479V-9.137h1.627v-.679h-.732a1.485,1.485,0,0,1-.773.247"
        />
        <g id="Group_2653" data-name="Group 2653" transform="translate(21.239 -11.518)">
          <path
            id="Path_2687"
            data-name="Path 2687"
            className="cls-2"
            d="M0,0V7.869H-17.5V-3.787h9.812A9.682,9.682,0,0,1-6.357-4.96H-18.75v14h20V-1.2Z"
          />
        </g>
      </g>
    </g>
  </svg>
);
