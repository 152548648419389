import { Route, Switch } from 'react-router';
import { ToastsContainer } from 'react-bootstrap-utils';

import { NotFoundPage } from 'shared/components/NotFoundPage';

import { AuthLayout, CartPaymentLayout, FullLayout, PrintLandscapeLayout } from './layouts/layouts';
import { CartPaymentPage } from './payment/CartPaymentPage';
import { Catalog } from './catalog/CatalogPage';
import { EditAddressPage } from './address/EditAddressPage';
import { LoginPage } from './auth/LoginPage';
import { OrderPage } from './orders/OrderPage';
import { OrdersPage } from './orders/OrdersPage';
import { PrintModelSheetPage } from './print/PrintModelSheetPage';
import { RecoverPage } from './auth/RecoverPage';
import { ShoppingCartPage } from './shopping-cart/ShoppingCartPage';
import { UserFabricsPage } from './catalog/user-fabrics/UserFabricsPage';

export const DesktopApp = () => (
  <ToastsContainer>
    <Switch>
      <Route path="/carrinho-compras/pagamento" component={CartPaymentLayout(CartPaymentPage)} />
      <Route path="/carrinho-compras" component={FullLayout(ShoppingCartPage)} />
      <Route path="/meus-pedidos/:id" component={FullLayout(OrderPage)} />
      <Route path="/meus-pedidos" component={FullLayout(OrdersPage)} />
      <Route path="/meus-tecidos" component={FullLayout(UserFabricsPage)} />
      <Route path="/login" component={AuthLayout(LoginPage)} />
      <Route path="/recuperar-senha" component={AuthLayout(RecoverPage)} />
      <Route path="/print-sheet" component={PrintLandscapeLayout(PrintModelSheetPage)} />
      <Route path="/enderecos/novo" component={CartPaymentLayout(EditAddressPage)} />
      <Route path="/enderecos/:enderecoId" component={CartPaymentLayout(EditAddressPage)} />
      <Route path="/" component={FullLayout(Catalog)} />
      <Route component={NotFoundPage} />
    </Switch>
  </ToastsContainer>
);
