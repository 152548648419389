export const CloseSlimIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
    <polygon
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#5D5E5E"
      points="15.994,1.454 14.54,0 7.997,6.543 1.454,0 0,1.454 6.543,7.997 0,14.54 1.454,15.994 7.997,9.451 14.54,15.994 15.994,14.54 9.451,7.997 "
    />
  </svg>
);
