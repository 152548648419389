export const CircledArrowRightIcon = () => (
  <svg
    className="mc-icon-svg"
    xmlns="http://www.w3.org/2000/svg"
    width="31px"
    height="30.092px"
    viewBox="0 0 30.179 30.092"
  >
    <g>
      <path
        fill="#5D5E5E"
        d="M10.081,15.775h8.817l-4.327,4.313c-0.286,0.285-0.286,0.747,0,1.032c0.286,0.285,0.749,0.285,1.035,0
				l6.092-6.075l-6.092-6.075c-0.143-0.143-0.33-0.214-0.517-0.214c-0.188,0-0.375,0.071-0.518,0.214
				c-0.286,0.285-0.286,0.747,0,1.031l4.327,4.314h-8.817c-0.404,0-0.731,0.326-0.731,0.729C9.35,15.449,9.677,15.775,10.081,15.775z"
      />
      <path
        fill="#5D5E5E"
        d="M15.089,30.092c8.32,0,15.09-6.75,15.09-15.046S23.409,0,15.089,0S0,6.749,0,15.046
				S6.769,30.092,15.089,30.092z M15.089,1.502c7.489,0,13.583,6.076,13.583,13.544S22.578,28.59,15.089,28.59
				c-7.49,0-13.583-6.076-13.583-13.544S7.599,1.502,15.089,1.502z"
      />
    </g>
  </svg>
);
