import { useModel } from 'shared/http/models.http';
import { useFabricToken } from 'shared/http/fabric-tokens.http';
import { getVariationIndexByCode } from 'shared/models';
import { useUserFabric } from 'shared/http/user-fabrics.http';
import { useSearch } from 'shared/helpers/useSearch';

import { ShowModel } from './ShowModel';
import { MoldToolbar } from './MoldToolbar';
import { ActionsToolbar } from './ActionsToolbar';

export function Catwalk() {
  const {
    'modelo': modelId,
    'amostra-tecido': fabricTokenId,
    'variacao': selectedVariation,
    'tecido-usuario': userFabricId,
  } = useSearch();
  const { model: selectedModel, isLoading: isLoadingModel } = useModel(modelId);
  const { fabricToken: selectedFabric, isLoading: isLoadingFabricToken } = useFabricToken(fabricTokenId);
  const { userFabric: selectedUserFabric, isLoading: isLoadingUserFabric } = useUserFabric(userFabricId);
  const variationIndex = getVariationIndexByCode(selectedModel, selectedVariation);

  return (
    <div className="catwalk">
      <ActionsToolbar modelId={modelId} fabricTokenId={fabricTokenId} variationIndex={variationIndex} />
      <MoldToolbar
        model={selectedModel}
        fabric={selectedFabric}
        variation={selectedVariation}
        userFabric={selectedUserFabric}
        isLoading={isLoadingModel || isLoadingFabricToken || isLoadingUserFabric}
      />
      <div className="full-height catwalk-area">
        <ShowModel
          model={selectedModel}
          fabric={selectedFabric}
          userFabric={selectedUserFabric}
          variationIndex={variationIndex}
        />
      </div>
    </div>
  );
}
