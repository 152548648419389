import PropTypes from 'prop-types';
import { FormInput } from 'react-bootstrap-utils';

export function FormPhoneNumber({ name, required }) {
  return (
    <FormInput
      name={name}
      type="tel"
      placeholder="Telefone *"
      pattern="(\(?[1-9][0-9]\)?)\s?([9]{1})?([0-9]{4})-?([0-9]{4})"
      title="Número de telefone precisa ser no formato (99) 9999-9999"
      required={required}
    />
  );
}

FormPhoneNumber.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};
