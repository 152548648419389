import { http } from '../helpers/http';

export function startPagseguroPayment(order) {
  return http.post(`/api/orders/${order._id}/payment/pagseguro`, order);
}

export function fetchPrivacyPolicy() {
  return http.get('/api/partials/privacy-policy');
}

export function fetchTermsAndConditions() {
  return http.get('/api/partials/terms-and-conditions');
}
