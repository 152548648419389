import PropTypes from 'prop-types';
import { useDialog } from 'react-bootstrap-utils';
import { useLocation } from 'react-router';

import { useSession } from 'shared/http/auth.http';
import { useNavigate } from 'shared/helpers/useNavigate';

export function useInviteIfNotLogged({ mobile = false } = {}) {
  const { isAuthenticated } = useSession();
  const { DialogPortal, showDialog } = useDialog({
    // eslint-disable-next-line react/prop-types
    body({ close }) {
      if (mobile) {
        return <CreateAccountInviteMobile close={close} />;
      }

      return <CreateAccountInviteDesktop close={close} />;
    },
  });

  return {
    inviteIfNotLogged() {
      if (isAuthenticated) {
        return false;
      }

      showDialog();

      return true;
    },
    InviteIfNotLoggedPortal: DialogPortal,
  };
}

export function CreateAccountInviteDesktop({ close }) {
  const { pathname, search } = useLocation();
  const { navigate } = useNavigate();

  function createAccount() {
    close();
    navigate('/login', {
      redirectTo: `${pathname}${search}`,
    });
  }

  return (
    <div className="account-invite-desktop">
      <div className="left-panel">
        <img id="curve" src="/assets/bg/curve.svg" alt="curva" />
        <img id="logo-modacad" src="/assets/logo-horizontal.svg" alt="logo modacad" />
      </div>
      <div className="container-convida">
        <img id="logo-modacad-2" src="/assets/icone-modacad.svg" alt="icone modacad" />
        <p className="esta-gostando">Está gostando do nosso app?</p>
        <p className="crie-conta">Crie sua conta para experimentar muito mais!</p>
        <p className="conta-ilimitada">Sua conta é gratuita e ilimitada</p>
        <button type="button" onClick={createAccount} className="button-criar">
          ENTRAR
        </button>
      </div>
    </div>
  );
}

CreateAccountInviteDesktop.propTypes = {
  close: PropTypes.func,
};

export function CreateAccountInviteMobile({ close }) {
  const { navigate } = useNavigate();
  const { pathname, search } = useLocation();

  function createAccount() {
    close();
    navigate('/login', {
      redirectTo: `${pathname}${search}`,
    });
  }

  return (
    <div className="account-invite-mobile">
      <div className="vertical-curve">
        <img src="/assets/logo-horizontal.svg" alt="logo modacad" />

        <p>
          Está gostando do nosso app?
          <br />
          Crie sua conta para <br /> experimentar muito mais!
        </p>
      </div>
      <img src="/assets/bg/topo2.svg" width="100%" alt="" />

      <div className="container-convida">
        <p>Sua conta é gratuita e ilimitada</p>

        <button type="button" className="btn btn-custom" onClick={createAccount}>
          ENTRAR
        </button>
      </div>
    </div>
  );
}

CreateAccountInviteMobile.propTypes = {
  close: PropTypes.func,
};
