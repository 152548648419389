import { useNavigate } from 'shared/helpers/useNavigate';

export function NotFoundPage() {
  const { navigateBack } = useNavigate();

  const goBack = (e) => {
    e.preventDefault();
    navigateBack();
  };

  return (
    <div className="container-fluid pagina-nao-encontrada">
      <div>
        <h3>Página não encontrada</h3>

        <a href="/">voltar a página inicial</a>
        <button type="button" className="" onClick={goBack}>
          voltar a página anterior
        </button>
      </div>
    </div>
  );
}
