import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'shared/components/Icon';

export function Breadcrumbs({ steps, actualStep }) {
  function isActive(step) {
    return step.title === actualStep;
  }

  return (
    <ol className="breadcrumb">
      {steps.map((step, index) => (
        <li key={index} className={isActive(step) ? 'active' : ''} onClick={step.onClick}>
          <BreadcrumbStep text={step.title} />
        </li>
      ))}
    </ol>
  );
}
Breadcrumbs.propTypes = {
  steps: PropTypes.array,
  actualStep: PropTypes.string,
};

function BreadcrumbStep({ text }) {
  const textContainerRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      const svg = textContainerRef.current;
      const svgViewBox = svg.viewBox;
      const g = svg.querySelector('g.middle');
      const dimensions = g.getBBox();

      if (svgViewBox.baseVal) {
        const boxWidth = Math.round(dimensions.width + 10);

        svgViewBox.baseVal.width = boxWidth;
        svg.style.width = boxWidth;
      }

      svg.style.fontSize = 0;
    }, 0);
  }, []);

  return (
    <div className="breadcrumb-step">
      <div className="mc-icon">
        <Icon icon="left-arrow-alt" />
      </div>
      <div>
        <svg ref={textContainerRef} className="text-container" viewBox="0 0 16 25" xmlns="http://www.w3.org/2000/svg">
          <g className="middle">
            <rect width="100%" height="25" className="background" />
            <rect width="100%" height="1" className="border" />
            <rect y="24" width="100%" height="1" className="border" />
            <text transform="matrix(1 0 0 1 5 18)">{text}</text>
          </g>
        </svg>
      </div>
      <div className="mc-icon">
        <Icon icon="right-arrow-alt" />
      </div>
    </div>
  );
}
BreadcrumbStep.propTypes = {
  text: PropTypes.string.isRequired,
};
