import { Sidebar } from 'shared/components/Sidebar';

import { Navbar } from '../ui/Navbar';

export function CartPaymentLayout(Component) {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <div className="payment-pages">
      <div className="curve curve-top">
        <img id="logo-modacad-compra" src="/assets/logo-horizontal.svg" alt="Logo Modacad" />
      </div>
      <img id="icon-modacad-cart" src="/assets/icone-modacad.svg" alt="Mini Logo Modacad" />
      <div id="div-main">
        <Component {...props} />
      </div>
    </div>
  );
}

export function PrintPortraitLayout(Component) {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <div className="print portrait">
      <Component {...props} />
    </div>
  );
}

export function PrintLandscapeLayout(Component) {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <div className="print landscape">
      <Component {...props} />
    </div>
  );
}

export function FullLayout(Component) {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <div className="full-layout">
      <Sidebar />

      <header>
        <Navbar />
      </header>

      <div className="main-container">
        <Component {...props} />
      </div>
    </div>
  );
}

export function AuthLayout(Component) {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <div className="auth-pages">
      <div className="curve curve-top">
        <img id="logo-modacad" src="/assets/logo-horizontal.svg" alt="Logo Modacad" />
      </div>
      <img id="icon-modacad" src="/assets/icone-modacad.svg" alt="Mini Logo Modacad" />
      <div id="div-main">
        <Component {...props} />
      </div>
    </div>
  );
}
