import PropTypes from 'prop-types';
import { Form, useFormControl } from 'react-bootstrap-utils';

import { Icon } from 'shared/components/Icon';
import { formatMoney } from 'shared/helpers/formatters';
import { FormCustomSwitch } from 'shared/components/FormCustomSwitch';

import { BuyMoldSelectedSize } from './BuyMoldSelectedSize';
import { PreviewSelectedModel } from './PreviewSelectedModel';

export function BuyMoldSizes({ order, update, onGoBack, onClose, onProceed, onBuy }) {
  const table = order.getTable();
  const orderTotal = order.getTotal();

  function updateTotal({ measures }) {
    order.updateMeasureTable(measures);

    order.updateTotal();
    update(order);
  }

  function submit() {
    const sizes = order.getSizes();

    update(order);

    onBuy(sizes).then(() => {
      onProceed();
    });
  }

  return (
    <div className="buy-mold-sizes">
      <div className="row">
        <div className="col-lg-4 hidden-sm app-buy-mold-preview">
          <PreviewSelectedModel order={order._order} />
        </div>
        <div className="col-lg-8 col-sm-12 app-buy-mold-content">
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onGoBack();
            }}
          >
            <span className="mc-icon-lg mc-icon-inverse">
              <Icon icon="circled-arrow-left" />
            </span>
          </a>
          <a
            href=""
            className="float-right"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClose();
            }}
          >
            <span className="mc-icon mc-icon-inverse">
              <Icon icon="close" />
            </span>
          </a>

          <Form
            initialValues={{ measures: table.measures }}
            onChange={updateTotal}
            onSubmit={submit}
            customActions={<></>}
          >
            <FormMeasuresTable name="measures" order={order} />
            <h3 className="text-right">
              <span>Total: {formatMoney(orderTotal)}</span>
              <button type="submit" className="btn btn-custom">
                <strong>CONFIRMAR</strong>
              </button>
            </h3>
          </Form>
        </div>
      </div>
    </div>
  );
}
BuyMoldSizes.propTypes = {
  order: PropTypes.object,
  update: PropTypes.func,
  onGoBack: PropTypes.func,
  onClose: PropTypes.func,
  onProceed: PropTypes.func,
  onBuy: PropTypes.func,
};

function FormMeasuresTable({ name, order }) {
  const { getValue } = useFormControl(name);
  const measures = getValue();

  return (
    <table className="table table-sm">
      <thead>
        <tr>
          <th />
          <th />
          <th />
          <th />
          <th className="text-center">Quadril</th>
          <th className="text-center">Busto</th>
          <th className="text-center">Cintura</th>
          <th className="text-center">Ombro</th>
        </tr>
      </thead>
      <tbody>
        {measures.map((measure, index) => (
          <tr key={index} className={measure.selected ? 'selected' : ''}>
            <td>{measure.referenceModel && <BuyMoldSelectedSize />}</td>
            <td className="text-right strong">
              {measure.name} ({measure.size})
            </td>
            <td className="col-xs-1">
              <FormCustomSwitch name={`${name}[${index}].selected`} />
            </td>
            <td className="text-right col-xs-3">
              {measure.selected && <span className="price">{formatMoney(order.getSizePrice())}</span>}
            </td>
            <td className="text-center col-xs-1">{measure.hip}</td>
            <td className="text-center col-xs-1">{measure.bust}</td>
            <td className="text-center col-xs-1">{measure.waist}</td>
            <td className="text-center col-xs-1">{measure.shoulder}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
FormMeasuresTable.propTypes = {
  name: PropTypes.string,
  order: PropTypes.object,
};
