export const NightIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 25 25">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M12.42,2.495c-0.507,0-1.001,0.05-1.488,0.123 c0.932,1.251,1.491,2.797,1.491,4.477c0,4.147-3.362,7.51-7.509,7.51c-0.817,0-1.602-0.135-2.339-0.376 c0.82,4.7,4.911,8.276,9.845,8.276c5.525,0,10.005-4.479,10.005-10.005C22.425,6.974,17.946,2.495,12.42,2.495z M12.339,20.499 c-0.815,0-4.185-0.436-6.832-4.051c0,0,4.678,0.001,7.246-4.016c1.939-3.034,1.71-5.664,1.595-7.726c0,0,1.817,0.453,3.464,1.957 c1.365,1.246,2.614,3.273,2.614,5.827C20.427,18.536,14.468,20.499,12.339,20.499z"
    />
  </svg>
);
