export const ImportFabricIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 25 25">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
        d="M24.5,6.5v-2h-4v-4h-2v4h-5v-4h-2v4h-5v-4h-2v4h-4v2h4v5h-4v2h4h2 h5v5v2v4h2v-4h5v4h2v-4h4v-2h-4v-5h4v-2h-4v-5H24.5z M6.5,11.5v-5h5v5H6.5z M18.5,18.5h-5v-5h5V18.5z M18.5,11.5h-5v-5h5V11.5z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
        d="M8.361,22.505H4.246l3.92-3.928c0.474-0.473,0.474-1.241,0-1.714 c-0.474-0.474-1.243-0.474-1.716,0l-3.953,3.96v-4.175c0-0.63-0.447-1.142-0.998-1.142c-0.552,0-0.999,0.512-0.999,1.142v6.71 c0,0.029,0.013,0.053,0.015,0.082C0.513,23.462,0.5,23.48,0.5,23.503c0,0.234,0.106,0.439,0.261,0.61 c0.019,0.024,0.035,0.049,0.055,0.071c0.022,0.021,0.047,0.036,0.071,0.055c0.17,0.154,0.376,0.261,0.611,0.261 c0.022,0,0.041-0.013,0.063-0.014C1.59,24.487,1.614,24.5,1.644,24.5h0.07c0.018,0,0.035,0,0.053,0h6.594 c0.631,0,1.143-0.446,1.143-0.997S8.992,22.505,8.361,22.505z"
      />
    </g>
  </svg>
);
