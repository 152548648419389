export const UploadIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 25 25">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M20.5,17.982V15.5h-0.111c1.3-1.271,2.111-3.039,2.111-5 c0-3.866-3.134-7-7-7c-3.523,0-6.41,2.612-6.899,6H6.5c-2.209,0-4,1.791-4,4c0,1.477,0.81,2.752,2,3.445v2.188 c-2.325-0.827-4-3.024-4-5.633c0-3.314,2.687-6,6-6c0.173,0,0.335,0.037,0.504,0.051C8.227,4.031,11.564,1.5,15.5,1.5 c4.971,0,9,4.029,9,9C24.5,13.62,22.911,16.368,20.5,17.982z M11.729,11.888c0.013-0.016,0.033-0.024,0.046-0.039 c0.077-0.091,0.161-0.162,0.256-0.217c0,0,0.001,0,0.001,0c0.118-0.067,0.241-0.104,0.372-0.111c0.006,0,0.011-0.004,0.017-0.005 c0.011-0.002,0.021-0.005,0.032-0.006c0.017-0.001,0.03-0.01,0.047-0.01c0.016,0,0.031,0.006,0.046,0.01 c0.011,0.001,0.021,0.004,0.032,0.006c0.01,0.002,0.02,0.005,0.03,0.006c0.089,0.006,0.175,0.018,0.258,0.052 c0.036,0.015,0.065,0.038,0.099,0.056c0.067,0.038,0.133,0.073,0.192,0.129c0.034,0.031,0.053,0.073,0.083,0.108l3.792,2.559 c0.455,0.307,0.61,0.986,0.348,1.519c-0.263,0.531-0.844,0.713-1.298,0.406L13.5,14.609V22.5c0,0.553-0.448,1-1,1 c-0.553,0-1-0.447-1-1v-7.891l-2.583,1.743c-0.455,0.307-1.036,0.125-1.298-0.406c-0.263-0.532-0.106-1.211,0.348-1.519 L11.729,11.888z"
    />
  </svg>
);
