import { useDialog } from 'react-bootstrap-utils';
import { usePreferences } from '../http/preferences.http';

export function useAnnouncement() {
  const { isLoading, preferences } = usePreferences();
  const { DialogPortal, showDialog } = useDialog({
    // eslint-disable-next-line react/prop-types
    body() {
      return (
        <div className="buy-mold p-5">
          <h1>Vendas suspensas temporariamente.</h1>
          <p className="mt-5">
            Estamos nos preparando para uma nova fase da modacad e por isso a venda de moldes por este canal está suspensa. Entre em contato conosco através das redes sociais ou email para mais informações.
          </p>
        </div>
      );
    },
  });

  return {
    showAnnouncement() {
      if (!isLoading && preferences?.sales_enabled) {
        return false;
      }

      showDialog();

      return true;
    },
    AnnouncementPortal: DialogPortal,
  };
}
