import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'perfect-scrollbar';
import _throttle from 'lodash/throttle';

export function resetVerticalScroll() {
  const container = document.querySelector('.vertical-scroll-inner');

  if (container) {
    container.scrollTop = 0;
  }
}

export function VerticalScroll({ children }) {
  const innerScrollElemRef = useRef(null);
  const upScrollIntervalRef = useRef(null);
  const psRef = useRef(null);
  const downScrollIntervalRef = useRef(null);
  const [isUpButtonVisible, setIsUpButtonVisible] = useState(false);
  const [isDownButtonVisible, setIsDownButtonVisible] = useState(false);

  function startUpScroll() {
    if (!innerScrollElemRef.current) {
      return;
    }

    const innerScrollElem = innerScrollElemRef.current;

    upScrollIntervalRef.current = setInterval(() => {
      if (innerScrollElem.scrollTop === innerScrollElem.clientTop) {
        return stopUpScroll();
      }

      upScroll();
    }, 5);
  }

  const stopUpScroll = () => {
    clearInterval(upScrollIntervalRef.current);
  };

  function upScroll() {
    if (!innerScrollElemRef.current) {
      return;
    }

    const innerScrollElem = innerScrollElemRef.current;

    innerScrollElem.scrollTop -= 1;
  }

  function downScroll() {
    if (!innerScrollElemRef.current) {
      return;
    }

    const innerScrollElem = innerScrollElemRef.current;

    innerScrollElem.scrollTop += 1;
  }

  function startDownScroll() {
    if (!innerScrollElemRef.current) {
      return;
    }

    const innerScrollElem = innerScrollElemRef.current;
    const maxScroll = innerScrollElem.scrollHeight - innerScrollElem.clientHeight;

    downScrollIntervalRef.current = setInterval(() => {
      if (innerScrollElem.scrollTop === maxScroll) {
        return stopDownScroll();
      }

      downScroll();
    }, 5);
  }

  function stopDownScroll() {
    clearInterval(downScrollIntervalRef.current);
  }

  const updateButtonsVisibilityRef = useRef(
    _throttle(() => {
      if (!innerScrollElemRef.current) {
        return;
      }

      const innerScrollElem = innerScrollElemRef.current;

      setIsUpButtonVisible(innerScrollElem.scrollTop > innerScrollElem.clientTop);
      setIsDownButtonVisible(innerScrollElem.scrollTop < innerScrollElem.scrollHeight - innerScrollElem.clientHeight);
    }, 250)
  );

  useEffect(() => {
    if (!innerScrollElemRef.current) {
      return;
    }

    const innerScrollElem = innerScrollElemRef.current;

    setTimeout(() => {
      psRef.current = new PerfectScrollbar(innerScrollElem, {
        suppressScrollX: true,
      });

      const updateButtonsVisibility = updateButtonsVisibilityRef.current;

      updateButtonsVisibility();

      innerScrollElem.addEventListener('ps-scroll-y', () => {
        updateButtonsVisibility();
      });
    }, 500);
  }, [innerScrollElemRef.current]);

  return (
    <div className="vertical-scroll">
      {isDownButtonVisible && <div className="bottom-shadow" ng-show="isDownButtonVisible()" />}
      {isUpButtonVisible && <div className="top-shadow" ng-show="isUpButtonVisible()" />}
      {isUpButtonVisible && (
        <button className="scroll-button up-scroll-button" onMouseDown={startUpScroll} onMouseUp={stopUpScroll}>
          <svg className="mc-icon" xmlns="http://www.w3.org/2000/svg" width="31px" height="10px" viewBox="0 0 31 10">
            <path
              fill="#fff"
              d="M15.5,0l15.008,9.368L29.493,10L15.5,1.266L1.506,10L0.492,9.367l13.994-8.735h0L15.5,0z"
            />
          </svg>
        </button>
      )}
      {isDownButtonVisible && (
        <button className="scroll-button down-scroll-button" onMouseDown={startDownScroll} onMouseUp={stopDownScroll}>
          <span className="sr-only">rolar abaixo</span>
          <svg className="mc-icon" xmlns="http://www.w3.org/2000/svg" width="31px" height="10px" viewBox="0 0 31 10">
            <path
              fill="#fff"
              d="M15.5,10L0.492,0.632l1.015-0.633L15.5,8.734l13.993-8.735l1.014,0.633L16.514,9.367h0L15.5,10z"
            />
          </svg>
        </button>
      )}
      <div className="vertical-scroll-inner" ref={innerScrollElemRef}>
        {children}
      </div>
    </div>
  );
}
VerticalScroll.propTypes = {
  children: PropTypes.node,
};
