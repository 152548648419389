export function HeartIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18.35" width="20px">
      <path
        id="heart-outline"
        className="cls-1"
        d="M12.1,18.55l-.1.1-.11-.1C7.14,14.24,4,11.39,4,8.5A3.418,3.418,0,0,1,7.5,5a3.926,3.926,0,0,1,3.57,2.36h1.86A3.926,3.926,0,0,1,16.5,5,3.418,3.418,0,0,1,20,8.5c0,2.89-3.14,5.74-7.9,10.05M16.5,3A6.014,6.014,0,0,0,12,5.08,6.014,6.014,0,0,0,7.5,3,5.44,5.44,0,0,0,2,8.5c0,3.77,3.4,6.86,8.55,11.53L12,21.35l1.45-1.32C18.6,15.36,22,12.27,22,8.5A5.44,5.44,0,0,0,16.5,3Z"
        transform="translate(-2 -3)"
        fill="#fff"
      />
    </svg>
  );
}
