import PropTypes from 'prop-types';

import { Icon } from 'shared/components/Icon';

export function HelpWithMeasurement({ back }) {
  function goBack(e) {
    e.stopPropagation();
    e.preventDefault();
    back();
  }

  return (
    <div className="help-with-measurement">
      <div className="app-buy-mold-content">
        <a href="" onClick={goBack} className="float-left">
          <Icon icon="circled-arrow-left" className="mc-icon-inverse mc-icon-lg" />
        </a>
        <h3 className="text-center">Como medir sua modelo de provas</h3>
        <h4 className="text-center">As medidas devem ser exatas. Nem apertadas, nem folgadas.</h4>
        <div className="row">
          <div className="col-md-4">
            <img src="/assets/right-measures.svg" alt="" />
          </div>
          <div className="col-md-4">
            <ul>
              <li>
                O busto deve ser medido na linha dos mamilos, permanecendo na mesma altura ao contornar a parte das
                costas
              </li>
              <li>
                O quadril deve ser medido na linha do ápice da nádega, permanecendo na mesma altura ao contornar a parte
                da frente.
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <p>
              Sabendo o tamanho exato do corpo é que as medidas para os moldes de modelos largos ou justos podem ser
              calculadas
            </p>
            <div className="row">
              <div className="col-md-12">
                <img src="/assets/wrong-measures-hip.svg" alt="" />
                <span>
                  <Icon icon="close-raw" class="mc-icon mc-icon-inverse" />
                  Apertada
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <img src="/assets/wrong-measures-bust.svg" alt="" />
                <span>
                  <Icon icon="close-raw" class="mc-icon mc-icon-inverse" />
                  Folgada
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
HelpWithMeasurement.propTypes = {
  back: PropTypes.func.isRequired,
};
