import { useMemo, useState } from 'react';

import { CheckUserEmailForm } from './CheckUserEmailForm';
import { RedirectUser } from './RedirectUser';
import { RegisterUserForm } from './RegisterUserForm';
import { RequestPasswordRecover } from './RequestPasswordRecover';
import { ValidateUserPasswordForm } from './ValidateUserPasswordForm';
import { VerifyUserEmailForm } from './VerifyUserEmailForm';

export const desktopAuthStates = {
  'email': CheckUserEmailForm,
  'password': ValidateUserPasswordForm,
  'register': RegisterUserForm,
  'verify': VerifyUserEmailForm,
  'redirect': RedirectUser,
  'recover': RequestPasswordRecover,
};

const authStateTransitions = {
  'email': {
    'proceed': ({ isRegistered }) => (isRegistered ? 'password' : 'register'),
    'back': () => false,
  },
  'password': {
    'proceed': ({ status, forgotPassword }) => {
      if (forgotPassword) {
        return 'recover';
      }

      return status === 'Pending' ? 'verify' : 'redirect';
    },
    'back': () => 'email',
  },
  'register': {
    'proceed': () => 'verify',
    'back': () => 'email',
  },
  'verify': {
    'proceed': () => 'redirect',
    'back': () => 'email',
  },
  'recover': {
    'proceed': () => false,
    'back': () => 'password',
  },
};

export function useAuthStates({ initialState = 'email', onGoBackSideEffect = null, onProceedSideEffect = null }) {
  const [actualState, setState] = useState(initialState);
  const [data, setData] = useState({});
  const Component = useMemo(() => desktopAuthStates[actualState], [actualState]);

  function onProceed(data) {
    const availableTransitions = authStateTransitions[actualState];

    if (!availableTransitions) {
      throw new Error(`State has no transtions: ${actualState}`);
    }

    const transition = availableTransitions['proceed'];
    const nextState = transition(data);

    if (onProceedSideEffect) {
      onProceedSideEffect(nextState);
    }

    if (!nextState) {
      throw new Error(`State has no next state: ${actualState}`);
    }

    setData((prevData) => ({
      ...prevData,
      ...data,
    }));

    setTimeout(() => {
      setState(nextState);
    });
  }

  function onGoBack() {
    const availableTransitions = authStateTransitions[actualState];

    if (!availableTransitions) {
      throw new Error(`State has no transtions: ${actualState}`);
    }

    const transition = availableTransitions['back'];
    const nextState = transition(data);

    if (onGoBackSideEffect) {
      onGoBackSideEffect(nextState);
    }

    if (!nextState) {
      throw new Error(`State has no next state: ${actualState}`);
    }

    setTimeout(() => {
      setState(nextState);
    });
  }

  return {
    actualState,
    onProceed,
    onGoBack,
    data,
    Component,
  };
}
