import { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';

import { useShoppingCart } from 'shared/http/shopping-cart.http';
import { formatDateTime, formatMoney } from './helpers/formatters';

export const CartPaymentContext = createContext();

export function CartPaymentProvider({ children, onEmptyCart }) {
  const { shoppingCart, isLoading } = useShoppingCart();
  const [stepCart, setStepCart] = useState(0);
  const [itens, setItens] = useState([]);
  const [cart, setCart] = useState([]);
  const [cupom, setCupom] = useState(null);
  const [cupomText, setCupomText] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [billingAddress, setBillingAddress] = useState([]);
  const [isAlterPedido, setIsAlterPedido] = useState(0);
  const [contractAccepted, setContractAccepted] = useState(false);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (!shoppingCart || isLoading) {
      return;
    }

    setItens(shoppingCart.items);
    setCart(shoppingCart);

    if (shoppingCart.items.length === 0) {
      onEmptyCart();
    }
  }, [shoppingCart]);

  function proximo() {
    if (selectedAddress !== '') {
      setStepCart(stepCart + 1);
    }
  }

  function backStep() {
    if (stepCart > 0) {
      setStepCart(stepCart - 1);
    }
  }

  function backToStart() {
    setStepCart(0);
  }

  function getWhatsappMessage() {
    if (!order || !billingAddress) {
      return '';
    }

    return `Olá, meu nome é ${billingAddress.name} e fiz um pedido em ${formatDateTime(order.createdAt)} no valor de ${formatMoney(order.total)} e gostaria de receber os dados de pagamento.`;
  }

  return (
    <CartPaymentContext.Provider
      value={{
        selectedAddress,
        stepCart,
        itens,
        cart,
        cupom,
        cupomText,
        billingAddress,
        isAlterPedido,
        contractAccepted,
        order,
        setContractAccepted,
        backStep,
        backToStart,
        setIsAlterPedido,
        setSelectedAddress,
        setCupomText,
        setStepCart,
        proximo,
        setItens,
        setCart,
        setCupom,
        setBillingAddress,
        setOrder,
        getWhatsappMessage
      }}
    >
      {children}
    </CartPaymentContext.Provider>
  );
}

CartPaymentProvider.propTypes = {
  children: PropTypes.node,
  onEmptyCart: PropTypes.func,
};
