export const RightArrowIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20">
    <g>
      <path
        fill="#fff"
        d="M8.899,5.195C8.63,4.931,8.201,4.939,7.943,5.214c-0.259,0.274-0.25,0.711,0.019,0.975L11.85,10 l-3.888,3.812c-0.269,0.264-0.278,0.7-0.019,0.975c0.133,0.141,0.31,0.211,0.488,0.211c0.167,0,0.337-0.063,0.468-0.192 l4.396-4.309c0.132-0.13,0.207-0.31,0.207-0.497s-0.075-0.367-0.207-0.497L8.899,5.195z"
      />
      <path
        fill="#fff"
        d="M10,0C4.486,0,0,4.486,0,10c0,5.514,4.486,10,10,10c5.514,0,10-4.486,10-10C20,4.486,15.514,0,10,0z M10,18.498c-4.686,0-8.499-3.812-8.499-8.498c0-4.686,3.812-8.498,8.499-8.498c4.685,0,8.498,3.812,8.498,8.498 C18.498,14.686,14.686,18.498,10,18.498z"
      />
    </g>
  </svg>
);
