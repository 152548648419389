import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormInput } from 'react-bootstrap-utils';

import { formatMoney } from 'shared/helpers/formatters';
import { Icon } from 'shared/components/Icon';
import { LoaderIcon } from 'shared/components/LoaderIcon';

export function FormDesconto({ coupom, isLoading, isError, desconto, onChange }) {
  const [editMode, setEditMode] = useState(true);

  useEffect(() => {
    if (!coupom && !editMode) {
      setEditMode(true);
    }
  }, [isError]);

  function editCoupom(e) {
    e.preventDefault();
    setEditMode(true);
  }

  function submit({ codigoCupom }) {
    onChange(codigoCupom);
    setEditMode(false);
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-1">
        <LoaderIcon />
      </div>
    );
  }

  if (!editMode) {
    return (
      <>
        <span className="font-weight-light">Desconto:</span> {formatMoney(desconto)}
        <button type="button" className="ml-2 btn-transparent" onClick={editCoupom}>
          <span className="mc-icon">
            <Icon icon="edit" />
          </span>
        </button>
      </>
    );
  }

  return (
    <Form initialValues={{ codigoCupom: coupom?.codigo }} onSubmit={submit} customActions={<></>}>
      <div className="input-group mb-3">
        <FormInput type="text" name="codigoCupom" required minLength="3" placeholder="Cupom de desconto" />

        <div className="input-group-append">
          <button className="btn btn-success btn-cupom">Aplicar</button>
        </div>
      </div>

      {isError && <div className="alert alert-warning">Código Inválido</div>}
    </Form>
  );
}
FormDesconto.propTypes = {
  coupom: PropTypes.any,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  desconto: PropTypes.any,
  onChange: PropTypes.func,
};
