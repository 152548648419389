export function InformationIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20px">
      <path
        fill="#fff"
        id="information-outline"
        className="cls-1"
        d="M11,9h2V7H11m1,13a8,8,0,1,1,8-8,8.011,8.011,0,0,1-8,8M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2M11,17h2V11H11Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
}
