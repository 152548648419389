import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import { RenderModel } from '../catwalk/RenderModel';

export function PreviewSelectedModel({ order }) {
  const [isReady, setIsReady] = useState(false);
  const variationIndex = useMemo(() => parseInt(order.modelVariation, 10), [order.modelVariation]);

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    });
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <div className="preview-selected-model">
      <RenderModel model={order.model} fabric={order.fabricToken} userFabric={order.userFabric} variationIndex={variationIndex} />
    </div>
  );
}
PreviewSelectedModel.propTypes = {
  order: PropTypes.object,
};
