import { Link } from 'react-router-dom';

import { SidebarOpener } from 'shared/components/SidebarOpener';
import { Icon } from 'shared/components/Icon';

export function Navbar() {
  return (
    <nav className="navbar navbar-light fixed-top main-navbar">
      <div>
        <SidebarOpener />
      </div>

      <div className="navbar-expand">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link to="/" className="nav-link">
              <span className="sr-only">catálogo</span>
              <Icon icon="bust" />
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/carrinho-compras" className="nav-link">
              <span className="sr-only">carrinho de compras</span>
              <Icon icon="cart" />
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
