import useSWR from 'swr';

import { http, swrFetcher } from '../helpers/http';

const baseUrl = '/api/tecidos-usuario';

export function useUserFabrics() {
  const { data, error } = useSWR(baseUrl, swrFetcher);

  return {
    userFabrics: data || [],
    isLoading: Boolean(!error && !data),
    isError: error,
  };
}

export function useUserFabric(userFabricId) {
  const { data, error } = useSWR(userFabricId && `${baseUrl}/${userFabricId}`, swrFetcher);

  return {
    userFabric: data,
    isLoading: Boolean(userFabricId && !error && !data),
    isError: error,
  };
}

export const uploadUserFabric = (image) =>
  http.post(baseUrl, image, {
    isFormData: true,
  });

export const saveUserFabric = (tecidoUsuarioId, tecidoUsuario) =>
  http.post(`${baseUrl}/${tecidoUsuarioId}`, tecidoUsuario);

