import useSWR from 'swr';

import { http, swrFetcher } from '../helpers/http';

const baseUrl = '/api/orders';

export function useOrders() {
  const { data, error } = useSWR(baseUrl, swrFetcher);

  return {
    orders: data || [],
    isLoading: !error && !data,
    isError: error,
  };
}

export function useOrder(orderId) {
  const { data, error } = useSWR(orderId && `${baseUrl}/${orderId}`, swrFetcher);

  return {
    order: data,
    isLoading: Boolean(orderId && !error && !data),
    isError: error,
  };
}

export function createOrder({ billingAddress, codigoCupom }) {
  return http.post(baseUrl, {
    billingAddress,
    codigoCupom,
  });
}

export function setOrderTransaction(orderId, transactionId) {
  return http.post(`${baseUrl}/${orderId}/transaction`, {
    transaction: transactionId,
  });
}
