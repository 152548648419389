export const CircledArrowRightAltIcon = () => (
  <svg
    className="mc-icon-svg"
    xmlns="http://www.w3.org/2000/svg"
    width="25px"
    height="25px"
    viewBox="0 0 25 25"
    enableBackground="new 0 0 25 25"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#5D5E5E"
      d="M12.5,0C5.6,0,0,5.6,0,12.5C0,19.4,5.6,25,12.5,25S25,19.4,25,12.5
	C25,5.6,19.4,0,12.5,0z M10.8,18.5l-0.7-0.7l5.1-5.3l-5.1-5.3l0.7-0.7l5.8,6L10.8,18.5z"
    />
  </svg>
);
