import { useMemo } from 'react';
import { useParams } from 'react-router';
import { Form } from 'react-bootstrap-utils';
import { NavLink } from 'react-router-dom';

import { LoaderIcon } from 'shared/components/LoaderIcon';
import { Icon } from 'shared/components/Icon';
import { useNavigate } from 'shared/helpers/useNavigate';
import { saveBillingAddress, useBillingAddress } from 'shared/http/billing-address.http';
import {
  BillingAddressFormFields,
  billingAddressFormValidations,
  fromAdressApi,
  ShippingAddressFormField,
  toAdressApi,
} from 'shared/address/AddressFormFields';

export function EditAddressPage() {
  const { navigate } = useNavigate();
  const { enderecoId: addressId } = useParams();
  const { billingAddress, isLoading } = useBillingAddress(addressId);
  const address = useMemo(() => fromAdressApi(billingAddress), [billingAddress]);

  function saveAddress(changedAddress) {
    saveBillingAddress(addressId, toAdressApi(changedAddress)).then(() => {
      navigate('/carrinho-compras/pagamento');
    });
  }

  return (
    <div className="editar-endereco-page">
      <NavLink className="arrow-back" to="/carrinho-compras/pagamento">
        <Icon icon="left-arrow-desktop" />
      </NavLink>
      <div className="add-address-form">
        <h2 className="text-light mt-3 font-weight-bold address-data">Dados para faturamento</h2>

        {isLoading ? (
          <LoaderIcon />
        ) : (
          <Form
            initialValues={address}
            onSubmit={saveAddress}
            validations={billingAddressFormValidations}
            customActions={
              <div className="text-center">
                <button type="submit" className="btn btn-primary btn-lg">
                  PRÓXIMO
                </button>
              </div>
            }
          >
            <div className="row">
              <div className="col-md-6">
                <ShippingAddressFormField />
              </div>
              <div className="col-md-6">
                <BillingAddressFormFields />
              </div>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
}
