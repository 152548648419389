import { Route, Switch, useLocation } from 'react-router';

import { useNavigate } from 'shared/helpers/useNavigate';

import { Catwalk } from './catwalk/Catwalk';
import { FabricsCatalog } from './fabrics/Fabrics';
import { ModelsCatalog } from './models/Models';

export const MODELS_TAB_URL = 'models';
export const FABRICS_TAB_URL = 'fabrics';

const tabsDefinition = [
  {
    title: 'Modelos',
    pathname: '/',
  },
  {
    title: 'Tecidos',
    pathname: '/tecidos',
  },
];

export function Catalog() {
  const location = useLocation();
  const { navigate } = useNavigate();

  function onTabSelect(nextPath) {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();

      const query = {};

      if (nextPath === '/') {
        query['tipo-modelo'] = undefined;
      }

      navigate(nextPath, query, { inherit: true });
    };
  }

  function isActive(pathname) {
    return pathname === location.pathname;
  }

  return (
    <div className="catalog">
      <div className="row">
        <div className="col">
          <Catwalk />
        </div>
        <div className="col">
          <div className="showroom">
            <div className="custom-tabs-container">
              <ul className="nav nav-tabs nav-justified">
                {tabsDefinition.map(({ title, pathname }) => (
                  <li className="nav-item" key={pathname}>
                    <button
                      type="button"
                      className={`nav-link ${isActive(pathname) ? 'active' : ''}`}
                      onClick={onTabSelect(pathname)}
                    >
                      {title}
                    </button>
                  </li>
                ))}
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade active">
                  <Switch>
                    <Route exact path={'/'} component={ModelsCatalog} />
                    <Route exact path={'/tecidos'} component={FabricsCatalog} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
