export function TrashDesktopIcon() {
  return (
    <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" viewBox="0 0 25 25">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
        d="M25,5c0-0.3-0.3-0.6-0.6-0.6h-5.6V3.1c0-1.4-1.1-2.5-2.5-2.5H8.8 c-1.4,0-2.5,1.1-2.5,2.5v1.2h-5C0.9,4.4,0,4.7,0,5v1.2c0,0.3,0.9,0.6,1.2,0.6h1.4l1.1,15c0,1.4,1.1,2.5,2.5,2.5h12.5 c1.4,0,2.5-1.1,2.5-2.5l1.1-15h2.1c0.3,0,0.6-0.3,0.6-0.6V5z M17.5,21.9h-10c-0.7,0-1.2-0.6-1.2-1.2L5,6.9h15l-1.2,13.8 C18.8,21.3,18.2,21.9,17.5,21.9z M11.2,11.2c0-0.3-0.3-0.6-0.6-0.6H9.4c-0.3,0-0.6,0.3-0.6,0.6v6.2c0,0.3,0.3,0.6,0.6,0.6h1.2 c0.3,0,0.6-0.3,0.6-0.6V11.2z M16.2,11.2c0-0.3-0.3-0.6-0.6-0.6h-1.2c-0.3,0-0.6,0.3-0.6,0.6v6.2c0,0.3,0.3,0.6,0.6,0.6h1.2 c0.3,0,0.6-0.3,0.6-0.6V11.2z M10,3.1h5c0.7,0,1.2,0.6,1.2,1.2H8.8C8.8,3.7,9.3,3.1,10,3.1z"
      />
    </svg>
  );
}
