import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroupInput, useDialog } from 'react-bootstrap-utils';

import { LoaderIcon } from 'shared/components/LoaderIcon';
import { useNavigate } from 'shared/helpers/useNavigate';
import { saveUserFabric, uploadUserFabric } from 'shared/http/user-fabrics.http';

export function useUploadUserFabricDialog() {
  const { DialogPortal, showDialog } = useDialog({
    title: 'Novo tecido',
    // eslint-disable-next-line react/prop-types
    body({ close }) {
      return <UploadUserFabric close={close} />;
    },
    staticBackdrop: true,
  });

  return {
    showUploadDialog: showDialog,
    UploadUserFabricDialogPortal: DialogPortal,
  };
}

function UploadUserFabric({ close }) {
  const { navigate } = useNavigate();
  const [imagemUrl, setImagemUrl] = useState();
  const [files, setFiles] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  function saveFabric(userFabricInfo) {
    close();
    const formData = new FormData();

    formData.append('imagemOriginal', files[0], files[0].name);
    setIsSaving(true);
    uploadUserFabric(formData)
      .then((userFabric) => saveUserFabric(userFabric._id, userFabricInfo))
      .then((userFabric) => {
        setIsSaving(false);
        navigate('/', { 'tecido-usuario': userFabric._id });
      }).catch(() => {
        setIsSaving(false);
      });
  }

  function uploadFabric(e) {
    setFiles(e.target.files);
  }

  useEffect(() => {
    if (files.length === 0) {
      return;
    }

    const imagem = files[0];

    const reader = new FileReader();

    reader.readAsDataURL(imagem);
    reader.onloadend = (data) => {
      setImagemUrl(data.target.result);
    };
  }, [files]);

  return (
    <div className="m-3 upload-user-fabric-form">
      {files.length === 0 ? (
        <div className="custom-file">
          <input type="file" className="custom-file-input" onChange={uploadFabric} accept=".jpg, .png, .jpeg" />
          <label className="custom-file-label" htmlFor="customFile">
            Selecione uma imagem
          </label>
        </div>
      ) : (
        <Form
          initialValues={{}}
          onSubmit={saveFabric}
          customActions={
            <>
            {isSaving && <LoaderIcon />}
            <div className="form-group text-right">
              <button type="button" className="btn btn-secondary" onClick={close} disabled={isSaving}>
                Cancelar
              </button>
              <button type="submit" className="btn btn-custom ml-2" disabled={isSaving}>
                Salvar
              </button>
            </div>
            </>
          }
        >
          <div>
            <img src={imagemUrl} className="box-imagem" alt="tecido do usuário para importar" crossOrigin="anonymous" />
          </div>
          <FormGroupInput label="Código" name="codigo" required disabled={isSaving} />
          <FormGroupInput label="Descrição" name="descricao" required disabled={isSaving} />
          <FormGroupInput label="Contato" name="contato" disabled={isSaving} />
        </Form>
      )}
    </div>
  );
}
UploadUserFabric.propTypes = {
  close: PropTypes.func,
};
