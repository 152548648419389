export function LeftArrowAltIcon() {
  return (
    <svg viewBox="0 0 16 25" xmlns="http://www.w3.org/2000/svg">
      <g className="left-arrow">
        <path d="M 0 0 L 0 1 L 12 13 L 0 24 L 0 25 L 16 25 L 16 0 Z" className="background" />
        <rect width="16" height="1" className="border" />
        <rect y="24" width="16" height="1" className="border" />
      </g>
    </svg>
  );
}
