import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { parse } from './query-string';

export function useSearch() {
  const { search: _search } = useLocation();
  const parsedSearch = useMemo(() => parse(_search || ''), [_search]);

  return parsedSearch;
}
