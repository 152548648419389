import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { HorizontalScroll } from 'shared/components/scroll/HorizontalScroll';
import { LoaderIcon } from 'shared/components/LoaderIcon';
import { trackViewedModels } from 'shared/analytics';
import { useModels } from 'shared/http/models.http';

import { ModelThumbnail } from './ModelThumbnail';

export function ModelsByFabricGroup({ modelType, fabricGroup, inviteIfNotLogged }) {
  const { models, isLoading } = useModels(
    {
      fabricGroupId: fabricGroup._id,
      modelTypeId: modelType._id,
    },
    Boolean(fabricGroup._id && modelType._id)
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    trackViewedModels(models);
  }, [isLoading, models]);

  return (
    <div className="models-by-fabric-group">
      {isLoading && <LoaderIcon />}

      {models.length > 0 && (
        <div className="row">
          <div className="col-sm-12">
            <div className="title">{fabricGroup.name}</div>

            <HorizontalScroll>
              {models.map((model) => (
                <ModelThumbnail key={model._id} model={model} inviteIfNotLogged={inviteIfNotLogged} />
              ))}
            </HorizontalScroll>
            <hr />
          </div>
        </div>
      )}
    </div>
  );
}
ModelsByFabricGroup.propTypes = {
  modelType: PropTypes.object,
  fabricGroup: PropTypes.object,
  inviteIfNotLogged: PropTypes.func.isRequired,
};
