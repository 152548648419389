export const CircledArrowLeftAltIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 25 25">
    <g>
      <path
        fill="#5D5E5E"
        d="M13.9,18.5c0.3,0.3,0.9,0.3,1.2,0c0.3-0.3,0.3-0.9,0-1.2l-4.9-4.8L15,7.7c0.3-0.3,0.3-0.9,0-1.2 c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2,0-0.4,0.1-0.6,0.2l-5.5,5.4c-0.2,0.2-0.3,0.4-0.3,0.6s0.1,0.5,0.3,0.6L13.9,18.5z"
      />
      <path
        fill="#5D5E5E"
        d="M12.5,25C19.4,25,25,19.4,25,12.5C25,5.6,19.4,0,12.5,0C5.6,0,0,5.6,0,12.5C0,19.4,5.6,25,12.5,25z M12.5,1.9c5.9,0,10.6,4.8,10.6,10.6c0,5.9-4.8,10.6-10.6,10.6c-5.9,0-10.6-4.8-10.6-10.6C1.9,6.6,6.6,1.9,12.5,1.9z"
      />
    </g>
  </svg>
);
