import PropTypes from 'prop-types';
import { Dialog } from 'react-bootstrap-utils';

export function UserFabricDetailsDialog({ userFabric, children, afterSelect }) {
  function onSelect(close) {
    close();
    afterSelect(userFabric);
  }

  return (
    <Dialog body={({ close }) => <UserFabricDetails userFabric={userFabric} onSelect={() => onSelect(close)} />}>
      {children}
    </Dialog>
  );
}
UserFabricDetailsDialog.propTypes = {
  userFabric: PropTypes.object,
  afterSelect: PropTypes.func,
  children: PropTypes.node,
};

export function UserFabricDetails({ userFabric, onSelect }) {
  return (
    <div className="manufacturer-card">
      <div className="row">
        <div className="col-md-6">
          <img
            src={userFabric.images.url || '/assets/image-placeholder.png'}
            style={{ height: '100%', width: '100%' }}
            alt="missing foto placeholder"
          />
        </div>
        <div className="col-md-6">
          <div className="page-header">
            <h4>Código: {userFabric.codigo || '-'}</h4>
          </div>

          <p>Descrição: {userFabric.descricao || '-'}</p>
          <p>Contato: {userFabric.contato || '-'}</p>

          <button type="button" className="btn btn-lg btn-block" onClick={onSelect}>
            Aplicar
          </button>
        </div>
      </div>
    </div>
  );
}
UserFabricDetails.propTypes = {
  userFabric: PropTypes.object,
  onSelect: PropTypes.func,
};
