export function EntrarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="90.018" height="39.005" viewBox="0 0 90.018 39.005">
      <defs>
        <filter id="Caminho_91" x="0" y="0" width="90.018" height="39.005" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.404" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Grupo_2578" data-name="Grupo 2578" transform="translate(-115.234 -36)">
        <g transform="matrix(1, 0, 0, 1, 115.23, 36)" filter="url(#Caminho_91)">
          <path
            id="Caminho_91-2"
            data-name="Caminho 91"
            d="M10.5,0H61.516a10.5,10.5,0,1,1,0,21.005H10.5A10.5,10.5,0,0,1,10.5,0Z"
            transform="translate(9 6)"
            fill="#00c4c9"
          />
        </g>
        <text
          id="ENTRAR"
          transform="translate(156 57)"
          fill="#fff"
          fontSize="12"
          fontFamily="OpenSans-Bold, Open Sans"
          fontWeight="700"
        >
          <tspan x="-23.774" y="0">
            ENTRAR
          </tspan>
        </text>
        <path
          id="ic_code_24px"
          d="M14.6,13.57l3.285-3.285L14.6,7l1-1,4.285,4.285L15.6,14.57Z"
          transform="translate(169.649 42.421)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
