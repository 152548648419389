import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { formatMoney } from 'shared/helpers/formatters';
import { Icon } from 'shared/components/Icon';
import { useSearch } from 'shared/helpers/useSearch';
import { setOrderTransaction, useOrder } from 'shared/http/orders.http';
import { LoaderIcon } from 'shared/components/LoaderIcon';

import { RenderModel } from '../catalog/catwalk/RenderModel';

export function OrderPage() {
  const { id: orderId } = useParams();
  const { transactionId } = useSearch();

  const { order, isLoading } = useOrder(orderId);

  const modelVariation = useMemo(() => (order ? order.modelVariation : null), [order]);
  const variationIndex = useMemo(() => (modelVariation ? parseInt(modelVariation, 10) : null), [modelVariation]);

  useEffect(() => {
    if (isLoading || !order) {
      return true;
    }

    if (transactionId) {
      setOrderTransaction(orderId, transactionId);
    }
  }, [order, isLoading]);

  if (isLoading) {
    return (
      <div className="order-page">
        <LoaderIcon />
      </div>
    );
  }

  return (
    <div className="order-page">
      <header>
        <h3>
          <div>
            <Link to="/meus-pedidos" className="mc-icon">
              <Icon icon="left-arrow-desktop" />
            </Link>
            Pedido {order._id.substring(16)}
          </div>

          <div>Total: {formatMoney(order.total)}</div>
        </h3>
      </header>
      <section>
        <div className="payment">
          <h3>Obrigado! Sua compra foi concluída com sucesso!</h3>

          <p>Enviaremos em breve uma mensagem para o seu e-mail com uma cópia do contrato de venda.</p>

          <p>
            Seus moldes serão enviados <strong>em até três</strong> dias úteis após a <strong>confirmação</strong> de
            pagamento.
          </p>
        </div>

        <div className="orders">
          {order.items.map((item) => (
            <div className="order" key={item._id}>
              <div className="order-inner">
                <header>
                  <span>{item.code}</span>
                </header>

                <section>
                  <div className="preview">
                    <RenderModel
                      model={item.model}
                      fabric={item.fabricToken}
                      variationIndex={variationIndex}
                      front-mode={true}
                    />
                  </div>
                  <div className="description">
                    <div className="measures">
                      Busto {item.measures.bust} cm <br />
                      Cintura {item.measures.waist} cm <br />
                      Quadril {item.measures.hip} cm <br />
                    </div>
                    <div className="model-size">Tam prova {item.measures.sizeName}</div>
                    <div className="sizes">
                      {item.sizes.map((size, index) => (
                        <span key={index}>
                          {size.name} {size.size}
                        </span>
                      ))}
                    </div>
                    <div className="price">{formatMoney(item.total)}</div>
                  </div>
                </section>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
