import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import { stringify } from 'shared/helpers/query-string';
import { useSearch } from 'shared/helpers/useSearch';
import {
  getFabricGroupNameWithoutType,
  getFabricsFromFabricGroups,
  useFabricGroups,
} from 'shared/http/fabric-groups.http';

import { LoaderIcon } from 'shared/components/LoaderIcon';

export function FabricsBySearch({ filter, groupFilter, subgroupFilter }) {
  const { fabricGroups, isLoading } = useFabricGroups();
  const search = useSearch();
  const location = useLocation();

  const fabrics = useMemo(
    () => (fabricGroups.length > 0 ? filterFabrics(getFabricsFromFabricGroups(fabricGroups)) : []),
    [fabricGroups]
  );

  function filterFabrics(_fabrics) {
    if (!_fabrics) {
      return;
    }

    const nameFilter = new RegExp(filter, 'i');

    return _fabrics.filter(
      (fabric) =>
        nameFilter.test(fabric.name) &&
        (!groupFilter || groupFilter.includes(fabric.parents.group._id)) &&
        (!subgroupFilter || subgroupFilter.includes(fabric.parents.subgroup._id))
    );
  }

  if (isLoading) {
    return <LoaderIcon />;
  }

  return (
    <div className="fabrics-by-search">
      <ul className="nav nav-pills flex-column">
        {fabrics.map((fabric) => (
          <li key={fabric._id} className="nav-item">
            <Link
              to={{
                ...location,
                search: stringify({
                  ...search,
                  fabricGroup: fabric.parents.group._id,
                  fabricSubgroup: fabric.parents.subgroup._id,
                  fabric: fabric._id,
                  fabricToken: undefined,
                  fabricSearchQuery: undefined,
                }),
              }}
              className="nav-link"
            >
              {fabric.name} <br />
              <small>
                {getFabricGroupNameWithoutType(fabric.parents.group)} &gt; {fabric.parents.subgroup.name}
              </small>
            </Link>
          </li>
        ))}
      </ul>
      {fabrics.length === 0 && <p>Nenhum tecido encontrado</p>}
    </div>
  );
}
FabricsBySearch.propTypes = {
  filter: PropTypes.string,
  groupFilter: PropTypes.string,
  subgroupFilter: PropTypes.string,
};
