import useSWR from 'swr';

import { swrFetcher } from '../helpers/http';

const baseUrl = '/api/fabric-manufacturers';

export function useFabricManufacturers(filters, shouldLoad = true) {
  const searchStr = buildFabricManufacturerSearch(filters);

  const { data, error } = useSWR(shouldLoad && `${baseUrl}/${searchStr}`, swrFetcher);

  return {
    fabricManufacturers: data || [],
    isLoading: Boolean(shouldLoad && !error && !data),
    isError: error,
  };
}

export function useFabricManufacturer(fabricManufacturerId) {
  const { data, error } = useSWR(fabricManufacturerId && `${baseUrl}/${fabricManufacturerId}`, swrFetcher);

  return {
    fabricManufacturer: data,
    isLoading: Boolean(fabricManufacturerId && !error && !data),
    isError: error,
  };
}

function buildFabricManufacturerSearch({ fabricId }) {
  return `?by_fabric_ids%5B%5D=${fabricId}`;
}
