import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useFabricManufacturers } from 'shared/http/fabric-manufacturers.http';
import { getValue } from 'shared/helpers/getters-setters';

import { LoaderIcon } from 'shared/components/LoaderIcon';

import { FabricsLightMode } from './FabricsLightMode';
import { FabricTokens } from './FabricTokens';

export function FabricManufacturers({ fabric }) {
  const [isLoading, setIsLoading] = useState(0);
  const { fabricManufacturers: manufacturers, isLoading: isLoadingManufaturers } = useFabricManufacturers(
    {
      fabricId: fabric && fabric._id,
    },
    Boolean(fabric && fabric._id)
  );

  const isEmpty = useMemo(() => {
    if (typeof document === 'undefined') {
      return false;
    }

    const fabricTokensWithRepresentant = document.querySelectorAll('.fabric-manufacturers .fabric-token').length;

    return !isLoading && ((manufacturers && manufacturers.length === 0) || fabricTokensWithRepresentant === 0);
  }, [isLoading, manufacturers]);

  function notifyIsLoading() {
    setIsLoading((i) => i + 1);
  }

  function notifyLoadingFinished() {
    setIsLoading((i) => i - 1);
  }

  useEffect(() => {
    setIsLoading(0);
  }, [fabric]);

  useEffect(() => {
    if (isLoadingManufaturers) {
      notifyIsLoading();

      return;
    }

    notifyLoadingFinished();
  }, [isLoadingManufaturers]);

  return (
    <div className="fabric-manufacturers">
      <h5>
        {getValue(fabric, 'name')}
        <FabricsLightMode className="float-right" />
      </h5>
      {manufacturers.map((manufacturer) => (
        <FabricTokens
          key={manufacturer._id}
          fabric={fabric}
          manufacturer={manufacturer}
          onStartLoading={notifyIsLoading}
          onFinishLoading={notifyLoadingFinished}
        />
      ))}
      {isEmpty && (
        <div>
          <em>Nenhum tecido encontrado</em>
        </div>
      )}
      {isLoading > 0 ? (
        <div>
          <LoaderIcon />
          <em>Procurando tecidos...</em>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
FabricManufacturers.propTypes = {
  fabric: PropTypes.object,
};
