import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormInput, useFormControl } from 'react-bootstrap-utils';

import { formatCEP } from 'shared/helpers/formatters';
import { useAddressFromZip } from 'shared/http/billing-address.http';

export function FormZip({ name }) {
  const { getValue: getCep } = useFormControl(name);
  const { getValue, setValue } = useFormControl('address', 'object');

  const addressValue = getValue();
  const zipValue = getCep();
  const { address: addressFromZip } = useAddressFromZip(zipValue);

  useEffect(() => {
    if (!addressFromZip) {
      return;
    }

    setValue({
      ...addressValue,
      zip: formatCEP(zipValue),
      street: addressFromZip.street,
      district: addressFromZip.neighborhood,
      city: addressFromZip.city,
      state: addressFromZip.state,
      country: 'Brazil',
    });
  }, [addressFromZip]);

  return (
    <FormInput
      name={name}
      type="tel"
      placeholder="CEP *"
      title="CEP precisa ser no formato 99.999-999"
      pattern="^[0-9]{2}\.?[0-9]{3}-?[0-9]{3}$"
      minLength="8"
      maxLength="10"
      required
    />
  );
}
FormZip.propTypes = {
  name: PropTypes.string,
};
