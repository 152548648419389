import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { requestPasswordRecover } from 'shared/http/auth.http';

export function RequestPasswordRecover({ data, onGoBack }) {
  function requestRecover(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    requestPasswordRecover(data.email);
  }

  function voltar(e) {
    e.preventDefault();
    e.stopPropagation();

    onGoBack();
  }

  useEffect(() => {
    requestRecover();
  }, []);

  return (
    <div className="request-password-recover login-page-step">
      <div className="content">
        <h1>Recuperar senha</h1>

        <div className="text-center">
          <h2>Foi solicitada a recuperação de sua senha.</h2>
          <h3>Verifique seu eMail.</h3>
        </div>

        <p>
          Não Recebeu seu eMail?
          <br />
          <a onClick={requestRecover}>Clique aqui</a> para reenviar.
        </p>

        <a href="" className="extra-link" onClick={voltar}>
          Voltar
        </a>
      </div>
    </div>
  );
}
RequestPasswordRecover.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  data: PropTypes.object,
};
