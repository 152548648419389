export const TrashIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 12 16">
    <path
      fill="#fff"
      id="ic_delete_sweep_24px"
      d="M3,18a2.006,2.006,0,0,0,2,2h6a2.006,2.006,0,0,0,2-2V8H3ZM14,5H11L10,4H6L5,5H2V7H14Z"
      transform="translate(-2 -4)"
    />
  </svg>
);
