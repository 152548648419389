export function TimesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.556 15.557" width="20px">
      <g id="Group_2488" data-name="Group 2488" transform="translate(-1340.222 -2247.222)">
        <rect
          id="Rectangle_1615"
          data-name="Rectangle 1615"
          width="2"
          height="20"
          transform="translate(1354.364 2247.222) rotate(45)"
          fill="#fff"
        />
        <rect
          id="Rectangle_1616"
          data-name="Rectangle 1616"
          width="2"
          height="20"
          transform="translate(1355.778 2261.364) rotate(135)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
