import { Link, NavLink, useLocation } from 'react-router-dom';

import { stringify } from 'shared/helpers/query-string';
import { logoutUser, useSession } from 'shared/http/auth.http';
import { useSearch } from 'shared/helpers/useSearch';

export function Sidebar() {
  const { isAuthenticated } = useSession();
  const search = useSearch();
  const location = useLocation();

  const currentUrl = `${location.pathname}${location.search}`;

  const closeSidebar = () => {
    const elem = document.querySelector('.sidebar');

    elem.classList.remove('open');
  };

  const onLogout = (e) => {
    e.preventDefault();
    logoutUser().then(() => {
      closeSidebar();
      window.location.reload();
    });
  };

  const buildUrl = (url, query) => `${url}?${stringify(query)}`;

  return (
    <div className="sidebar" onClick={closeSidebar} role="navigation">
      <div className="overlay">
        <div className="navbar">
          <div className="logo">
            <Link to="/">
              <img src="/assets/logo-horizontal-wh.svg" alt="Logo Modacad" width="200px" />
            </Link>
          </div>

          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to="/" className="nav-link">
                Catálogo
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/carrinho-compras" className="nav-link">
                Carrinho de Compras
              </NavLink>
            </li>

            {!isAuthenticated ? (
              <li className="nav-item">
                <NavLink
                  to={{
                    pathname: '/login',
                    search: stringify({
                      redirectTo: currentUrl,
                    }),
                  }}
                  className="nav-link"
                >
                  Entrar/Cadastrar
                </NavLink>
              </li>
            ) : (
              <>
                <li className="nav-item">
                  <NavLink to="/meus-pedidos" className="nav-link">
                    Meus Pedidos
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink to={buildUrl('/meus-tecidos', search)} className="nav-link">
                    Meus Tecidos
                  </NavLink>
                </li>

                <li className="nav-item">
                  <button type="button" className="nav-link" onClick={onLogout}>
                    Sair
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="versao">
          <p className="text-center">Versão Atual {process.env.VERSION}</p>
        </div>
      </div>
    </div>
  );
}
