export function CheckedDesktopIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 30 30">
      <g id="Grupo_2691" data-name="Grupo 2691" transform="translate(3873 -12868)">
        <circle
          fill="#00c4c9"
          id="Elipse_17"
          data-name="Elipse 17"
          cx="15"
          cy="15"
          r="15"
          transform="translate(-3873 12868)"
        />
        <path
          fill="#fff"
          id="ic_done_24px"
          d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z"
          transform="translate(-3870.4 12870.4)"
        />
      </g>
    </svg>
  );
}
