import { useEffect } from 'react';

import { useNavigate } from 'shared/helpers/useNavigate';
import { useSearch } from 'shared/helpers/useSearch';

export function RedirectUser() {
  const { redirectTo } = useSearch();
  const { navigateToUrl } = useNavigate();

  useEffect(() => {
    navigateToUrl(redirectTo || '/');
  }, []);

  return (
    <div className="login-page-step">
      <div className="content">
        <div className="conta-gratuita">Redirecionando usuário</div>
      </div>
    </div>
  );
}
