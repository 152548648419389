import useSWR from 'swr';

import { swrFetcher } from '../helpers/http';

const baseUrl = '/api/model-types';

export function useModelTypes(filters, shouldLoad) {
  const searchStr = buildModelTypesSearch(filters);

  const { data, error } = useSWR(shouldLoad && `${baseUrl}${searchStr}`, swrFetcher);

  return {
    modelTypes: data || [],
    isLoading: Boolean(shouldLoad && !error && !data),
    isError: error,
  };
}

export function useModelType(modelTypeId) {
  const { data, error } = useSWR(modelTypeId && `${baseUrl}/${modelTypeId}`, swrFetcher);

  return {
    modelType: data,
    isLoading: Boolean(modelTypeId && !error && !data),
    isError: error,
  };
}

function buildModelTypesSearch(filters) {
  const search = [];

  if (filters && filters.fabricSubgroupId) {
    search.push(`by_subgroup_ids%5B%5D=${filters.fabricSubgroupId}`);
  }

  let searchStr = search.join('&');

  if (searchStr.length > 0) {
    searchStr = `?${searchStr}`;
  }

  return searchStr;
}
