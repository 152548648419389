export const CircledCloseIcon = () => (
  <svg
    className="mc-icon-svg"
    xmlns="http://www.w3.org/2000/svg"
    width="25px"
    height="25px"
    viewBox="0 0 25 25"
    enableBackground="new 0 0 25 25"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#5D5E5E"
      d="M12.5,0C5.6,0,0,5.6,0,12.5C0,19.4,5.6,25,12.5,25S25,19.4,25,12.5
	C25,5.6,19.4,0,12.5,0z M15.3,16l-2.8-2.8L9.7,16L9,15.3l2.8-2.8L9,9.7L9.7,9l2.8,2.8L15.3,9L16,9.7l-2.8,2.8l2.8,2.8L15.3,16z"
    />
  </svg>
);
