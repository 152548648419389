export function ModeloProvaIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="28px" viewBox="0 0 95 45">
      <polygon fillRule="evenodd" clipRule="evenodd" points="85,0 0,0 0,20 0,40 85,40 95,20 " />
      <path fillRule="evenodd" clipRule="evenodd" d="M0,40h10v5L0,40z" />
      <text transform="matrix(1 0 0 1 9.1675 15.9126)">
        <tspan x="0" y="0">
          TAM.
        </tspan>
        <tspan x="0" y="16.843">
          PROVA
        </tspan>
      </text>
    </svg>
  );
}
