export const ListIcon = () => (
  <svg className="mc-icon-svg" xmlns="http://www.w3.org/2000/svg" width="20px" height="18px" viewBox="0 0 20 18">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#5D5E5E"
      d="M0.842,9.811h18.316c0.46,0,0.833-0.363,0.833-0.811 c0-0.447-0.373-0.811-0.833-0.811H0.842C0.382,8.189,0.01,8.553,0.01,9C0.01,9.448,0.382,9.811,0.842,9.811z M0.842,2.114h18.316 c0.46,0,0.833-0.363,0.833-0.811c0-0.447-0.373-0.811-0.833-0.811H0.842c-0.46,0-0.833,0.363-0.833,0.811 C0.01,1.751,0.382,2.114,0.842,2.114z M19.158,15.886H0.842c-0.46,0-0.833,0.363-0.833,0.811s0.373,0.811,0.833,0.811h18.316 c0.46,0,0.833-0.363,0.833-0.811S19.618,15.886,19.158,15.886z"
    />
  </svg>
);
