import PropTypes from 'prop-types';
import { Form, FormInput } from 'react-bootstrap-utils';

import { verifyUserByEmail } from 'shared/http/auth.http';

import { isValidEmailRegExp } from 'modacad-domain';

export function CheckUserEmailForm({ onProceed }) {
  function onSubmit({ email }) {
    verifyUserByEmail(email).then(({ existeUsuarioCadastrado }) => {
      onProceed({ email, isRegistered: existeUsuarioCadastrado });
    });
  }

  return (
    <div className="check-user-email-form login-page-step">
      <Form
        initialValues={{}}
        onSubmit={onSubmit}
        customActions={
          <button type="submit" className="btn btn-lg btn-primary">
            PRÓXIMO
          </button>
        }
        validations={{
          email: [
            {
              //TODO: original form used "Preencha seu email for empty emails"
              message: 'Preencha um email válido',
              validate(value) {
                return isValidEmailRegExp.test(value);
              },
            },
          ],
        }}
      >
        <p className="conta-gratuita">Sua conta é gratuita e ilimitada.</p>

        <FormInput name="email" type="email" placeholder="Coloque o seu email aqui" required />
      </Form>
    </div>
  );
}
CheckUserEmailForm.propTypes = {
  onProceed: PropTypes.func.isRequired,
};
