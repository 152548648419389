export function gerarCodigoModelo(model, modelVariation) {
  //02M:1 CC 101 F:1 C:3 L:00
  //model.custom_fabric_code model.type.code model.code model.unfolding.code
  let itemCode = `${model.custom_fabric_code} ${model.type.code} ${model.code}`;

  if (modelVariation) {
    const variation = model.unfoldings[modelVariation];

    itemCode += ` ${variation.code}`;
  } else {
    itemCode += ` ${(model.default_unfolding && model.default_unfolding.code) || ''}`;
  }

  return itemCode;
}
