import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { fetchPrivacyPolicy, fetchTermsAndConditions } from 'shared/http/cart-payment.http';
import { LoaderIcon } from 'shared/components/LoaderIcon';

export function AcceptContract({ setIsLoadingTerms, onAccept }) {
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    setIsLoadingTerms(true);
    Promise.all([fetchTermsAndConditions(), fetchPrivacyPolicy()]).then(([termsAndConditions, privacyPolicy]) => {
      setTermsAndConditions(termsAndConditions);
      setPrivacyPolicy(privacyPolicy);
      setIsloading(false);
      setIsLoadingTerms(false);
    }, () => {
      setIsloading(false);
      setIsLoadingTerms(false);
    });
  }, []);

  if (isLoading) {
    return (
      <div className="accept-contract">
        <div className="content-contract">
          <div>
            <h3>Aguarde enquanto preparamos o contrato</h3>
            <LoaderIcon />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="accept-contract">
      <div className="content-contract">
        <div>
          <p className="mt-2">
            Olá, que bom que você encontrou o que deseja, nós trabalhamos bastante para desenvolver essa plataforma
            para você! Para registrar o nosso negócio, preparamos esse contrato em linguagem simples, não deixe de ler
            para você saber ao que tem direito! Lembramos que ao aceitar esse contrato, você concorda integralmente
            com as condições nele descritas.
          </p>
          <p>
            Caso você tenha alguma dúvida, não deixe de entrar em contato através do &quot;fale conosco&quot; em nosso
            site ou, ainda, através do e-mail{' '}
            <a href="mailto:atendimento@modacad.com.br" title="Atendimento Modacad">
              atendimento@modacad.com.br
            </a>
            . Nossos especialistas farão de tudo para resolver a sua questão no menor prazo possível.
          </p>
        </div>
        <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
        <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
        <div>
          <h4 className="text-light"> E, POR FIM:</h4>
          <p>
            Esse contrato, em conjunto com a nossa política de privacidade, bem como suas posteriores alterações,
            regularão a nossa relação decorrente da compra de nossos produtos e/ou uso de nosso sistema. Caso você
            tenha dúvidas ou questões sobre a sua relação conosco, por favor entre em contato através do nosso site.
            No caso de controvérsias que a gente não possa resolver amigavelmente, fica eleito o foro da comarca de
            Belo Horizonte, Minas Gerais, para solução de demandas advindas deste contrato.
          </p>
        </div>
      </div>
      <h5 className="text-center text-light accept-form">
        <input type="checkbox" name="contractAccepted" id="contractAccepted" className="custom-control-input" defaultValue={false} onChange={({currentTarget}) => {
          onAccept({contractAccepted:currentTarget.checked});
        }} />
        <label htmlFor="contractAccepted" className="custom-control-label">Li e aceito o contrato</label>
      </h5>
    </div>
  );
}

AcceptContract.propTypes = {
  setIsLoadingTerms: PropTypes.func,
  onAccept: PropTypes.func
};
