export function getValue(doc, path, formatter) {
  const paths = path.split('.');

  const value = paths.reduce((acc, attr) => {
    if (!acc) {
      return;
    }

    return acc[attr];
  }, doc);

  return formatter ? formatter(value) : value;
}

export function setValue(doc, path, value) {
  const paths = path.split('.');

  const lastPath = paths[paths.length - 1];
  const remainingPaths = paths.slice(0, paths.length - 1);

  const obj = remainingPaths.reduce((acc, attr) => {
    const x = getValueByIndex(acc, attr);

    if (!x) {
      setValueByIndex(acc, attr, {});
    }

    return x;
  }, doc);

  setValueByIndex(obj, lastPath, value);
}

export function serialize(value) {
  if (typeof value !== 'object') {
    return value;
  }

  return JSON.stringify(value);
}

function getValueByIndex(doc, path) {
  const regexp = path.match(/(.+)\[(.+)\]/);

  if (regexp) {
    const index = regexp[2];
    const propertyName = regexp[1];

    return doc[propertyName][index];
  }

  return doc[path];
}

function setValueByIndex(doc, path, value) {
  const regexp = path.match(/(.+)\[(.+)\]/);

  if (regexp) {
    const index = regexp[2];
    const propertyName = regexp[1];

    doc[propertyName][index] = value;
  } else {
    doc[path] = value;
  }
}
