import { FormInput } from 'react-bootstrap-utils';

import { isValidCnpj, validateCpfCnpj, validateUserName } from 'shared/validations';

import { FormPhoneNumber } from './FormPhoneNumber';
import { FormZip } from './FormZip';

export function BillingAddressFormFields() {
  return (
    <>
      <FormInput name="name" minLength="5" placeholder="Nome *" required />
      <FormInput name="cpfCnpj" placeholder="CPF/CNPJ *" required />
      <FormInput
        name="inscricaoEstadual"
        placeholder="Inscrição Estadual"
        required={({ cpfCnpj }) => isValidCnpj(cpfCnpj)}
        disabled={({ cpfCnpj }) => !isValidCnpj(cpfCnpj)}
      />
      <FormInput
        name="inscricaoMunicipal"
        placeholder="Inscrição Municipal"
        required={({ cpfCnpj }) => isValidCnpj(cpfCnpj)}
        disabled={({ cpfCnpj }) => !isValidCnpj(cpfCnpj)}
      />
      <FormPhoneNumber name="phone" required />
    </>
  );
}

export const billingAddressFormValidations = {
  name: [
    {
      message: 'Necessário informar nome e sobrenome válidos',
      validate: validateUserName,
    },
  ],
  cpfCnpj: [
    {
      message: 'CPF/CNPJ inválidos',
      validate: validateCpfCnpj,
    },
  ],
};

export function ShippingAddressFormField() {
  return (
    <>
      <FormZip name="address.zip" />
      <FormInput name="address.street" placeholder="Rua *" required />
      <FormInput name="address.number" placeholder="Número *" required />
      <FormInput name="address.complement" placeholder="Complemento" />
      <FormInput name="address.district" placeholder="Bairro *" required />
      <FormInput name="address.city" type="text" placeholder="Cidade *" required />
      <FormInput name="address.state" type="text" placeholder="UF *" required />
    </>
  );
}

export function fromAdressApi(address) {
  if (!address) {
    return {};
  }

  return {
    ...address,
    cpfCnpj: address.documents[0].value,
    inscricaoEstadual: address.documents[1].value,
    inscricaoMunicipal: address.documents[2].value,
  };
}

export function toAdressApi(address) {
  return {
    ...address,
    documents: [
      {
        name: 'cpfCnpj',
        label: 'CPF / CNPJ',
        value: address.cpfCnpj,
      },
      {
        name: 'inscricaoEstadual',
        label: 'Inscrição Estadual',
        value: address.inscricaoEstadual,
      },
      {
        name: 'inscricaoMunicipal',
        label: 'Inscrição Municipal',
        value: address.inscricaoMunicipal,
      },
    ],
  };
}
