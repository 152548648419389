import PropTypes from 'prop-types';

import { ArrowIcon } from './icons/ArrowIcon';
import { AvatarIcon } from './icons/AvatarIcon';
import { BustIcon } from './icons/BustIcon';
import { CameraIcon } from './icons/CameraIcon';
import { CartIcon } from './icons/CartIcon';
import { CheckCircleIcon } from './icons/CheckCircleIcon';
import { CheckedDesktopIcon } from './icons/CheckedDesktopIcon';
import { CheckedIcon } from './icons/CheckedIcon';
import { CheckIcon } from './icons/CheckIcon';
import { ChevronDownIcon } from './icons/ChevronDownIcon';
import { ChevronLeftIcon } from './icons/ChevronLeftIcon';
import { ChevronRightIcon } from './icons/ChevronRightIcon';
import { ChevronUpIcon } from './icons/ChevronUpIcon';
import { CircledArrowLeftAltIcon } from './icons/CircledArrowLeftAltIcon';
import { CircledArrowLeftIcon } from './icons/CircledArrowLeftIcon';
import { CircledArrowRightAltIcon } from './icons/CircledArrowRightAltIcon';
import { CircledArrowRightIcon } from './icons/CircledArrowRightIcon';
import { CircledCloseIcon } from './icons/CircledCloseIcon';
import { CircledPencilIcon } from './icons/CircledPencilIcon';
import { CloseIcon } from './icons/CloseIcon';
import { CloseRawIcon } from './icons/CloseRawIcon';
import { CloseSlimIcon } from './icons/CloseSlimIcon';
import { DayIcon } from './icons/DayIcon';
import { DefaultIcon } from './icons/DefaultIcon';
import { DownloadIcon } from './icons/DownloadIcon';
import { EditDesktopIcon } from './icons/EditDesktopIcon';
import { EditIcon } from './icons/EditIcon';
import { EntrarIcon } from './icons/EntrarIcon';
import { EnviarMeuTecidoIcon } from './icons/EnviarMeuTecidoIcon';
import { ExportFabricIcon } from './icons/ExportFabricIcon';
import { EyeOffIcon } from './icons/EyeOffIcon';
import { EyeOnIcon } from './icons/EyeOnIcon';
import { GridIcon } from './icons/GridIcon';
import { HeartIcon } from './icons/HeartIcon';
import { HelpAltIcon } from './icons/HelpAltIcon';
import { HelpIcon } from './icons/HelpIcon';
import { ImportFabricIcon } from './icons/ImportFabricIcon';
import { InformationIcon } from './icons/InformationIcon';
import { LeftArrowDesktopIcon } from './icons/LeftArrowDesktopIcon';
import { LeftArrowIcon } from './icons/LeftArrowIcon';
import { LeftArrowAltIcon } from './icons/LeftArrowAltIcon';
import { ListIcon } from './icons/ListIcon';
import { MenuIcon } from './icons/MenuIcon';
import { MeusTecidosIcon } from './icons/MeusTecidosIcon';
import { ModeloProvaIcon } from './icons/ModeloProvaIcon';
import { NewOrderIcon } from './icons/NewOrderIcon';
import { NightIcon } from './icons/NightIcon';
import { OkIcon } from './icons/OkIcon';
import { PrintAltIcon } from './icons/PrintAltIcon';
import { PrintIcon } from './icons/PrintIcon';
import { RightArrowIcon } from './icons/RightArrowIcon';
import { RightArrowAltIcon } from './icons/RightArrowAltIcon';
import { RotateIcon } from './icons/RotateIcon';
import { SaveIcon } from './icons/SaveIcon';
import { SearchIcon } from './icons/SearchIcon';
import { TamanhoProvaIcon } from './icons/TamanhoProvaIcon';
import { TimesIcon } from './icons/TimesIcon';
import { TrashDesktopIcon } from './icons/TrashDesktopIcon';
import { TrashIcon } from './icons/TrashIcon';
import { UncheckedDesktopIcon } from './icons/UncheckedDesktopIcon';
import { UncheckedIcon } from './icons/UncheckedIcon';
import { UploadIcon } from './icons/UploadIcon';
import { UploadTecidoIcon } from './icons/UploadTecidoIcon';
import { ZoomInIcon } from './icons/ZoomInIcon';
import { ZoomOutIcon } from './icons/ZoomOutIcon';

const iconMap = {
  'arrow': <ArrowIcon />,
  'avatar': <AvatarIcon />,
  'bust': <BustIcon />,
  'camera': <CameraIcon />,
  'cart': <CartIcon />,
  'check-circle': <CheckCircleIcon />,
  'check': <CheckIcon />,
  'checked-desktop': <CheckedDesktopIcon />,
  'checked': <CheckedIcon />,
  'chevron-Down': <ChevronDownIcon />,
  'chevron-left': <ChevronLeftIcon />,
  'chevron-right': <ChevronRightIcon />,
  'chevron-Up': <ChevronUpIcon />,
  'circled-arrow-left-alt': <CircledArrowLeftAltIcon />,
  'circled-arrow-left': <CircledArrowLeftIcon />,
  'circled-arrow-right-alt': <CircledArrowRightAltIcon />,
  'circled-arrow-right': <CircledArrowRightIcon />,
  'circled-close': <CircledCloseIcon />,
  'circled-pencil': <CircledPencilIcon />,
  'close-raw': <CloseRawIcon />,
  'close-slim': <CloseSlimIcon />,
  'close': <CloseIcon />,
  'day': <DayIcon />,
  'download': <DownloadIcon />,
  'edit-desktop': <EditDesktopIcon />,
  'edit': <EditIcon />,
  'entrar': <EntrarIcon />,
  'enviar-meu-tecido': <EnviarMeuTecidoIcon />,
  'export-fabric': <ExportFabricIcon />,
  'eye-off': <EyeOffIcon />,
  'eye-on': <EyeOnIcon />,
  'grid': <GridIcon />,
  'heart': <HeartIcon />,
  'help-alt': <HelpAltIcon />,
  'help': <HelpIcon />,
  'import-fabric': <ImportFabricIcon />,
  'information': <InformationIcon />,
  'left-arrow-desktop': <LeftArrowDesktopIcon />,
  'left-arrow': <LeftArrowIcon />,
  'left-arrow-alt': <LeftArrowAltIcon />,
  'list': <ListIcon />,
  'menu': <MenuIcon />,
  'meus-tecidos': <MeusTecidosIcon />,
  'modelo-prova': <ModeloProvaIcon />,
  'new-order': <NewOrderIcon />,
  'night': <NightIcon />,
  'ok': <OkIcon />,
  'print-alt': <PrintAltIcon />,
  'print': <PrintIcon />,
  'right-arrow': <RightArrowIcon />,
  'right-arrow-alt': <RightArrowAltIcon />,
  'rotate': <RotateIcon />,
  'save': <SaveIcon />,
  'search': <SearchIcon />,
  'tamanho-prova': <TamanhoProvaIcon />,
  'trash-desktop': <TrashDesktopIcon />,
  'trash': <TrashIcon />,
  'unchecked-desktop': <UncheckedDesktopIcon />,
  'unchecked': <UncheckedIcon />,
  'upload-tecido': <UploadTecidoIcon />,
  'upload': <UploadIcon />,
  'x': <TimesIcon />,
  'zoom-in': <ZoomInIcon />,
  'zoom-out': <ZoomOutIcon />,
};

export function Icon({ icon }) {
  return iconMap[icon] || <DefaultIcon />;
}
Icon.propTypes = {
  icon: PropTypes.string.isRequired,
};
