import PropTypes from 'prop-types';

import { getValue } from 'shared/helpers/getters-setters';
import { useFabricManufacturer } from 'shared/http/fabric-manufacturers.http';

export function ManufacturerInfo({ token }) {
  const { fabricManufacturer: manufacturer } = useFabricManufacturer(token.manufacturer_id);

  return (
    <>
      <div className="manufacturer">
        <div className="manufacturer-logo">
          <h4>{getValue(manufacturer, 'name') || '-'}</h4>
          <p>TÊXTIL FICTÍCIA IND &amp; COM</p>
        </div>
      </div>

      <div className="manufacturer-info">
        <p>{getValue(manufacturer, 'name') || '-'}</p>
        <p />
        <p>{getValue(manufacturer, 'contact.name') || '-'}</p>
        <p>{getValue(manufacturer, 'contact.address') || '-'}</p>
        <p>
          {getValue(manufacturer, 'contact.phone') || '-'} / {getValue(manufacturer, 'contact.cellPhone') || '-'}
        </p>
        <p>{getValue(manufacturer, 'contact.email') || '-'}</p>
      </div>
    </>
  );
}
ManufacturerInfo.defaultProps = {
  token: {},
};
ManufacturerInfo.propTypes = {
  token: PropTypes.object,
};
