import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormInput } from 'react-bootstrap-utils';

import { Icon } from 'shared/components/Icon';
import { authenticateUser, recoverPassword } from 'shared/http/auth.http';
import { useNavigate } from 'shared/helpers/useNavigate';
import { useSearch } from 'shared/helpers/useSearch';

export function RecoverPassword({ showTitle = true }) {
  const { codigo, email } = useSearch();
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [data, setData] = useState({ codigo, email });

  //TODO: verificar se o código é valido e exibir mensagem de erro caso negativo

  function onPasswordChange(formData) {
    setPasswordChanged(true);
    setData((prevData) => ({ ...prevData, ...formData }));
  }

  return (
    <>
      {!passwordChanged ? (
        <ChangePasswordForm onProceed={onPasswordChange} data={data} showTitle={showTitle} />
      ) : (
        <ChangePasswordSuccess data={data} showTitle={showTitle} />
      )}
    </>
  );
}
RecoverPassword.propTypes = {
  showTitle: PropTypes.bool,
};

function ChangePasswordForm({ onProceed, data, showTitle = true }) {
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  function onSubmit(formData) {
    recoverPassword(formData).then(({ senhaModificada }) => {
      if (senhaModificada) {
        onProceed(formData);
      }
    });
  }

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      customActions={
        <button type="submit" className="btn btn-lg btn-primary">
          PRÓXIMO
        </button>
      }
      validations={{
        passwordConfirmation: [
          {
            message: 'As senhas digitadas devem ser iguais',
            validate(_, formData) {
              return formData.password === formData.passwordConfirmation;
            },
          },
        ],
      }}
    >
      {showTitle && <h1>Recuperar senha</h1>}

      <div className="input-with-addon">
        <button type="button" onClick={() => setPasswordVisibility((v) => !v)}>
          <Icon icon={isPasswordVisible ? 'eye-off' : 'eye-on'} />
        </button>
        <FormInput
          name="password"
          type={isPasswordVisible ? 'text' : 'password'}
          minLength="8"
          placeholder="Sua senha"
          required
        />
      </div>

      <FormInput
        name="passwordConfirmation"
        type={isPasswordVisible ? 'text' : 'password'}
        minLength="8"
        placeholder="Confirme sua senha"
        required
      />
    </Form>
  );
}
ChangePasswordForm.propTypes = {
  onProceed: PropTypes.func.isRequired,
  data: PropTypes.object,
  showTitle: PropTypes.bool,
};

function ChangePasswordSuccess({ data, showTitle = true }) {
  const { navigate } = useNavigate();

  function goLogin() {
    authenticateUser({
      email: data.email,
      password: data.password,
    }).then(() => {
      navigate('/');
    });
  }

  return (
    <div className="content">
      {showTitle && <h1>Recuperar senha</h1>}
      <h2 className="text-light">Senha alterada com sucesso!</h2>

      <button type="button" className="btn btn-primary btn-custom" onClick={goLogin}>
        ENTRAR NO APP
      </button>
    </div>
  );
}
ChangePasswordSuccess.propTypes = {
  data: PropTypes.object,
  showTitle: PropTypes.bool,
};
