import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormInput } from 'react-bootstrap-utils';

import { registerUser } from 'shared/http/auth.http';

import { Icon } from '../components/Icon';

export function RegisterUserForm({ onProceed, onGoBack, data }) {
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  function onSubmit(formData) {
    registerUser(formData).then(() => {
      onProceed();
    });
  }

  return (
    <div className="register-user-form login-page-step">
      <Form
        initialValues={data}
        onSubmit={onSubmit}
        customActions={
          <button type="submit" className="btn btn-lg btn-primary">
            PRÓXIMO
          </button>
        }
        validations={{
          passwordConfirmation: [
            {
              message: 'As senhas digitadas devem ser iguais',
              validate(_, formData) {
                return formData.password === formData.passwordConfirmation;
              },
            },
          ],
        }}
      >
        <div className="input-with-addon">
          <button type="button" onClick={onGoBack}>
            <Icon icon="edit" />
          </button>
          <FormInput name="email" readOnly />
        </div>

        <FormInput name="name" placeholder="Seu nome" required />

        <div className="input-with-addon">
          <button type="button" onClick={() => setPasswordVisibility((v) => !v)}>
            <Icon icon={isPasswordVisible ? 'eye-off' : 'eye-on'} />
          </button>
          <FormInput
            name="password"
            type={isPasswordVisible ? 'text' : 'password'}
            minLength="8"
            placeholder="Sua senha"
            required
          />
        </div>

        <FormInput
          name="passwordConfirmation"
          type={isPasswordVisible ? 'text' : 'password'}
          minLength="8"
          placeholder="Confirme sua senha"
          required
        />
      </Form>
    </div>
  );
}
RegisterUserForm.propTypes = {
  onProceed: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  data: PropTypes.object,
};
