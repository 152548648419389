import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export function NoOrders() {
  return (
    <div className="no-orders text-light m-4">
      <p>Nenhum pedido realizado ainda. Navegue para o catálogo e faça um novo pedido.</p>
      <div className="text-center">
        <Link to="/" className="btn btn-success mb-3">
          Voltar
        </Link>
      </div>
    </div>
  );
}
NoOrders.propTypes = {
  goBack: PropTypes.func.isRequired,
};
