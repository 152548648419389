import useSWR from 'swr';

import variationLabelsMap from '../model-variations-labels';
import { swrFetcher } from '../helpers/http';

const baseUrl = '/api/models';

export function useModels(filters, shouldLoad = true) {
  const searchStr = buildModelsSearch(filters);

  const { data, error } = useSWR(shouldLoad && `${baseUrl}${searchStr}`, swrFetcher);

  return {
    models: data || [],
    isLoading: Boolean(shouldLoad && !error && !data),
    isError: error,
  };
}

export function useModel(modelId) {
  const { data, error } = useSWR(modelId && `${baseUrl}/${modelId}`, swrFetcher);

  return {
    model: data,
    isLoading: Boolean(modelId && !error && !data),
    isError: error,
  };
}

export const getVariationLabel = (variation, value) => {
  const normalizedCode = variation.code.toUpperCase();

  let labelFromModel;

  variation.labels.some((label) => {
    if (label.code === value.code) {
      labelFromModel = label.name;

      return true;
    }

    return false;
  });

  const variationLabels = variationLabelsMap[normalizedCode];

  return labelFromModel || (variationLabels && variationLabels[value.code]) || value.name || value.code;
};

export const sortVariationValues = (variation) => {
  const variationValues = [...variation.values];

  variationValues.sort((a, b) => parseInt(a.code, 10) - parseInt(b.code, 10));

  return variationValues;
};

export function parseVariationFromModelCode(modelCode) {
  return modelCode.split(' ').reduce((variations, compoundCode) => {
    const [code, value] = compoundCode.split(':');

    return {
      ...variations,
      [code]: value,
    };
  }, {});
}

export function buildModelCodeFromVariations(variations) {
  return Object.entries(variations)
    .map(([code, value]) => `${code}:${value}`)
    .sort()
    .join(' ');
}

function buildModelsSearch(filters) {
  const search = [];

  if (filters.fabricGroupId) {
    search.push(`by_group_ids%5B%5D=${filters.fabricGroupId}`);
  }

  if (filters.fabricSubgroupId) {
    search.push(`by_subgroup_ids%5B%5D=${filters.fabricSubgroupId}`);
  }

  if (filters.modelTypeId) {
    search.push(`by_type_ids%5B%5D=${filters.modelTypeId}`);
  }

  let searchStr = search.join('&');

  if (searchStr.length > 0) {
    searchStr = `?${searchStr}`;
  }

  return searchStr;
}
