import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import { getValue } from 'shared/helpers/getters-setters';
import { stringify } from 'shared/helpers/query-string';
import { useSearch } from 'shared/helpers/useSearch';
import { filterFabricsWithRepresentative } from 'shared/http/fabric-groups.http';

import { FabricsLightMode } from './FabricsLightMode';

export function FabricsBySubgroup({ fabricSubgroup }) {
  const location = useLocation();
  const search = useSearch();

  const fabricsWithRepresentative = useMemo(
    () => filterFabricsWithRepresentative(getValue(fabricSubgroup, 'fabrics') || []),
    [fabricSubgroup]
  );

  return (
    <div className="fabrics-by-subgroup">
      <h5>
        {getValue(fabricSubgroup, 'name')}
        <FabricsLightMode className="float-right" />
      </h5>
      <div className="row">
        {fabricsWithRepresentative.map((fabric) => (
          <div key={fabric._id} className="col-xs-6 col-sm-6 col-md-4 col-lg-2">
            <div className="fabric">
              <Link to={{ ...location, search: stringify({ ...search, 'tecido': fabric._id }) }}>
                <img src={getValue(fabric, 'images.thumbnail.url')} alt="tecido com representante" crossOrigin="anonymous" />
                <small>{fabric.name}</small>
              </Link>
            </div>
          </div>
        ))}
      </div>
      {fabricsWithRepresentative.length === 0 && (
        <div ng-if="$ctrl.isEmpty()">
          <em>Nenhum tecido encontrado</em>
        </div>
      )}
    </div>
  );
}
FabricsBySubgroup.propTypes = {
  fabricSubgroup: PropTypes.object,
};
